import { getMetric, getMetricCollectionById, getMetricMetadataById } from 'src/api/metricstore';
import { ITableUserFriendlyDataDefinition } from 'src/components/common/csvDownloaderWrapper';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { ViewType } from 'src/components/metricstore/helpers';

// Note:The below function assumes that the allTableColumnsArray contains the complete metric intervals in ascending
// order. Also assumes that the latest metric datapoint corresponds to an interval value which is in the past.
function addIncrementValuesColumn(allMetricData: any, allTableColumnsArray: any, viewType: ViewType) {
  const incrementTypes: Record<ViewType, string> = {
    [ViewType.MONTHLY]: 'MoM',
    [ViewType.WEEKLY]: 'WoW',
    [ViewType.QUARTERLY]: 'QoQ',
    [ViewType.YEARLY]: 'YoY',
  };
  let incrementType = incrementTypes[viewType] || 'YoY';
  allTableColumnsArray.push(incrementType);
  allMetricData.forEach((singlemetricData: any) => {
    let incrementValue = 'NA';
    try {
      // Check for falsy metric values https://developer.mozilla.org/en-US/docs/Glossary/Falsy
      if (
        !+singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 3]] ||
        !singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 2]]
      ) {
        throw new Error('Invalid data for division operation');
      }
      incrementValue =
        (
          ((singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 2]] -
            singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 3]]) /
            singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 3]]) *
          100
        ).toFixed(2) + '%';
    } catch (Exception) {
      incrementValue = 'NA';
    } finally {
      singlemetricData[allTableColumnsArray[allTableColumnsArray.length - 1]] = incrementValue;
    }
  });
  return allMetricData;
}

function getWeekOfYear(now: Date): number {
  const millisecondsInAWeek = 7 * 24 * 60 * 60 * 1000;
  const firstDayOfYear = new Date(`${now.getUTCFullYear()}-01-01T00:00:00Z`);
  // Sets the firstDayOfYear to the first Thursday of the year
  // Refer ISO standard here https://en.wikipedia.org/wiki/ISO_week_date#First_week
  while (firstDayOfYear.getUTCDay() !== 4) {
    firstDayOfYear.setUTCDate(firstDayOfYear.getUTCDate() + 1);
  }
  // Now that we have identified what week is the first in the year, we calculate what is the
  // Monday of that week by subtracting 3 days from Thursday
  firstDayOfYear.setUTCDate(firstDayOfYear.getUTCDate() - 3);
  // If the given date is before the first Monday of the year, it belongs to the last week of the previous year
  if (now < firstDayOfYear) {
    return getWeekOfYear(new Date(now.getUTCFullYear() - 1, 11, 31));
  }
  // Calculate difference in days between the current date and first Monday of the year and divide by 7
  return Math.ceil((now.getTime() - firstDayOfYear.getTime()) / millisecondsInAWeek);
}

function getCurrentIntervalValue(viewType: ViewType): string {
  const now = new Date();
  switch (viewType) {
    case ViewType.MONTHLY:
      return `${now.getUTCFullYear()}-${String(now.getUTCMonth() + 1).padStart(2, '0')}`;
    case ViewType.WEEKLY:
      return `${now.getUTCFullYear()}-WK${String(getWeekOfYear(now)).padStart(2, '0')}`;
    case ViewType.QUARTERLY:
      return `${now.getUTCFullYear()}-Q${Math.ceil((now.getUTCMonth() + 1) / 3)}`;
    case ViewType.YEARLY:
      return String(now.getUTCFullYear());
    default:
      return 'NA';
  }
}

export function getColumnDefinitionCollectionView(tableData: string[]) {
  let columnDefinitions: TableProps.ColumnDefinition<any>[] = [];
  let csvColumnDefinition: ITableUserFriendlyDataDefinition[] = [];
  let csvDataColumn: { [key: string]: string } = {};
  csvDataColumn['Metric'] = '';
  columnDefinitions.push({
    id: 'Metric',
    header: 'Metric',
    cell: (item) => item['Metric'],
    minWidth: 300,
    sortingField: 'Metric',
  });

  for (const cellData of tableData) {
    columnDefinitions.push({
      id: cellData,
      header: cellData,
      cell: (item) => item[cellData],
      minWidth: 150,
      sortingField: cellData,
    });
    csvDataColumn[cellData] = '';
  }

  console.log('column definition is:', columnDefinitions);

  for (const key in csvDataColumn) {
    csvColumnDefinition.push({
      header: key,
      friendlyName: (csvData: Array<Record<string, string>>) => csvData[key],
    });
  }
  return [columnDefinitions, csvColumnDefinition];
}

export const handleRefresh = async (
  metricCollectionId: string,
  setMetricData: (arg0: string[]) => void,
  setLoadingTableData: (arg0: boolean) => void,
  setAllMetricItems: (arg0: any[]) => void,
  setError: (arg0: any) => void,
  viewType: ViewType,
  columnCount: number,
) => {
  setLoadingTableData(true);
  let allTableColumnsSet = new Set<string>();

  try {
    const metricCollectionMetadata = await getMetricCollectionById({
      id: metricCollectionId,
    });
    let allMetricData = [];

    // Create an array of promises for each metricId
    const listOfMetric = Object.values(metricCollectionMetadata.metricPositionToIdMap);

    const metricPromises = listOfMetric.map(async (metricId) => {
      const oneMetricDataPromise = await getMetric({
        metricId: metricId,
        viewType: viewType,
      });

      const metricMetadata = await getMetricMetadataById({
        id: metricId,
      });

      let fittingResponse: any = {};
      fittingResponse['Metric'] = metricMetadata.name;
      fittingResponse['Id'] = metricId;
      oneMetricDataPromise.data.forEach((rowData: any) => {
        allTableColumnsSet.add(rowData.interval_value);
        fittingResponse[rowData.interval_value] = rowData.metric_value;
      });
      return fittingResponse;
    });

    //  Promise.all to wait for all promises to resolve
    allMetricData = await Promise.all(metricPromises);
    // Sets are unordered by default so converting to array for slicing to get desired column
    // Note: Increment computation in addIncrementValuesColumn() depends on this array. Please take care when modifying.
    let currentIntervalValue = getCurrentIntervalValue(viewType);
    console.log(currentIntervalValue);
    allTableColumnsSet.delete(currentIntervalValue);
    let allTableColumnsArray: string[] = Array.from(allTableColumnsSet).sort().slice(-columnCount);
    allMetricData = addIncrementValuesColumn(allMetricData, allTableColumnsArray, viewType);
    setMetricData(allTableColumnsArray);
    setAllMetricItems(allMetricData);
  } catch (err) {
    console.log('Error in fetching view data: ', err);
    setError(err);
  }
  setLoadingTableData(false);
};
