import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Modal,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as React from 'react';
import { deleteMetadataField, listMetadataFields } from 'src/api/catalog';
import { PageHeader } from 'src/components/subscriptions/common';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import { CreateMetadataFieldModal } from 'src/components/metadata/metadataFields/createMetadataFieldModal';
import { EditMetadataFieldModal } from 'src/components/metadata/metadataFields/editMetadataFieldModal';
import { ViewMetadataFieldModal } from 'src/components/metadata/metadataFields/viewMetadataFieldModal';
import { isHCAdmin } from 'src/commons/common';

export interface MetadataFormFieldsTableProps {
  form: any;
  ownerId: string;
  setNotification: (header: any, message: string) => void;
  activeGroup: string;
  activeWorkspace?: any;
}

const MetadataFormFieldsTable = (props: MetadataFormFieldsTableProps) => {
  const [metadataFormFields, setMetadataFormFields] = useState([]);
  const [selectedMetadataFieldId, setSelectedMetadataFieldId] = useState(undefined);
  const [selectedMetadataField, setSelectedMetadataField] = useState(undefined);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingFormFields, setLoadingFormFields] = useState(false);
  const [createFieldModalVisible, setCreateFieldModalVisible] = useState(false);
  const [deleteFieldModalVisible, setDeleteFieldModalVisible] = useState(false);
  const [editFieldModalVisible, setEditFieldModalVisible] = useState(false);
  const [viewFieldModalVisible, setViewFieldModalVisible] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const selectedFormId = props.form.MetadataFormId;
  const selectedForm = props.form;
  console.log('selectedForm', selectedForm);
  console.log('ownerId', ownerId);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    metadataFormFields == undefined ? [] : metadataFormFields,
    {
      filtering: {
        noMatch: '',
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No fields found</b>
            </div>
            <p className='awsui-util-mb-s'>No fields found for the given form. Create one...</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const metadataFormFieldColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'metadataFormFieldName',
      header: 'Name',
      cell: (item) => item.Name,
      minWidth: 200,
      sortingField: 'metadataFormFieldName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => (
        <StatusIndicator type={item.Status == 'Enabled' ? 'success' : 'stopped'}>{item.Status}</StatusIndicator>
      ),
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.FieldType.replace('Field#', ''),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedFormField = selectedItems[0];
    if (selectedFormField) {
      setSelectedMetadataFieldId(selectedFormField.MetadataFieldId);
      setSelectedMetadataField(selectedFormField);
    }
  }, [collectionProps.selectedItems]);

  useEffect(() => {
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
  }, []);

  useEffect(() => {
    getMetaDataFormFields();
  }, [selectedFormId]);

  const handleRefresh = async () => {
    await getMetaDataFormFields();
    setSelectedMetadataField(undefined);
  };

  const getMetaDataFormFields = async () => {
    setLoadingFormFields(true);
    setMetadataFormFields(undefined);
    try {
      let listMetadataFormFieldsResult = await listMetadataFields({
        MetadataFormId: selectedFormId,
      });
      let formFields = listMetadataFormFieldsResult?.MetadataFieldsList;
      while (listMetadataFormFieldsResult.NextToken != undefined) {
        listMetadataFormFieldsResult = await listMetadataFields({
          MetadataFormId: selectedFormId,
          NextToken: listMetadataFormFieldsResult.NextToken,
        });
        formFields.push(...listMetadataFormFieldsResult.MetadataFieldsList);
      }
      setMetadataFormFields(formFields);
    } catch (err) {
      if (err.statusCode >= 500) {
        props.setNotification('error', `Error in fetching form fields. ${err.message}`);
      }
    } finally {
      setLoadingFormFields(false);
    }
  };

  const handleDeleteMetadataField = async () => {
    setButtonLoading(true);
    try {
      let deleteMetadataFieldResult = await deleteMetadataField({
        MetadataFieldId: selectedMetadataFieldId,
        MetadataFormId: selectedFormId,
      });
      if (deleteMetadataFieldResult.Message == 'Success') {
        props.setNotification('success', 'Successfully deleted the field.');
      } else {
        props.setNotification('error', `Failure in deleting form field. Reason:${deleteMetadataFieldResult.Message}`);
      }
    } catch (err) {
      props.setNotification('error', `Error in deleting form field :${err.message}`);
    } finally {
      setButtonLoading(false);
      setDeleteFieldModalVisible(false);
      await handleRefresh();
    }
  };

  const shouldEnableAdminPermissions = () => {
    if (!selectedMetadataField) return false;
    if (props.activeGroup == selectedForm.OwnerId || props.activeWorkspace?.workspaceId == selectedForm.OwnerId)
      return true;
    if (isHCAdmin(props.activeGroup)) return true;
    return false;
  };

  const shouldEnableAdminPermissionsForCreateField = () => {
    if (props.activeGroup == selectedForm.OwnerId || props.activeWorkspace?.workspaceId == selectedForm.OwnerId)
      return true;
    if (isHCAdmin(props.activeGroup)) return true;
  };

  const metadataFormFieldsButtonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleMetadataFormFieldsButtonAction,
        items: [
          {
            text: 'View',
            id: 'viewMetadataField',
            disabled: !selectedMetadataField,
          },
          {
            text: 'Edit',
            id: 'editMetadataField',
            //disabled: !selectedMetadataField || selectedFormType.includes("Global") && !isHCAdmin(ownerId),
            disabled: !shouldEnableAdminPermissions(),
          },
          {
            text: 'Delete',
            id: 'deleteMetadataField',
            //disabled: !selectedMetadataField || selectedFormType.includes("Global") && !isHCAdmin(ownerId),
            disabled: !shouldEnableAdminPermissions(),
          },
        ],
      },
      {
        text: 'Create field',
        variant: 'normal',
        disabled: !shouldEnableAdminPermissionsForCreateField(),
        onItemClick: () => setCreateFieldModalVisible(true),
      },
    ];
  };

  const handleMetadataFormFieldsButtonAction = async (e) => {
    if (e.detail.id === 'editMetadataField') {
      setEditFieldModalVisible(true);
    } else if (e.detail.id === 'deleteMetadataField') {
      setDeleteFieldModalVisible(true);
    } else if (e.detail.id === 'viewMetadataField') {
      setViewFieldModalVisible(true);
    }
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  return (
    <>
      <Modal
        visible={deleteFieldModalVisible}
        header={[`Confirm deletion`]}
        onDismiss={() => setDeleteFieldModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='primary' loading={buttonLoading} onClick={handleDeleteMetadataField}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          Are you sure you want to delete field <strong>{selectedMetadataFieldId}</strong>?
          <br />
        </div>
      </Modal>
      <CreateMetadataFieldModal
        formId={selectedFormId}
        setNotification={props.setNotification}
        close={() => {
          setCreateFieldModalVisible(false);
        }}
        visible={createFieldModalVisible}
        ownerId={ownerId}
        handleRefresh={handleRefresh}
      />
      <EditMetadataFieldModal
        formId={selectedFormId}
        fieldId={selectedMetadataFieldId}
        metadataFormField={selectedMetadataField}
        setNotification={props.setNotification}
        close={() => {
          setEditFieldModalVisible(false);
        }}
        visible={editFieldModalVisible}
        ownerId={ownerId}
        handleRefresh={handleRefresh}
      />
      <ViewMetadataFieldModal
        metadataFormField={selectedMetadataField}
        close={() => {
          setViewFieldModalVisible(false);
        }}
        visible={viewFieldModalVisible}
      />
      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          selectionType='single'
          loadingText='Loading form fields...'
          loading={loadingFormFields}
          columnDefinitions={metadataFormFieldColumnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No fields</b>
              <Box variant='p' color='inherit'>
                No fields to display. Create one...
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={getFilterCounterText(filteredItemsCount)}
            />
          }
          resizableColumns={true}
          header={
            <>
              <PageHeader
                buttons={metadataFormFieldsButtonOptions()}
                header={
                  <>
                    Metadata fields
                    <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                  </>
                }
              />
            </>
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={notificationsPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        />
      </SpaceBetween>
    </>
  );
};

export default MetadataFormFieldsTable;
