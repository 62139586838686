import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { Link } from 'react-router-dom';
import {
  createDatasetDetailLink,
  createGroupDetailLink,
  createTagDetailLink,
  createWorkspaceDetailLink,
  createSchemaDetailLink,
  createCatalogDetailLink,
  createDatabaseDetailLink,
} from 'src/routes';
import {
  ACCESS_PROVIDER_GALAXI,
  ACCESS_PROVIDER_OMNI,
  DZ_GLUE_DATASOURCE_ID,
  DZ_REDSHIFT_DATASOURCE_ID,
  GALAXI_DATASOURCE_ID,
  IAM_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  LAKEFORMATION_TAG_TYPE,
  REDSHIFT_DATASOURCE_ID,
} from 'src/commons/constants';
import * as React from 'react';
import { typeToHumanReadableObject } from 'src/components/permissions/myDatasets/common';
import _ from 'lodash';
import { Badge } from '@amzn/awsui-components-react-v3';
import { ownerLinkItem } from 'src/components/workspaces/common/common';
import { keyForCatalogNameMap } from 'src/commons/common';

export const SEARCH_RESULTS_1000 = 1000;
export const AWS_ACCOUNT_SEARCH_LABEL = 'AWS account';
export const OWNER_SEARCH_LABEL = 'Owner';
export const POSIX_OWNER_SEARCH_LABEL = 'Posix owner';
export const GLADSTONE_GRP_SEARCH_LABEL = 'Gladstone group';
export const OWNER_SEARCH_KEY_FIELD_NAMES = new Set(['primaryOwner', 'owners', 'owner', 'OwnerId']);

export enum SearchEntityType {
  Dataset = 'Dataset',
  Database = 'Database',
  Catalog = 'Catalog',
  Schema = 'Schema',
  Workspace = 'Workspace',
  Tag = 'Tag',
  MetadataForm = 'MetadataForm',
  BusinessGlossary = 'BusinessGlossary',
}

export interface SearchAggregations {
  [key: string]: {
    buckets?: { docCount?: number; key?: string }[];
    fieldName?: string;
  };
}

export const getDataSources = (isWorkspace: boolean): string[] => {
  // Skip IAM datasets in workspaces
  return isWorkspace
    ? [
        LAKE_FORMATION_DATASOURCE_ID,
        REDSHIFT_DATASOURCE_ID,
        GALAXI_DATASOURCE_ID,
        DZ_REDSHIFT_DATASOURCE_ID,
        DZ_GLUE_DATASOURCE_ID,
      ]
    : [
        IAM_DATASOURCE_ID,
        LAKE_FORMATION_DATASOURCE_ID,
        REDSHIFT_DATASOURCE_ID,
        GALAXI_DATASOURCE_ID,
        DZ_REDSHIFT_DATASOURCE_ID,
        DZ_GLUE_DATASOURCE_ID,
      ];
};

export const flatten = (items, dataSources: string[], searchEntityType: SearchEntityType, catalogMap: any) => {
  let list = [];
  switch (searchEntityType) {
    case SearchEntityType.Dataset:
      list = flattenDatasets(
        items.map((item) => item.datasetEntity).filter((item) => !!item),
        dataSources,
        catalogMap,
      );
      break;
    case SearchEntityType.Database:
      list.push(
        ...items
          .filter((item) => item.entityType == 'Database')
          .map((item) => item.databaseEntity)
          .filter((item) => !!item)
          .map((item) => {
            let accountId = item?.catalogId.includes('.') ? item?.catalogId.split('.')[0] : item?.catalogId;
            let catalogName = catalogMap.get(
              keyForCatalogNameMap(
                item?.dataSourceId,
                accountId,
                item?.clusterIdentifier,
                item?.redshiftWorkgroupName,
                item?.region,
              ),
            );
            catalogName = catalogName == null ? item?.catalogId : catalogName;
            return {
              dataSourceId: item?.dataSourceId,
              catalogId: accountId,
              description: item?.description,
              catalogName: catalogName,
              owner: item?.owner,
              region: item?.region,
              clusterIdentifier: item?.clusterIdentifier,
              redshiftWorkgroupName: item?.redshiftWorkgroupName,
              type: typeToHumanReadableObject[item?.dataSourceId],
              databaseName: item?.databaseName.includes('.') ? item?.databaseName.split('.')[0] : item?.databaseName,
            };
          }),
      );
      break;
    case SearchEntityType.Catalog:
      list.push(
        ...items
          .filter((item) => item.entityType == 'Catalog')
          .map((item) => item.catalogEntity)
          .filter((item) => !!item)
          .map((item) => ({
            catalogId: item?.catalogId.includes('.') ? item?.catalogId.split('.')[0] : item?.catalogId,
            description: item?.description,
            catalogName: item?.catalogName,
            owner: item?.owner,
            region: item?.region,
            clusterIdentifier: item?.catalogId.includes('.') ? item?.catalogId.split('.')[1] : null,
            redshiftWorkgroupName: item?.redshiftWorkgroupName,
            dataSourceId: item?.dataSourceId,
            type: typeToHumanReadableObject[item?.dataSourceId],
          })),
      );
      break;
    case SearchEntityType.Schema:
      list.push(
        ...items
          .filter((item) => item.entityType == 'Schema')
          .map((item) => item.schemaEntity)
          .filter((item) => !!item)
          .map((item) => {
            let accountId = item?.catalogId.split('.')[0];
            let catalogName = catalogMap.get(
              keyForCatalogNameMap(
                item?.dataSourceId,
                accountId,
                item?.clusterIdentifier,
                item?.redshiftWorkgroupName,
                item?.region,
              ),
            );
            catalogName = catalogName == null ? item?.catalogId : catalogName;
            return {
              dataSourceId: item?.dataSourceId,
              catalogId: accountId,
              databaseName: item?.database,
              catalogName: catalogName,
              description: item?.description,
              ownerId: item?.owner,
              region: item?.region,
              clusterIdentifier: item?.clusterIdentifier,
              redshiftWorkgroupName: item?.redshiftWorkgroupName,
              schemaName: item?.schemaName,
              type: typeToHumanReadableObject[item?.dataSourceId],
            };
          }),
      );
      break;
    case SearchEntityType.Tag:
      let filteredTags = items
        .filter(
          (item) =>
            item.entityType == 'Tag' &&
            // the TagTable component filters out some tags so we need to do the same here for the counts to match
            item.tagEntity.resourceId == 'ACCOUNT_TAG' &&
            item.tagEntity.statusCustomerType == 'Active:Publisher',
        )
        .map((item) => item.tagEntity)
        .filter((item) => !!item);
      list.push(...filteredTags);
      break;
    case SearchEntityType.Workspace:
      let filteredWks = items
        .filter((item) => item.entityType == 'Workspace')
        .map((item) => item.workspaceEntity)
        .filter((item) => !!item);
      list.push(...filteredWks);
      break;
    case SearchEntityType.BusinessGlossary:
      let glossaries = items
        .filter((item) => item.entityType == 'BusinessGlossary')
        .map((item) => item.businessGlossaryEntity)
        .filter((item) => !!item);
      list.push(
        ...glossaries.map((item) => ({
          name: item?.name,
          description: item?.description,
          ownerId: item?.ownerId,
          glossaryType: item?.ownerType,
          glossaryId: item?.businessGlossaryId,
          status: item?.status,
          glossaryValue: item?.glossaryValue,
        })),
      );
      break;
    case SearchEntityType.MetadataForm:
      let forms = items
        .filter((item) => item.entityType == 'MetadataForm')
        .map((item) => item.metadataFormEntity)
        .filter((item) => !!item);
      list.push(
        ...forms.map((item) => ({
          name: item?.name,
          description: item?.description,
          ownerId: item?.ownerId,
          formType: item?.ownerType,
          metadataFormId: item?.formId,
          status: item?.status,
        })),
      );
      break;
    default:
      break;
  }
  return list;
};

export const flattenDatasets = (items, dataSources: string[], catalogMap: any) => {
  let list = [];
  for (let item of items) {
    let tableName = item?.accessProvider ? (
      <>
        <Badge
          color={
            item?.accessProvider == ACCESS_PROVIDER_GALAXI
              ? 'green'
              : item?.accessProvider == ACCESS_PROVIDER_OMNI
              ? 'blue'
              : 'grey'
          }
        >
          {item.accessProvider}
        </Badge>{' '}
        {item.tableName}
      </>
    ) : (
      <>{item.tableName}</>
    );

    if (
      (dataSources.includes(LAKE_FORMATION_DATASOURCE_ID) && item.dataSourceId == LAKE_FORMATION_DATASOURCE_ID) ||
      (dataSources.includes(DZ_GLUE_DATASOURCE_ID) && item.dataSourceId == DZ_GLUE_DATASOURCE_ID) ||
      (dataSources.includes(GALAXI_DATASOURCE_ID) && item.dataSourceId == GALAXI_DATASOURCE_ID)
    ) {
      let catalogName = catalogMap.get(
        keyForCatalogNameMap(
          item?.dataSourceId,
          item?.accountId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.region,
        ),
      );
      catalogName = catalogName == null ? item?.accountId : catalogName;
      list.push({
        tableName: tableName,
        datasetName: item.datasetName,
        databaseName: item.database,
        catalogId: item.accountId,
        catalogName: catalogName,
        Id: item.datasetId,
        owner: item.owner,
        region: item.region,
        datasourceType: item.dataSourceId,
        dataSourceId: item.dataSourceId,
        type: typeToHumanReadableObject[LAKE_FORMATION_DATASOURCE_ID],
      });
    } else if (
      (dataSources.includes(REDSHIFT_DATASOURCE_ID) && item.dataSourceId == REDSHIFT_DATASOURCE_ID) ||
      (dataSources.includes(DZ_REDSHIFT_DATASOURCE_ID) && item.dataSourceId == DZ_REDSHIFT_DATASOURCE_ID)
    ) {
      let catalogName = catalogMap.get(
        keyForCatalogNameMap(
          item?.dataSourceId,
          item?.accountId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.region,
        ),
      );
      catalogName = catalogName == null ? item?.accountId : catalogName;
      list.push({
        dataSourceId: item.dataSourceId,
        tableName: tableName,
        datasetName: item.datasetName,
        databaseName: item.database,
        catalogId: item.accountId,
        catalogName: catalogName,
        Id: item.datasetId,
        owner: item.owner,
        region: item.region,
        schemaName: item.schemaName,
        clusterIdentifier: item.clusterIdentifier,
        redshiftWorkgroupName: item.redshiftWorkgroupName,
        datasourceType: item.dataSourceId,
        type: typeToHumanReadableObject[item.dataSourceId],
      });
    } else if (dataSources.includes(IAM_DATASOURCE_ID) && item.dataSourceId == IAM_DATASOURCE_ID) {
      let catalogName = catalogMap.get(
        keyForCatalogNameMap(
          item?.dataSourceId,
          item?.accountId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.region,
        ),
      );
      catalogName = catalogName == null ? item?.accountId : catalogName;
      list.push({
        tableName: tableName,
        datasetName: item.datasetName,
        databaseName: item.database,
        catalogId: item.accountId,
        catalogName: catalogName,
        Id: item.datasetId,
        owner: item.owner,
        region: item.region,
        datasourceType: item.dataSourceId,
        dataSourceId: item.dataSourceId,
        type: typeToHumanReadableObject[IAM_DATASOURCE_ID],
      });
    } else {
      console.log(`skipping dataset with supported sources: ${dataSources} found:${item.dataSourceId}`);
    }
  }
  return list;
};

export const defaultTermAggregations: Map<SearchEntityType, { field: string; name: string; size?: number }[]> = new Map(
  [
    [
      SearchEntityType.Dataset,
      [
        {
          field: 'primaryOwner',
          name: OWNER_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'awsAccountId',
          name: AWS_ACCOUNT_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'dataClassification',
          name: 'Dataset visibility',
        },
        {
          field: 'supportedAccessType',
          name: 'Access type',
        },
        {
          field: 'tableStatus',
          name: 'Table status',
        },
        {
          field: 'PII',
          name: 'PII classification',
        },
        {
          field: 'accessProvider.keyword',
          name: 'Access Provider',
        },
      ],
    ],
    [
      SearchEntityType.Database,
      [
        {
          field: 'owners',
          name: OWNER_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'accountId',
          name: AWS_ACCOUNT_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'dataClassification',
          name: 'Database visibility',
        },
        {
          field: 'dataSourceId',
          name: 'Data source',
        },
      ],
    ],
    [
      SearchEntityType.Catalog,
      [
        {
          field: 'owner',
          name: OWNER_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'dataClassification',
          name: 'Catalog visibility',
        },
        {
          field: 'dataSourceId',
          name: 'Data source',
        },
      ],
    ],
    [
      SearchEntityType.Schema,
      [
        {
          field: 'accountId',
          name: AWS_ACCOUNT_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'dataClassification',
          name: 'Schema visibility',
        },
        {
          field: 'dataSourceId',
          name: 'Data source',
        },
      ],
    ],
    [
      SearchEntityType.Workspace,
      [
        {
          field: 'GroupId',
          name: GLADSTONE_GRP_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'Owner.keyword',
          name: POSIX_OWNER_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'Status',
          name: 'Status',
        },
        {
          field: 'SupportedServices',
          name: 'Supported capabilities',
          size: 5,
        },
      ],
    ],
    [
      SearchEntityType.Tag,
      [
        {
          field: 'OwnerId',
          name: OWNER_SEARCH_LABEL,
          size: SEARCH_RESULTS_1000,
        },
        {
          field: 'TagType.keyword',
          name: 'Tag type',
        },
        {
          field: 'Status',
          name: 'Status',
        },
        {
          field: 'TagKey',
          name: 'Tag name',
          size: 5,
        },
      ],
    ],
    [
      SearchEntityType.MetadataForm,
      [
        {
          field: 'Status',
          name: 'Status',
        },
      ],
    ],
    [
      SearchEntityType.BusinessGlossary,
      [
        {
          field: 'Status',
          name: 'Status',
        },
      ],
    ],
  ],
);

export const aggregationDisplayConfig: Map<SearchEntityType, { [name: string]: { displayCount: boolean } }> = new Map([
  [
    SearchEntityType.Dataset,
    {
      dataClassification: {
        displayCount: true,
      },
      supportedAccessType: {
        displayCount: true,
      },
      tableStatus: {
        displayCount: true,
      },
      PII: {
        displayCount: true,
      },
      awsAccountId: {
        displayCount: true,
      },
      primaryOwner: {
        displayCount: true,
      },
      'accessProvider.keyword': {
        displayCount: true,
      },
    },
  ],
  [
    SearchEntityType.Database,
    {
      dataClassification: {
        displayCount: true,
      },
      dataSourceId: {
        displayCount: true,
      },
      accountId: {
        displayCount: true,
      },
      owners: {
        displayCount: true,
      },
    },
  ],
  [
    SearchEntityType.Catalog,
    {
      dataClassification: {
        displayCount: true,
      },
      dataSourceId: {
        displayCount: true,
      },
      owner: {
        displayCount: true,
      },
    },
  ],
  [
    SearchEntityType.Schema,
    {
      dataClassification: {
        displayCount: true,
      },
      dataSourceId: {
        displayCount: true,
      },
      accountId: {
        displayCount: true,
      },
    },
  ],
  [
    SearchEntityType.Workspace,
    {
      'Owner.keyword': {
        displayCount: true,
      },
      Status: {
        displayCount: true,
      },
      SupportedServices: {
        displayCount: true,
      },
      GroupId: {
        displayCount: true,
      },
    },
  ],
  [
    SearchEntityType.Tag,
    {
      'TagType.keyword': {
        displayCount: false,
      },
      Status: {
        displayCount: false,
      },
      TagKey: {
        displayCount: false,
      },
      OwnerId: {
        displayCount: false,
      },
    },
  ],
  [
    SearchEntityType.MetadataForm,
    {
      Status: {
        displayCount: false,
      },
    },
  ],
  [
    SearchEntityType.BusinessGlossary,
    {
      Status: {
        displayCount: false,
      },
    },
  ],
]);

export const entityDisplayName: Map<SearchEntityType, string> = new Map([
  [SearchEntityType.Dataset, 'Datasets'],
  [SearchEntityType.Database, 'Databases'],
  [SearchEntityType.Catalog, 'Catalogs'],
  [SearchEntityType.Schema, 'Schemas'],
  [SearchEntityType.Workspace, 'Workspaces'],
  [SearchEntityType.Tag, 'Tags'],
  [SearchEntityType.MetadataForm, 'Metadata forms'],
  [SearchEntityType.BusinessGlossary, 'Business glossaries'],
]);

export const defaultEntityBoostedFields: Map<SearchEntityType, Array<string>> = new Map([
  // 'field^boostFloat',  eg: 'dataSetName^5' indicates dataSetName is boosted 5X for score priority
  [
    SearchEntityType.Dataset,
    ['dataSetName^5', 'databaseName^3', 'columns.comment^3', 'dataSetDesc^3', 'catalogId^2', 'primaryOwner^2', '*'],
  ],
  [SearchEntityType.Database, ['databaseName^5', 'catalogId^3', 'owners^2', '*']],
  [SearchEntityType.Catalog, ['name^5', 'catalogId^3', 'owner^2', '*']],
  [SearchEntityType.Schema, []],
  [SearchEntityType.Workspace, ['WorkspaceName^5', 'GroupId^2', '*']],
  [SearchEntityType.Tag, ['TagPair^5', 'OwnerId^2', '*']],
  [SearchEntityType.MetadataForm, []],
  [SearchEntityType.BusinessGlossary, []],
]);

export const isFilterableFacet = (field: string) => {
  return _.includes(
    [AWS_ACCOUNT_SEARCH_LABEL, OWNER_SEARCH_LABEL, GLADSTONE_GRP_SEARCH_LABEL, POSIX_OWNER_SEARCH_LABEL],
    field,
  );
};

export const ignoredFilterBucketValues = new Set<string>(['Metric']);

export const aggregationDisplaySortOrder: Map<string, number> = new Map([
  // higher the number, higher the priority
  [OWNER_SEARCH_LABEL, 10],
  [GLADSTONE_GRP_SEARCH_LABEL, 9],
  [AWS_ACCOUNT_SEARCH_LABEL, 8],
  [POSIX_OWNER_SEARCH_LABEL, 7],
]);

export const getSearchTableColumnDefinitions = (
  isWorkspace: boolean,
  entityType: SearchEntityType,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  switch (entityType) {
    case SearchEntityType.Dataset:
      return getDatasetColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Database:
      return getDatabaseColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Catalog:
      return getCatalogColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Schema:
      return getSchemaColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Workspace:
      return getWorkspaceColumnDefinitions();
    case SearchEntityType.Tag:
      return getTagColumnDefinitions(workspaceNameMap);
    case SearchEntityType.BusinessGlossary:
      return getBusinessGlossaryColumnDefinitions(workspaceNameMap);
    case SearchEntityType.MetadataForm:
      return getMetadataFormColumnDefinitions(workspaceNameMap);
    default:
      return getDatasetColumnDefinitions(isWorkspace, workspaceNameMap);
  }
};

export const getMinimalSearchTableColumnDefinitions = (
  isWorkspace: boolean,
  entityType: SearchEntityType,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  switch (entityType) {
    case SearchEntityType.Dataset:
      return getMinimalDatasetColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Database:
      return getMinimalDatabaseColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Catalog:
      return getMinimalCatalogColumnDefinitions(isWorkspace, workspaceNameMap);
    case SearchEntityType.Schema:
      return getMinimalSchemaColumnDefinitions(isWorkspace, workspaceNameMap);
    // Resource Selector does not support other entity types: MetadataForm, BusinessGlossary, etc.
    default:
      return getMinimalDatasetColumnDefinitions(isWorkspace, workspaceNameMap);
  }
};

function getDatabaseNameCell(isWorkspace: boolean) {
  return {
    id: 'databaseName',
    header: 'Database name',
    cell: (item) => (
      <Link
        to={createDatabaseDetailLink(
          item?.dataSourceId,
          item?.catalogId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.databaseName,
          item?.region,
        )}
      >
        {item.databaseName}
      </Link>
    ),
    sortingField: 'databaseName',
    minWidth: 200,
  };
}

function getCatalogNameCell(isWorkspace: boolean) {
  return {
    id: 'catalogName',
    header: 'Catalog name',
    cell: (item) => (
      <Link
        to={createCatalogDetailLink(
          item?.dataSourceId,
          item?.catalogId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.region,
        )}
      >
        {item.catalogName}
      </Link>
    ),
    sortingField: 'catalogName',
    minWidth: 200,
  };
}

function getTypeCell(valueProvider) {
  return {
    id: 'type',
    header: 'Type',
    cell: valueProvider,
    minWidth: 200,
  };
}

function getOwnerCell(valueProvider, workspaceNameMap: Map<string, string>) {
  return {
    id: 'owner',
    header: 'Owner',
    cell: (item) => ownerLinkItem(valueProvider(item), workspaceNameMap),
    sortingField: 'owner',
    minWidth: 200,
  };
}

function getSchemaNameCell() {
  return {
    id: 'schemaName',
    header: 'Schema name',
    cell: (item) => (
      <Link
        to={createSchemaDetailLink(
          item?.dataSourceId,
          item?.catalogId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.databaseName,
          item?.schemaName,
          item?.region,
        )}
      >
        {item.schemaName}
      </Link>
    ),
    sortingField: 'schemaName',
    minWidth: 200,
  };
}

function getClusterIdentifierCell() {
  return {
    id: 'clusterIdentifier',
    header: 'Cluster identifier',
    cell: (item) => (
      <Link
        to={createCatalogDetailLink(
          item?.dataSourceId,
          item?.catalogId,
          item?.clusterIdentifier,
          item?.redshiftWorkgroupName,
          item?.region,
        )}
      >
        {item.clusterIdentifier}
      </Link>
    ),
    sortingField: 'clusterIdentifier',

    minWidth: 200,
  };
}

function getDescriptionCell() {
  return {
    id: 'description',
    header: 'Description',
    cell: (item) => item.description,
    sortingField: 'owner',
    minWidth: 200,
  };
}

export const getDatasetColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  return [
    {
      id: 'datasetName',
      header: 'Dataset name',
      cell: (item) => <Link to={createDatasetDetailLink(item?.Id)}>{item.tableName}</Link>,
      sortingField: 'datasetName',
      minWidth: 200,
    },
    {
      id: 'datasetTitle',
      header: 'Dataset title',
      cell: (item) => <Link to={createDatasetDetailLink(item?.Id)}>{item.datasetName}</Link>,
      sortingField: 'datasetTitle',
      minWidth: 200,
    },
    getDatabaseNameCell(isWorkspace),
    getCatalogNameCell(isWorkspace),
    getOwnerCell((item) => item.owner, workspaceNameMap),
    getTypeCell((item) => item.type),
  ];
};

export const getMinimalDatasetColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  return getDatasetColumnDefinitions(isWorkspace, workspaceNameMap).slice(0, 3); // catalog ID, DB name, table name
};

export const getDatabaseColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => [
  getDatabaseNameCell(isWorkspace),
  getClusterIdentifierCell(),
  getCatalogNameCell(isWorkspace),
  getDescriptionCell(),
  getOwnerCell((item) => item.owner, workspaceNameMap),
  getTypeCell((item) => item.type),
];

export const getMinimalDatabaseColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  // DB name, cluster ID, catalog name.
  const columns = getDatabaseColumnDefinitions(isWorkspace, workspaceNameMap).slice(0, 3);
  // Reorder them to DB name, catalog name, cluster ID.
  return [columns[0], columns[2], columns[1]];
};

export const getCatalogColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => [
  getCatalogNameCell(isWorkspace),
  {
    id: 'catalogId',
    header: 'Catalog ID',
    cell: (item) => item.catalogId,
    sortingField: 'catalogId',
    minWidth: 200,
  },
  getOwnerCell((item) => item.owner, workspaceNameMap),
  getDescriptionCell(),
  {
    id: 'clusterName',
    header: 'ClusterName',
    cell: (item) => item.clusterIdentifier,
    sortingField: 'clusterName',
    minWidth: 200,
  },
  getTypeCell((item) => item.type),
];

export const getMinimalCatalogColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => {
  return getCatalogColumnDefinitions(isWorkspace, workspaceNameMap).slice(0, 2); // catalog name and ID
};

export const getSchemaColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => [
  getDatabaseNameCell(isWorkspace),
  getSchemaNameCell(),
  getClusterIdentifierCell(),
  getCatalogNameCell(isWorkspace),
  getDescriptionCell(),
  getOwnerCell((item) => item.ownerId, workspaceNameMap),
];

export const getMinimalSchemaColumnDefinitions = (
  isWorkspace: boolean,
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] =>
  // DB name, schema name, cluster ID, catalog name
  getSchemaColumnDefinitions(isWorkspace, workspaceNameMap).slice(0, 4);

export const getWorkspaceColumnDefinitions = (): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'workspaceId',
    header: 'Workspace ID',
    cell: (item) => <Link to={createWorkspaceDetailLink(item.workspaceId)}>{item.workspaceId}</Link>,
    sortingField: 'workspaceId',
    minWidth: 250,
  },
  {
    id: 'workspaceName',
    header: 'Workspace name',
    cell: (item) => item.workspaceName,
    sortingField: 'workspaceName',
    minWidth: 250,
  },
  {
    id: 'workspaceRegion',
    header: 'Workspace region',
    cell: (item) => item.workspaceRegion,
    sortingField: 'workspaceRegion',
    minWidth: 200,
  },
  {
    id: 'groupId',
    header: 'Group ID',
    cell: (item) => <Link to={createGroupDetailLink(item.groupId)}>{item.groupId}</Link>,
    sortingField: 'groupId',
    minWidth: 250,
  },
];

export const getTagColumnDefinitions = (workspaceNameMap: Map<string, string>): TableProps.ColumnDefinition<any>[] => [
  getOwnerCell((item) => item.ownerId, workspaceNameMap),
  {
    id: 'region',
    header: 'Region',
    cell: (item) => item.region,
    sortingField: 'region',
    minWidth: 200,
  },
  {
    id: 'tagType',
    header: 'Tag type',
    cell: (item) => (item.tagType == null ? LAKEFORMATION_TAG_TYPE : item.tagType),
    sortingField: 'tagType',
    minWidth: 200,
  },
  {
    id: 'tagPair',
    header: 'Tag pair',
    cell: (item) => <Link to={createTagDetailLink(item.ownerId, item.tagId)}>{item.tagPair}</Link>,
    sortingField: 'tagPair',
    minWidth: 200,
  },
];

const createBusinessGlossaryLink = (item: any) => {
  return `/glossaries/${item.glossaryId}/${item.ownerId}`;
};

export const getBusinessGlossaryColumnDefinitions = (
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'glossaryName',
    header: 'Glossary name',
    cell: (item) => <Link to={createBusinessGlossaryLink(item)}>{item.name}</Link>,
    sortingField: 'glossaryName',
    minWidth: 250,
  },
  {
    id: 'glossaryId',
    header: 'Glossary ID',
    cell: (item) => item.glossaryId,
    sortingField: 'glossaryId',
    minWidth: 200,
  },
  getOwnerCell((item) => item.ownerId, workspaceNameMap),
  getDescriptionCell(),
  getTypeCell((item) => item.glossaryType),
];

const createMetadataFormLink = (item: any) => {
  return `/metadataForms/${item.metadataFormId}/${item.ownerId}`;
};

export const getMetadataFormColumnDefinitions = (
  workspaceNameMap: Map<string, string>,
): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'metadataFormName',
    header: 'MetadataForm name',
    cell: (item) => <Link to={createMetadataFormLink(item)}>{item.name}</Link>,
    sortingField: 'metadataFormName',
    minWidth: 250,
  },
  {
    id: 'metadataFormId',
    header: 'MetadataForm ID',
    cell: (item) => item.metadataFormId,
    sortingField: 'metadataFormId',
    minWidth: 200,
  },
  getOwnerCell((item) => item.ownerId, workspaceNameMap),
  {
    id: 'status',
    header: 'Status',
    cell: (item) => item.status,
    minWidth: 200,
  },
  getDescriptionCell(),
  getTypeCell((item) => item.formType),
];
