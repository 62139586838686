import React, { Component } from 'react';
import ResourceSelector from './resourceSelector/resourceSelectorComponent';
import RecipientSelector from './recipientSelector/recipientSelectorComponent';
import PermissionSelector from './permissionSelector/permissionSelectorComponent';
import { TablePermissions } from './permissionSelector/tablePermissionsFormFields';
import { DataLakePrincipal, Resource } from 'aws-sdk/clients/lakeformation';
import UseCase from './useCase/useCaseComponent';
import { Database } from 'aws-sdk/clients/glue';
import {
  Button,
  Flashbar,
  FlashbarProps,
  Form,
  Header,
  SelectProps,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../../utils/navigation';
import * as validate from '../../../commons/validationUtils';
import {
  createRequestForDataPermission,
  getRequest,
  getTemplateForResource,
  listDataPermissions,
} from '../../../api/permissions';
import { capitalize } from './tablePermissionsPage.utils';

import {
  AccessManagementTemplateInfo,
  GetTemplateForResourceResult,
  TemplateResult,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';

import { HybridCatalogDataSet } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Page } from '../../../routes/Paths';
import { TemplateRequestDetailsContainer } from 'src/components/permissions/myPermissions/templateRequestDetailsContainer';
import {
  APPROVALS_PRIORITY,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  DATA_PERMISSION_STATUS_ACTIVE,
  FORM_CONTENT_TYPE,
  TEMPLATE_TYPE_ACCESS_MANAGEMENT,
} from 'src/commons/constants';
import { convertApprovalWorkflowIDToLink } from 'src/components/permissions/myPermissions/common';
import { generateApprovalWorkflowMessage, getErrorMessageContent } from 'src/components/common/checkout';
import _ from 'lodash';
import { isValidPrincipalNotRoot } from '../../../commons/validationUtils';

export interface CreateLakeFormationPermissionComponentProps {
  setContentType: any;
  location: any;
  activeGroup: string;
  permissionType: 'request' | 'revoke';
  username: string;
}

export interface CreateLakeFormationPermissionComponentState {
  notifications: FlashbarProps.MessageDefinition[];
  loading: any;
  redirect: string;
  principal: DataLakePrincipal;
  ramRole: any;
  database: Database;
  databaseName: string;
  table: HybridCatalogDataSet;
  tableName: string;
  tableInfo: SelectProps.Option;
  permissions: TablePermissions;
  resource: Resource;
  useCase: string;
  buttonLoading: boolean;
  catalogId: string;
  templates: TemplateResult[];
  additionalMetadata: any[];
  ticketLink: string;
  dataPermissionId: string;
  publisherAuditRole: string;
  publisherRole: string;
  formSubmitted: boolean;
}

const defaultPermissions = {
  tablePermissions: ['SELECT'],
  grantablePermissions: ['SELECT'],
};

export const extractApprovalTemplateIdFromLink = (link: string) => {
  if (link != null) {
    let position = link.lastIndexOf('/');
    return link.substring(position + 1, link.length);
  } else {
    console.log('The approval template link is wrong or not converted properly. ');
  }
};

export default class CreateLakeFormationPermissionComponent extends Component<
  CreateLakeFormationPermissionComponentProps,
  CreateLakeFormationPermissionComponentState
> {
  state = {
    notifications: [],
    loading: false,
    redirect: undefined,
    database: undefined,
    databaseName: undefined,
    table: undefined,
    tableName: undefined,
    tableInfo: undefined,
    permissions: defaultPermissions,
    resource: undefined,
    principal: undefined,
    ramRole: undefined,
    groupRequestedBy: this.props.activeGroup,
    groupRequestedTo: undefined,
    dataLocationArn: undefined,
    dataLocationRegion: undefined,
    useCase: undefined,
    buttonLoading: false,
    catalogId: undefined,
    templates: [],
    additionalMetadata: [],
    ticketLink: '',
    dataPermissionId: undefined,
    publisherRole: undefined,
    publisherAuditRole: undefined,
    formSubmitted: false,
  };

  componentDidMount = async () => {
    // if we were redirected here from the catalog page,
    // replace some state values with the props passed in
    if (this.props.location.state) {
      this.setState({
        tableInfo: this.props.location.state.tableInfo,
        tableName: this.props.location.state.tableName,
        databaseName: this.props.location.state.databaseName,
        catalogId: this.props.location.state.catalogId,
      });
    }
    this.props.setContentType(FORM_CONTENT_TYPE);
  };

  updateTemplateInfo = async (id: string) => {
    if (id === undefined) {
      return;
    }
    // This is to support rule-based template
    // Provide account ID where the LF/Redshift access will be granted to
    const dataLakePrincipals =
      this.state.principal && this.state.principal.DataLakePrincipalIdentifier
        ? [this.state.principal.DataLakePrincipalIdentifier]
        : [];
    let getTemplateForResourceResult: GetTemplateForResourceResult = null;
    try {
      if (id == 'All|Tables') {
        if (this.state.table != undefined) {
          id =
            `DS-glueLF|A-${this.state.table.IdInfo.CatalogId}|DN-${this.state.table.IdInfo.DatabaseName}` +
            `|R-${this.state.table.IdInfo.Region}`;
        } else {
          id =
            `DS-glueLF|A-${this.props.location.state.catalogId}|DN-${this.props.location.state.databaseName}` +
            `|R-${this.props.location.state.region}`;
        }
      }
      getTemplateForResourceResult = await getTemplateForResource({
        resourceId: id,
        templateType: TEMPLATE_TYPE_ACCESS_MANAGEMENT,
        dataLakePrincipals: dataLakePrincipals,
      });
    } catch (e) {
      this.setNotification('error', e.message);
    }
    const newTemplates = getTemplateForResourceResult.templates.map((template) => {
      let accessManagement = {};
      if (template.accessManagementInfo?.simTemplates) {
        let simTickets = template.accessManagementInfo?.simTemplates?.map((simTemplate) => {
          return { ...simTemplate, simTicketLink: '' };
        });
        accessManagement['simTemplates'] = simTickets;
      }
      if (template.accessManagementInfo?.approvalsWorkflowTemplate) {
        let approvals = {
          approvalTemplateId: extractApprovalTemplateIdFromLink(
            template.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateLink,
          ),
          neededOnDate: '',
          priority: APPROVALS_PRIORITY,
          userAlias: this.props.username,
        };
        accessManagement['approvalsWorkflowTemplate'] = approvals;
      }
      return { ...template, accessManagementInfo: accessManagement, id: id };
    });
    //Removed the duplicates based on templateId
    const uniqueTemplates = _.uniqBy(newTemplates, 'templateId');
    this.setState({
      templates: getTemplateForResourceResult.templates,
      additionalMetadata: uniqueTemplates,
    });
  };

  generateAccessManagementRequest = () => {
    return this.state.additionalMetadata.map((template) => {
      let accessManagementRequest: AccessManagementTemplateInfo = {
        templateId: template.templateId,
        resourceId: template.resourceId,
      };
      if (template.accessManagementInfo?.simTemplates) {
        let simTickets = template.accessManagementInfo?.simTemplates?.map((simTemplate) => {
          return {
            simTemplateLink: simTemplate.simTemplateLink,
            simTemplateName: simTemplate.simTemplateName,
            simTicketLink: simTemplate.simTicketLink,
          };
        });
        accessManagementRequest.simTicketLinks = simTickets;
      }
      if (template.accessManagementInfo?.approvalsWorkflowTemplate) {
        accessManagementRequest.approvalTemplateId =
          template.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateId;
        accessManagementRequest.userAlias = template.accessManagementInfo.approvalsWorkflowTemplate.userAlias;
        accessManagementRequest.neededOnDate = this.convertNeededOnDate(
          template.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate,
        );
        accessManagementRequest.priority = template.accessManagementInfo.approvalsWorkflowTemplate.priority;
      }
      return accessManagementRequest;
    });
  };

  convertNeededOnDate = (oldDate: string) => {
    const arr = oldDate.split('-');
    return arr[1] + '/' + arr[2] + '/' + arr[0];
  };

  createDataPermissionRequest = async () => {
    const accessManagementTemplateMetadata = this.generateAccessManagementRequest();
    let owner = this.state.table.Owners;
    let catalogId = this.state.table.IdInfo.CatalogId;
    let databaseName = this.state.table.IdInfo.DatabaseName;
    let region = this.state.table.IdInfo.Region;
    let tableName = this.state.table.IdInfo.TableName;
    if (tableName == 'All Tables') {
      let createRequestForDataPermissionRequest = {
        ownerRequestedBy: this.props.activeGroup,
        ownerRequestedTo: owner,
        useCase: this.state.useCase,
        dataPermissionPublisherRole: this.state.publisherRole,
        dataPermissionId: this.state.dataPermissionId,
        dataLocationRegion: region,
        dataPermissionConsumerRole: this.state.ramRole,
        resource: {
          tableWithColumns: null,
          database: null,
          dataCellsFilter: null,
          lFTagPolicy: null,
          table: {
            databaseName: databaseName,
            name: null,
            catalogId: catalogId,
            tableWildcard: {},
          },
        },
        permissions: this.state.permissions.tablePermissions,
        permissionsWithGrantOption: isValidPrincipalNotRoot(this.state.principal.DataLakePrincipalIdentifier)
          ? []
          : this.state.permissions.grantablePermissions,
        dataLakePrincipal: this.state.principal.DataLakePrincipalIdentifier,
        accessManagementTemplateMetadata: accessManagementTemplateMetadata,
      };
      let createRequestForDataPermissionResult = await createRequestForDataPermission(
        createRequestForDataPermissionRequest,
      );
      return createRequestForDataPermissionResult;
    } else {
      let createRequestForDataPermissionRequest = {
        ownerRequestedBy: this.props.activeGroup,
        ownerRequestedTo: owner,
        useCase: this.state.useCase,
        dataPermissionPublisherRole: this.state.publisherRole,
        dataPermissionId: this.state.dataPermissionId,
        dataLocationRegion: region,
        dataPermissionConsumerRole: this.state.ramRole,
        resource: {
          tableWithColumns: null,
          database: null,
          dataCellsFilter: null,
          lFTagPolicy: null,
          table: {
            databaseName: databaseName,
            name: tableName,
            catalogId: catalogId,
            tableWildcard: null,
          },
        },
        permissions: this.state.permissions.tablePermissions,
        permissionsWithGrantOption: isValidPrincipalNotRoot(this.state.principal.DataLakePrincipalIdentifier)
          ? []
          : this.state.permissions.grantablePermissions,
        dataLakePrincipal: this.state.principal.DataLakePrincipalIdentifier,
        accessManagementTemplateMetadata: accessManagementTemplateMetadata,
      };

      let createRequestForDataPermissionResult = await createRequestForDataPermission(
        createRequestForDataPermissionRequest,
      );
      return createRequestForDataPermissionResult;
    }
  };

  isColumnPermissionWithNoSelectedColumns = () => {
    if (this.state.resource != null && this.state.resource.TableWithColumns != null) {
      let columnResource = this.state.resource.TableWithColumns;
      if (columnResource.columnNames != null && columnResource.columnNames.length == 0) {
        return true;
      }
      if (
        columnResource.columnWildcard != null &&
        columnResource.columnWildcard.excludedColumnNames != null &&
        columnResource.columnWildcard.excludedColumnNames.length == 0
      ) {
        return true;
      }
    }
    return false;
  };

  handleConfirm = async () => {
    if (!this.state.useCase) {
      this.setState({ formSubmitted: true });
      return;
    }

    this.setState({ loading: true, buttonLoading: true });
    try {
      let response;
      response = await this.createDataPermissionRequest();
      const requestId = response.requestId;
      let approvalWorkflowLinks = [];
      const getRequestResponse = await getRequest({ requestId: requestId });
      const accessManagementInfoList = getRequestResponse?.requestItem?.additionalMetadata?.accessManagementInfoList;
      if (accessManagementInfoList) {
        accessManagementInfoList.forEach((aMIS) => {
          if (aMIS.approvalWorkflowId) {
            const approvalWorkflowLink = convertApprovalWorkflowIDToLink(aMIS.approvalWorkflowId);
            approvalWorkflowLinks.push(approvalWorkflowLink);
          }
        });
      }

      let newNotifications = [
        ...this.state.notifications,
        {
          type: 'success',
          content: `Lake Formation request '${requestId}' created.`,
          dismissible: true,
          buttonText: 'View requests',
          onButtonClick: () =>
            this.setState({
              redirect: Page.REQUESTS,
            }),
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ];

      if (approvalWorkflowLinks.length > 0) {
        const approvalWorkflowMessage = generateApprovalWorkflowMessage(approvalWorkflowLinks);
        newNotifications.push({
          type: 'info',
          content: approvalWorkflowMessage,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        });
      }
      this.setState({
        notifications: newNotifications,
      });
    } catch (err) {
      const failMessage = 'Failed to submit the access request';
      const errMsgContent = getErrorMessageContent(err);
      this.setState({
        notifications: [
          {
            type: 'error',
            header: failMessage,
            content: errMsgContent,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
    }
    this.setState({ loading: false });
    scrollUp();
  };

  allFieldsSet() {
    return (
      // Validate Objects
      validate.isNotFalsy(this.state.table) &&
      validate.isNotFalsy(this.state.database) &&
      validate.isNotFalsy(this.state.principal) &&
      validate.isNotFalsy(this.state.ramRole) &&
      validate.isNotFalsy(this.state.permissions) &&
      // Validate Request information
      validate.isValidGroup(this.state.groupRequestedBy) &&
      (validate.isValidGroup(this.state.table.Owners[0]) || validate.isValidGroup(this.state.database.Owners[0])) &&
      validate.isValidRole(this.state.table.LakeFormationRoleARN) &&
      (validate.isNotFalsy(this.state.table.IdInfo.Region) || validate.isNotFalsy(this.state.database.IdInfo.Region)) &&
      (validate.isValidAccount(this.state.principal.DataLakePrincipalIdentifier) ||
        validate.isValidPrincipalNotRoot(this.state.principal.DataLakePrincipalIdentifier)) &&
      validate.isNotFalsy(this.state?.permissions?.tablePermissions?.length) &&
      this.validateAdditionalMetadata()
    );
  }

  validateAdditionalMetadata = () => {
    let validation = [];
    this.state.additionalMetadata.map((t) => {
      if (t.accessManagementInfo.simTemplates) {
        t.accessManagementInfo.simTemplates.map((st) => {
          if (!st.simTicketLink) {
            validation.push(true);
          } else {
            validation.push(false);
          }
        });
      }
      if (t.accessManagementInfo.approvalsWorkflowTemplate) {
        if (!t.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate) {
          validation.push(true);
        } else if (!t.accessManagementInfo.approvalsWorkflowTemplate.priority) {
          validation.push(true);
        } else {
          validation.push(false);
        }
      }
    });
    return validation.every((ticket) => ticket == false);
  };

  getDataPermissionIdFromTable = async (table) => {
    let owner = table?.Owners;
    let databaseName = table?.IdInfo.DatabaseName;
    let catalogId = table?.IdInfo.CatalogId;
    let region = table?.IdInfo.Region;
    let tableName = table?.IdInfo.TableName;

    let listDatasetPermissionRequest = {
      ownerId: owner,
      region: region,
      resource: {
        table: {
          databaseName: databaseName,
          name: null,
          catalogId: catalogId,
          tableWildcard: null,
        },
      },
      option: DATA_PERMISSION_PUBLISHER_OPTION,
      status: DATA_PERMISSION_STATUS_ACTIVE,
      type: DATA_PERMISSION_LAKE_FORMATION_TYPE,
      nextToken: null,
    };

    let permissionId = undefined;
    try {
      let listDatasetPermissionResponse = await listDataPermissions(listDatasetPermissionRequest);
      if (listDatasetPermissionResponse?.dataPermissionList !== undefined) {
        for (let permission of listDatasetPermissionResponse?.dataPermissionList) {
          if (
            (permission.resource.table !== undefined &&
              (permission.resource.table.name == null || permission.resource.table.name == tableName)) ||
            (permission.resource.tableWithColumns !== undefined &&
              (permission.resource.tableWithColumns.name == tableName ||
                (permission.resource.tableWithColumns.name == 'ALL_TABLES' &&
                  permission.resource.tableWithColumns.databaseName == databaseName)))
          ) {
            permissionId = permission.dataPermissionId;
            this.setState({
              publisherAuditRole: permission.audit.PublisherAuditRole,
              publisherRole: permission.audit.PublisherRole,
            });
          }
        }
      }
    } catch (err) {
      console.log('Exception get Permission', err);
    }
    this.setState({ dataPermissionId: permissionId });
  };

  setPrincipal = (value) => {
    this.setState({ principal: value }, () => this.updateTemplateInfo(this.state.table?.Id));
  };
  setRamRole = (value) => {
    this.setState({ ramRole: value });
  };
  setTable = (table: HybridCatalogDataSet | undefined) => {
    this.setState({
      table,
    });
    if (table !== undefined) {
      this.updateTemplateInfo(table.Id);
      this.getDataPermissionIdFromTable(table);
    } else {
      this.setState({
        templates: [],
        additionalMetadata: [],
      });
    }
  };
  setDatabase = (database: Database | undefined) => {
    this.setState({ database });
    if (database) {
      this.setState({ catalogId: database['CatalogId'] });
      this.setState({
        table: undefined,
        tableInfo: undefined,
        tableName: undefined,
      });
    }
  };
  setResource = (resource: Resource | undefined) => {
    this.setState({ resource, permissions: defaultPermissions });
  };
  setPermissions = (permissions: TablePermissions | undefined) => {
    this.setState({ permissions });
  };
  setUseCase = (value) => {
    this.setState({ useCase: value });
  };
  setAdditionalMetadata = (templateDetails) => {
    this.setState({ additionalMetadata: templateDetails });
  };

  setNotification = (header, message) => {
    if (header === '' || header === 'success') {
      this.state.notifications = [
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ];
    } else {
      this.state.notifications = [
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ];
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <div style={{ width: '760px' }}>
          <Flashbar items={this.state.notifications} />
          <Form
            header={
              <Header
                variant='h1'
                description={`Choose the access permissions to ${this.props.permissionType.toLowerCase()} to this or an external account.`}
              >
                {`${capitalize(this.props.permissionType)} permissions`}
              </Header>
            }
            actions={
              <div>
                <Button variant='link' onClick={() => this.setState({ redirect: Page.MY_DATASETS })}>
                  Cancel
                </Button>

                <Button
                  variant='primary'
                  onClick={this.handleConfirm}
                  loading={this.state.loading}
                  disabled={!this.allFieldsSet() || this.isColumnPermissionWithNoSelectedColumns()}
                >
                  {capitalize(this.props.permissionType)}
                </Button>
              </div>
            }
          >
            <SpaceBetween size='l'>
              <ResourceSelector
                onTableChange={this.setTable}
                onDatabaseChange={this.setDatabase}
                databaseName={this.state.databaseName}
                tableName={this.state.tableName}
                catalogId={this.state.catalogId}
                activeGroup={this.props.activeGroup}
              />
              <RecipientSelector
                activeGroup={this.props.activeGroup}
                onChange={this.setPrincipal}
                onRamRoleChange={this.setRamRole}
              />
              <PermissionSelector
                table={this.state.table}
                database={this.state.database}
                principal={this.state.principal}
                onChange={this.setResource}
                permissions={this.state.permissions}
                onPermissionsChange={this.setPermissions}
              />
              <UseCase onChange={this.setUseCase} value={this.state.useCase} formSubmitted={this.state.formSubmitted} />
              {this.state.additionalMetadata.length > 0 &&
                this.state.principal &&
                this.state.principal.DataLakePrincipalIdentifier && (
                  <TemplateRequestDetailsContainer
                    additionalMetadata={this.state.additionalMetadata}
                    setAdditionalMetadata={(metadata) => {
                      this.setState({ additionalMetadata: metadata });
                    }}
                  />
                )}
            </SpaceBetween>
          </Form>
        </div>
      </div>
    );
  }
}
