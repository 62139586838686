import { useEffect } from 'react';
import * as React from 'react';
import { SpaceBetween, Header } from '@amzn/awsui-components-react-v3/polaris';
import { ManageDatasets } from './index';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';

export interface PublishTablesComponentProps {
  setContentType: any;
  activeGroup: string;

  toggleHelp(): void;

  title: string;
}

const PublishTablesComponent = (props: PublishTablesComponentProps) => {
  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  return (
    <SpaceBetween size='xl'>
      <Header variant='h1' description={'Easily ingest your S3, or DynamoDB data to a data lake with Tethys.'}>
        Tethys
      </Header>
      <ManageDatasets
        toggleHelp={props.toggleHelp}
        activeGroup={props.activeGroup}
        setContentType={props.setContentType}
      />
    </SpaceBetween>
  );
};

export default PublishTablesComponent;
