import * as React from 'react';
import { useState, useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { defaultPageSizePreference, defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  Table,
  Header,
  Button,
  SpaceBetween,
  Box,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { createWorkflowStatusLink, Page } from 'src/routes';
import { DZEntity } from 'aws-sdk/clients/awsdatalakegladstonelambda';

export interface DataZoneEnvironmentsTableProps {
  setContentType: any;
  activeGroup: string;
  activeWorkspace?: any;
  nextToken?: string;
  loadMoreResults?: any;
  loading: boolean;
  environmentEntities: DZEntity[];
}

export const DataZoneEnvironmentsTable = (props: DataZoneEnvironmentsTableProps) => {
  const [redirect, setRedirect] = useState<string>(undefined);
  const [tableMessage] = useState('No environments');
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const statusCell = (item: DZEntity) => {
    const status = item.status;
    const statusToTypeMapping = {
      Success: 'success',
      Failed: 'error',
      Pending: 'loading',
    };
    const type = statusToTypeMapping[status];
    const message = status == 'Success' ? status : `${status} (${item.statusReason})`;
    return <StatusIndicator type={type}>Success</StatusIndicator>;
  };

  const columnDefinitions: TableProps.ColumnDefinition<DZEntity>[] = [
    {
      id: 'workspaceId',
      header: 'Workspace ID',
      cell: (item) => item.workspaceId,
      width: 250,
      sortingField: 'workspaceId',
    },
    {
      id: 'workflowId',
      header: 'Workflow ID',
      cell: (item) =>
        item.workflowConfig && item.workflowConfig.onboardWorkflowId ? (
          <Link to={createWorkflowStatusLink(item.workflowConfig.onboardWorkflowId)}>
            {' '}
            {item.workflowConfig.onboardWorkflowId}{' '}
          </Link>
        ) : (
          ''
        ),
      width: 330,
      sortingField: 'workflowId',
    },
    {
      id: 'environmentId',
      header: 'Environment ID',
      cell: (item) => item.environmentId,
      width: 120,
    },
    {
      id: 'project',
      header: 'Project name',
      cell: (item) => item.groupId,
      width: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => statusCell(item),
      width: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.subtype,
      width: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
  }, []);

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    props.environmentEntities,
    {
      filtering: {
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No environments to display.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  const handleClickCreate = () => {
    setRedirect(Page.DATAZONE_ONBOARD);
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <Table
        {...collectionProps}
        loadingText='Loading environments...'
        loading={props.loading}
        columnDefinitions={columnDefinitions}
        items={items}
        empty={
          <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
            <SpaceBetween size='m'>
              <b>No environments</b>
              <p>There are no environments available.</p>
            </SpaceBetween>
          </Box>
        }
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <Header
            counter={'(' + props.environmentEntities.length + ')'}
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant={'normal'} onClick={handleClickCreate}>
                  Create environment
                </Button>
              </SpaceBetween>
            }
          >
            DataZone environments
          </Header>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={defaultPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
