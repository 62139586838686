import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Page } from './Paths';
import {
  advisoriesHelp,
  advisoryCreateOrUpdateHelp,
  advisoryDetailHelp,
  advisorySendNotificationHelp,
  advisoryTemplateDetailHelp,
  advisoryTemplatesHelp,
  bootstrapactionsHelp,
  catalogDetailsHelp,
  catalogHelp,
  connectionsHelp,
  createLakeFormationRequestHelp,
  createNotificationHelp,
  dataZoneManageHelp,
  dataZoneOnboardHelp,
  groupsHelp,
  myDatasetsHelp,
  new_access_request_help,
  notificationHelp,
  permissionsHelp,
  publishDataSetShareHelp,
  publisherHelp,
  redshiftQueryHelp,
  registerLakeFormationCatalogHelp,
  registerRedshiftCatalogHelp,
  resourcegroupsHelp,
  searchHelp,
  subscriptionHelp,
  tagsHelp,
  templatesHelp,
  tethysHelp,
  workspace_access_help,
  workspaceDatasetsHelp,
} from './helpData';

export interface HelpProps {
  setToolsHide: any;
  content?: React.ReactNode;
}
export interface HelpState {}

export class WrapHelp extends React.Component<HelpProps, HelpState> {
  componentDidMount() {
    this.props.setToolsHide(false);
  }
  render() {
    return this.props.content;
  }
}

export class NoHelp extends React.Component<HelpProps, HelpState> {
  componentDidMount() {
    this.props.setToolsHide(true);
  }
  render() {
    return '';
  }
}

export const Help = (props) => (
  <Switch>
    <Route path={[Page.SUBSCRIPTIONS]} render={() => <WrapHelp {...props} content={subscriptionHelp} />} />
    <Route path={[Page.CONNECTIONS]} render={() => <WrapHelp {...props} content={connectionsHelp} />} />
    <Route
      path={[Page.BROWSE_DATASETS, Page.BROWSE_DATABASES, Page.BROWSE_CATALOGS]}
      exact
      render={() => <WrapHelp {...props} content={catalogHelp} />}
    />
    <Route path={[Page.DATASET_DETAILS]} render={() => <WrapHelp {...props} content={catalogDetailsHelp} />} />
    <Route path={[Page.MY_DATASETS]} exact render={() => <WrapHelp {...props} content={permissionsHelp} />} />
    <Route path={[Page.PUBLISH]} exact render={() => <WrapHelp {...props} content={publishDataSetShareHelp} />} />
    <Route path={[Page.MY_DATASETS]} exact render={() => <WrapHelp {...props} content={myDatasetsHelp} />} />
    <Route
      path={[Page.CREATE_LAKE_FORMATION_PERMISSIONS]}
      exact
      render={() => <WrapHelp {...props} content={createLakeFormationRequestHelp} />}
    />
    <Route path={[Page.DATASETSHARE_DETAILS]} exact render={() => <WrapHelp {...props} content={myDatasetsHelp} />} />
    <Route path={[Page.PUBLISHER_TABLE_DETAILS]} exact render={() => <WrapHelp {...props} content={publisherHelp} />} />
    <Route path={[Page.NOTIFICATIONS]} exact render={() => <WrapHelp {...props} content={notificationHelp} />} />
    <Route
      path={[Page.CREATE_NOTIFICATION]}
      exact
      render={() => <WrapHelp {...props} content={createNotificationHelp} />}
    />
    <Route path={[Page.GROUP]} exact render={() => <WrapHelp {...props} content={groupsHelp} />} />
    <Route path={[Page.RESOURCEGROUPS]} exact render={() => <WrapHelp {...props} content={resourcegroupsHelp} />} />
    <Route path={[Page.BOOTSTRAPACTIONS]} exact render={() => <WrapHelp {...props} content={bootstrapactionsHelp} />} />
    <Route path={[Page.WORKSPACEACCESS]} exact render={() => <WrapHelp {...props} content={workspace_access_help} />} />
    <Route
      path={[Page.WORKSPACE_MY_REQUESTS]}
      exact
      render={() => <WrapHelp {...props} content={new_access_request_help} />}
    />
    <Route path={[Page.TAGS]} exact render={() => <WrapHelp {...props} content={tagsHelp} />} />
    <Route
      path={[Page.INGESTION.REGISTER_CONTRACT]}
      exact
      render={() => <WrapHelp {...props} content={tethysHelp} />}
    />
    <Route path={[Page.PUBLISHER_TABLES]} exact render={() => <WrapHelp {...props} content={tethysHelp} />} />
    <Route path={[Page.INGESTION.UPDATE_CONTRACT]} render={() => <WrapHelp {...props} content={tethysHelp} />} />
    <Route path={[Page.INGESTION.DETAIL]} render={() => <WrapHelp {...props} content={tethysHelp} />} />
    <Route path={[Page.INGESTION.MONITORING]} render={() => <WrapHelp {...props} content={tethysHelp} />} />
    <Route
      path={[Page.WORKSPACE_REDSHIFT_REGISTER]}
      render={() => <WrapHelp {...props} content={registerRedshiftCatalogHelp} />}
    />
    <Route
      path={[Page.WORKSPACE_GLUE_REGISTER]}
      render={() => <WrapHelp {...props} content={registerLakeFormationCatalogHelp} />}
    />
    <Route path={[Page.SEARCH]} render={() => <WrapHelp {...props} content={searchHelp} />} />
    <Route path={[Page.WORKSPACE_REDSHIFT_QUERY]} render={() => <WrapHelp {...props} content={redshiftQueryHelp} />} />
    <Route path={[Page.TEMPLATES]} render={() => <WrapHelp {...props} content={templatesHelp} />} />
    <Route
      path={[Page.WORKSPACES_BROWSE_DATASETS, Page.WORKSPACES_BROWSE_DATABASES, Page.WORKSPACES_BROWSE_CATALOGS]}
      render={() => <WrapHelp {...props} content={workspaceDatasetsHelp} />}
    />
    <Route
      path={[
        Page.CREATE_ADVISORY,
        Page.UPDATE_ADVISORY,
        Page.UPDATE_ADVISORY_TEMPLATE,
        Page.CREATE_ADVISORY_FROM_TEMPLATE,
        Page.CREATE_ADVISORY_FROM_ADVISORY,
      ]}
      render={() => <WrapHelp {...props} content={advisoryCreateOrUpdateHelp} />}
    />
    <Route
      path={[Page.ADVISORY_SEND_NOTIFICATION]}
      render={() => <WrapHelp {...props} content={advisorySendNotificationHelp} />}
    />
    <Route
      path={[Page.ADVISORY_TEMPLATE_DETAIL]}
      render={() => <WrapHelp {...props} content={advisoryTemplateDetailHelp} />}
    />
    <Route
      path={[Page.LIST_ADVISORY_TEMPLATES]}
      render={() => <WrapHelp {...props} content={advisoryTemplatesHelp} />}
    />
    <Route path={[Page.ADVISORY_DETAIL]} render={() => <WrapHelp {...props} content={advisoryDetailHelp} />} />
    <Route path={[Page.LIST_ADVISORIES]} render={() => <WrapHelp {...props} content={advisoriesHelp} />} />
    <Route path={[Page.DATAZONE_ONBOARD]} render={() => <WrapHelp {...props} content={dataZoneOnboardHelp} />} />
    <Route path={[Page.DATAZONE_MANAGE]} render={() => <WrapHelp {...props} content={dataZoneManageHelp} />} />

    <Route path={['*']} render={() => <NoHelp {...props} />} />
  </Switch>
);

export default Help;
