import {
  DZ_REDSHIFT_DATASOURCE_ID,
  IAM_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
} from 'src/commons/constants';

export const getDataSource = (resourceDetails) => {
  //This helper is used as default value if we can't get the datasource from the item itself.
  if (resourceDetails.ClusterIdentifier != null || resourceDetails.RedshiftWorkgroupName != null) {
    return REDSHIFT_DATASOURCE_ID;
  } else if (resourceDetails.DataAccessRole == null) {
    return IAM_DATASOURCE_ID;
  } else {
    return LAKE_FORMATION_DATASOURCE_ID;
  }
};

export const generateCatalogId = (catalog) => {
  const dataSource = catalog.DataSourceId ? catalog.DataSourceId : getDataSource(catalog);
  if (dataSource == REDSHIFT_DATASOURCE_ID || dataSource == DZ_REDSHIFT_DATASOURCE_ID) {
    if (catalog.ClusterIdentifier) {
      return `DS-${dataSource}|A-${catalog.CatalogId}|CI-${catalog.ClusterIdentifier}|R-${catalog.Region}`;
    } else {
      return `DS-${dataSource}|A-${catalog.CatalogId}|WN-${catalog.RedshiftWorkgroupName}|R-${catalog.Region}`;
    }
  } else {
    return `DS-${dataSource}|A-${catalog.CatalogId}|R-${catalog.Region}`;
  }
};

export const generateDatabaseId = (database) => {
  const dataSource = database.DataSourceId ? database.DataSourceId : getDataSource(database);
  if (dataSource == REDSHIFT_DATASOURCE_ID || dataSource == DZ_REDSHIFT_DATASOURCE_ID) {
    if (database.ClusterIdentifier) {
      return `DS-${dataSource}|A-${database.CatalogId}|CI-${database.ClusterIdentifier}|DN-${database.DatabaseName}|R-${database.Region}`;
    } else {
      return `DS-${dataSource}|A-${database.CatalogId}|WN-${database.RedshiftWorkgroupName}|DN-${database.DatabaseName}|R-${database.Region}`;
    }
  } else {
    return `DS-${dataSource}|A-${database.CatalogId}|DN-${database.DatabaseName}|R-${database.Region}`;
  }
};

export const generateDatasetId = (dataset) => {
  return dataset.Id;
};

export const generateSchemaId = (schema) => {
  const dataSource = schema.DataSourceId ? schema.DataSourceId : REDSHIFT_DATASOURCE_ID;
  if (schema.ClusterIdentifier) {
    return `DS-${dataSource}|A-${schema.CatalogId}|CI-${schema.ClusterIdentifier}|DN-${schema.DatabaseName}|SN-${schema.Schema}|R-${schema.Region}`;
  } else {
    return `DS-${dataSource}|A-${schema.CatalogId}|WN-${schema.RedshiftWorkgroupName}|DN-${schema.DatabaseName}|SN-${schema.Schema}|R-${schema.Region}`;
  }
};

export const getDatasetUpperLevelResourceId = (dataset) => {
  let dataSourceId = dataset.IdInfo.DataSourceId;
  if (dataSourceId == LAKE_FORMATION_DATASOURCE_ID) {
    return `DS-${dataSourceId}|A-${dataset.IdInfo.CatalogId}|DN-${dataset.IdInfo.DatabaseName}|R-${dataset.IdInfo.Region}`;
  } else {
    if (dataset.IdInfo.ClusterIdentifier) {
      return `DS-${dataSourceId}|A-${dataset.IdInfo.CatalogId}|CI-${dataset.IdInfo.ClusterIdentifier}|DN-${dataset.IdInfo.DatabaseName}|SN-${dataset.IdInfo.SchemaName}|R-${dataset.IdInfo.Region}`;
    } else {
      return `DS-${dataSourceId}}|A-${dataset.IdInfo.CatalogId}|WN-${dataset.IdInfo.RedshiftWorkgroupName}|DN-${dataset.IdInfo.DatabaseName}|SN-${dataset.IdInfo.SchemaName}|R-${dataset.IdInfo.Region}`;
    }
  }
};
