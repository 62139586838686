import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Pagination,
  PropertyFilter,
  Table,
} from '@amzn/awsui-components-react-v3';
import { PageHeader } from 'src/components/notifications/common';
import {
  defaultWrapLinesPreference,
  i18nStrings,
  mediumPageSizePreference,
  paginationLabels,
} from 'src/commons/tables';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { listDatabases } from 'src/api/catalog';
import { Link } from 'react-router-dom';
import { createCatalogDetailLink, createDatabaseDetailLink } from 'src/routes';
import { DZ_REDSHIFT_DATASOURCE_ID, REDSHIFT_DATASOURCE_ID, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { keyForCatalogNameMap } from 'src/commons/common';

export interface WSDatabaseListProps {
  setContentType: any;
  activeGroup: string;
  username: string;
  activeWorkspace: any;
  catalogId: any;
  catalogName: string;
  catalogRegion: string;
  clusterIdentifier: string;
  catalogMap: any;
}

export const WSDatabaseList = (props: WSDatabaseListProps) => {
  const [allItems, setItems] = useState([]);
  const [loadingDatabase, setLoadingDatabase] = useState(false);

  const handleRefresh = async () => {
    setLoadingDatabase(true);
    let request = { NextToken: null };
    let databases = await listDatabases(request);
    let databaseInfoList = databases.DatabaseInfoList;
    while (databases.NextToken != null) {
      request.NextToken = databases.NextToken;
      databases = await listDatabases(request);
      databaseInfoList.push(...databases.DatabaseInfoList);
    }
    var databasesInCatalog = [];
    for (const database of databaseInfoList) {
      if (
        database.CatalogId == props.catalogId &&
        database.Region == props.catalogRegion &&
        database.ClusterIdentifier == props.clusterIdentifier
      ) {
        databasesInCatalog.push(database);
      }
    }
    for (let database of databasesInCatalog) {
      let catalogName = props.catalogMap.get(
        keyForCatalogNameMap(
          database?.DataSourceId,
          database?.CatalogId,
          database?.ClusterIdentifier,
          database?.RedshiftWorkgroupName,
          database?.Region,
        ),
      );
      database.CatalogName = catalogName == null ? database?.CatalogId : catalogName;
    }
    setItems(databasesInCatalog);
    setLoadingDatabase(false);
  };

  useEffect(() => {
    handleRefresh();
  }, [props.catalogId]);

  // useEffect(() => {
  //   props.setContentType(TABLE_CONTENT_TYPE);
  //   handleRefresh();
  // }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'catalogName',
      header: 'Catalog name',
      cell: (item) => (
        <Link
          to={createCatalogDetailLink(
            item?.DataSourceId,
            item?.CatalogId,
            item?.ClusterIdentifier,
            item?.RedshiftWorkgroupName,
            item?.Region,
          )}
        >
          {item.CatalogName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'databaseName',
      header: 'Database name',
      cell: (item) => (
        <Link
          to={createDatabaseDetailLink(
            item?.DataSourceId,
            item?.CatalogId,
            item?.ClusterIdentifier,
            item?.RedshiftWorkgroupName,
            item?.DatabaseName,
            item?.Region,
          )}
        >
          {item.DatabaseName}
        </Link>
      ),
      minWidth: 200,
    },
    {
      id: 'description',
      header: 'Database description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
  ];

  const { items, collectionProps, paginationProps, propertyFilterProps, filteredItemsCount } = useCollection(allItems, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [
        {
          propertyLabel: 'Database name',
          key: 'DatabaseName',
          groupValuesLabel: 'Database names',
        },
      ],
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        loadingText='Loading databases...'
        loading={loadingDatabase}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[]}
              header={
                <>
                  Databases
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
            />
          </>
        }
        empty={
          <div className='awsui-util-t-c'>
            <p className='awsui-util-mb-s'>No registered databases</p>
          </div>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={mediumPageSizePreference}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
        filter={
          <PropertyFilter
            {...propertyFilterProps}
            disabled={loadingDatabase}
            i18nStrings={i18nStrings}
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
      />
    </>
  );
};
