import { useEffect, useState } from 'react';
import { ListAdvisoriesFilter, ListAdvisoriesRequest } from 'aws-sdk/clients/awsdlomni';
import { listAdvisories } from 'src/api/notifications';
import { DataAdvisoryTable } from 'src/components/dataadvisory/listAdvisoriesPage/advisoryTable';
import React from 'react';
import { activeGroupOrWorkspaceId } from 'src/components/dataadvisory/constants';

export interface AdvisoriesForResourceTableProps {
  resourceArn: string;
  activeGroup: string;
  activeWorkspace?: any;
  setContentType: any;
}

export const AdvisoriesForResourceTable = (props: AdvisoriesForResourceTableProps) => {
  const [advisories, setAdvisories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    const filter: ListAdvisoriesFilter = {
      quickFilters: {
        allAdvisories: true,
        advisoriesForDataYouOwn: false,
        advisoriesForDataYouHaveAccessTo: false,
      },
      resources: {
        arns: [props.resourceArn],
        operator: 'Or',
      },
    };
    const request: ListAdvisoriesRequest = {
      filter: filter,
      ownerId: activeGroupOrWorkspaceId(props),
    };
    try {
      const result = await listAdvisories(request);
      console.log(result);
      setAdvisories(result.advisories);
    } catch (e) {
      console.log('Failed to fetch advisories', e);
    }
    setLoading(false);
  };
  return (
    <DataAdvisoryTable
      setContentType={props.setContentType}
      activeGroup={props.activeGroup}
      activeWorkspace={props.activeWorkspace}
      advisories={advisories}
      loading={loading}
    />
  );
};
