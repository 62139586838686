import { AwsRum } from 'aws-rum-web';
import { getRUMConfig, RumConfig } from 'src/api/config';
import { getUser } from 'src/api/auth';
import { AwsCredentialIdentity } from '@aws-sdk/types';

let awsRum: AwsRum;

export async function initRumClient(credentials: AwsCredentialIdentity) {
  try {
    const rumConfig: RumConfig = getRUMConfig();
    if (rumConfig === undefined) return;
    awsRum = new AwsRum(
      rumConfig.applicationId,
      rumConfig.applicationVersion,
      rumConfig.applicationRegion,
      rumConfig.awsRumConfig,
    );
    awsRum.setAwsCredentials(credentials);
    awsRum.addSessionAttributes({
      userAlias: await getUser(),
    });
    awsRum.enable();
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
