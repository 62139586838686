import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { createGroupDetailLink, createWorkspaceDetailLink, Page } from 'src/routes';
import * as React from 'react';
import { ColumnLayout, StatusIndicator } from '@amzn/awsui-components-react-v3/polaris';
import { isValidWorkspace } from 'src/commons/validationUtils';
import { Link } from 'react-router-dom';
import { ExternalLinkItem } from 'src/components/permissions/myBaselining/steps-config';
import { Alert, Box, Button, Header, Modal, SpaceBetween, Table, Textarea } from '@amzn/awsui-components-react-v3';
import {
  convertApprovalTemplateIDToLink,
  convertApprovalWorkflowIDToLink,
} from 'src/components/permissions/myPermissions/common';
import { useState } from 'react';

export const tagBasedColumnDefinition = (isIncomingRequests): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'requestId',
    header: 'Request ID',
    cell: (item) =>
      item.requestId ? <Link to={Page.WORKSPACE_MY_REQUESTS + '/' + item.requestId}>{item.requestId}</Link> : '',
    minWidth: '200px',
  },
  {
    id: 'resourceTag',
    header: 'Resource tag',
    cell: (item) => item.resourceTag,
    minWidth: '200px',
    sortingField: 'resourceTag',
  },
  isIncomingRequests
    ? {
        id: 'requestedWorkspace',
        header: 'Requested owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedWorkspace)
                ? createWorkspaceDetailLink(item.requestedWorkspace)
                : createGroupDetailLink(item.requestedWorkspace)
            }
          >
            {item.requestedWorkspace}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedWorkspace',
      }
    : {
        id: 'requestedTo',
        header: 'Requested to owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedTo)
                ? createWorkspaceDetailLink(item.requestedTo)
                : createGroupDetailLink(item.requestedTo)
            }
          >
            {item.requestedTo}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedTo',
      },
  {
    id: 'requestedBy',
    header: 'Requested by',
    cell: (item) => item.requestedBy,
    minWidth: '100px',
    sortingField: 'requestedBy',
  },
  {
    id: 'requestedTime',
    header: 'Requested time',
    cell: (item) => item.requestedTime,
    minWidth: '100px',
    sortingField: 'requestedTime',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => (
      <div>
        {item.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
        {item.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
        {item.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
        {item.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
        {item.status == 'RUNNING' && <StatusIndicator type='pending'>Running</StatusIndicator>}
        {item.status == 'FAILED' && <StatusIndicator type='error'>Failed</StatusIndicator>}
      </div>
    ),
    minWidth: '125px',
    sortingField: 'status',
  },
  {
    id: 'statusReason',
    header: 'Status reason',
    cell: (item) => item.statusReason,
    minWidth: '125px',
    sortingField: 'statusReason',
  },
];

export const resourceBasedColumnDefinition = (isIncomingRequests): TableProps.ColumnDefinition<any>[] => [
  {
    id: 'requestId',
    header: 'Request ID',
    cell: (item) =>
      item.requestId ? <Link to={Page.WORKSPACE_MY_REQUESTS + '/' + item.requestId}>{item.requestId}</Link> : '',
    minWidth: '200px',
  },
  {
    id: 'permissionType',
    header: 'Permission Type',
    cell: (item) => item.permissionType,
    minWidth: '150px',
    sortingField: 'permissionType',
  },
  {
    id: 'catalogId',
    header: 'Catalog',
    cell: (item) => item.catalogId,
    minWidth: '200px',
    sortingField: 'catalogId',
  },
  {
    id: 'databaseName',
    header: 'Database name',
    cell: (item) => item.databaseName,
    minWidth: '200px',
    sortingField: 'databaseName',
  },
  {
    id: 'tableName',
    header: 'Table name',
    cell: (item) => (item.tableName != undefined ? item.tableName : 'All Tables'),
    minWidth: '200px',
    sortingField: 'tableName',
  },
  {
    id: 'fgapName',
    header: 'Fine grain policy',
    cell: (item) =>
      item.fgapId ? (
        <Link to={Page.VIEW_FINE_GRAIN_ACCESS_POLICY.replace(':id', item.fgapId)}>{item.fgapName}</Link>
      ) : (
        ''
      ),
    minWidth: '200px',
    sortingField: 'fgapName',
  },
  isIncomingRequests
    ? {
        id: 'requestedWorkspace',
        header: 'Requested owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedWorkspace)
                ? createWorkspaceDetailLink(item.requestedWorkspace)
                : createGroupDetailLink(item.requestedWorkspace)
            }
          >
            {item.requestedWorkspace}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedWorkspace',
      }
    : {
        id: 'requestedTo',
        header: 'Requested to owner',
        cell: (item) => (
          <Link
            to={
              isValidWorkspace(item.requestedTo)
                ? createWorkspaceDetailLink(item.requestedTo)
                : createGroupDetailLink(item.requestedTo)
            }
          >
            {item.requestedTo}
          </Link>
        ),
        minWidth: '100px',
        sortingField: 'requestedTo',
      },
  {
    id: 'requestedBy',
    header: 'Requested by',
    cell: (item) => item.requestedBy,
    minWidth: '100px',
    sortingField: 'requestedBy',
  },
  {
    id: 'requestedTime',
    header: 'Requested time',
    cell: (item) => item.requestedTime,
    minWidth: '100px',
    sortingField: 'requestedTime',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (item) => (
      <div>
        {item.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
        {item.status == 'RUNNING' && <StatusIndicator type='pending'>Running</StatusIndicator>}
        {item.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
        {item.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
        {item.status == 'FAILED' && <StatusIndicator type='error'>Failed</StatusIndicator>}
        {item.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
      </div>
    ),
    minWidth: '125px',
    sortingField: 'status',
  },
  {
    id: 'statusReason',
    header: 'Status reason',
    cell: (item) => item.statusReason,
    minWidth: '100px',
    sortingField: 'statusReason',
  },
];

export const ResourceBasedRequestInformation = ({ request }) => (
  <>
    <div className='awsui-util-container'>
      <div className='awsui-util-container-header'>
        <h2>Request details</h2>
      </div>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Requested by</div>
          <div> {request.requestedBy}</div>
        </div>
        {request.requestWorkspace && (
          <div>
            <div className='awsui-util-label'>Requested workspace</div>
            <div>
              <Link
                to={
                  isValidWorkspace(request.requestWorkspace)
                    ? createWorkspaceDetailLink(request.requestWorkspace)
                    : createGroupDetailLink(request.requestWorkspace)
                }
              >
                {request.requestWorkspace}
              </Link>
            </div>
          </div>
        )}
        {request.decidedBy && (
          <div>
            <div className='awsui-util-label'>Decided by</div>
            <div> {request.decidedBy}</div>
          </div>
        )}

        {request.owner && (
          <div>
            <div className='awsui-util-label'>Data owner</div>
            <div>
              <Link
                to={
                  isValidWorkspace(request.owner)
                    ? createWorkspaceDetailLink(request.owner)
                    : createGroupDetailLink(request.owner)
                }
              >
                {request.owner}
              </Link>
            </div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Status</div>
          <div>
            {request.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
            {request.status == 'RUNNING' && <StatusIndicator type='pending'>Running</StatusIndicator>}
            {request.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
            {request.status == 'FAILED' && <StatusIndicator type='error'>Failed</StatusIndicator>}
            {request.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
            {request.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
          </div>
        </div>
        {request.statusReason && (
          <div>
            <div className='awsui-util-label'>Status reason</div>
            <div> {request.statusReason}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Request date</div>
          <div> {request.timeOfRequest}</div>
        </div>
        {request.timeOfDecision && (
          <div>
            <div className='awsui-util-label'>Decision date</div>
            <div> {request.timeOfDecision}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>External account</div>
          <div> {request.principal}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Catalog ID</div>
          <div> {request.catalogId}</div>
        </div>
        {request.clusterId && (
          <div>
            <div className='awsui-util-label'>Cluster ID</div>
            <div> {request.clusterId}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Database name</div>
          <div> {request.databaseName}</div>
        </div>
        {request.schemaName && (
          <div>
            <div className='awsui-util-label'>Schema name</div>
            <div> {request.schemaName}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Table name</div>
          <div> {request.tableName}</div>
        </div>
        {request.fgapName && (
          <div>
            <div className='awsui-util-label'>Fine grain policy</div>
            <div> {request.fgapName}</div>
          </div>
        )}
        {request.columnNames && (
          <div>
            <div className='awsui-util-label'>Columns ({request.columnWildCard})</div>
            <div> {request.columnNames}</div>
          </div>
        )}

        {request.columnWildCard && (
          <div>
            <div className='awsui-util-label'>Columns wildcard</div>
            <div> {request.columnWildCard}</div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Permissions</div>
          <div> {request.permissions}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Grantable permissions</div>
          <div> {request.grantablePermissions}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Use case</div>
          <div> {request.useCase ? request.useCase : ' '}</div>
        </div>
        {request.reasonOfAction && (
          <div>
            <div className='awsui-util-label'>Reason for action</div>
            <div> {request.reasonOfAction}</div>
          </div>
        )}
        {request.simTickets?.length > 0 && (
          <div>
            <div className='awsui-util-label'>SIM ticket links</div>
            <div>
              {request.simTickets.map((st) => {
                return (
                  <>
                    <Link to={st?.simTicketLink}>{st?.simTemplateName}</Link> <br />
                  </>
                );
              })}
            </div>
          </div>
        )}
      </ColumnLayout>
    </div>
    {request.approvalsWorkflow?.length > 0 && (
      <Table
        columnDefinitions={[
          {
            id: 'approvalTemplateId',
            header: 'Approval template ID',
            cell: (e) => (
              <a href={convertApprovalTemplateIDToLink(e['approvalTemplateId'])} target='_blank'>
                {e['approvalTemplateId']}
              </a>
            ),
          },
          {
            id: 'approvalWorkflowId',
            header: 'Approval workflow ID',
            cell: (e) => (
              <a href={convertApprovalWorkflowIDToLink(e['approvalWorkflowId'])} target='_blank'>
                {e['approvalWorkflowId']}
              </a>
            ),
          },
          {
            id: 'approvalWorkflowStatus',
            header: 'Approval workflow status',
            cell: (e) => e['approvalWorkflowStatus'],
          },
        ]}
        items={deDupeApprovalsInfoByApprovalWorkflowId(request.approvalsWorkflow)}
        loadingText='Loading resources'
        header={<Header>Approval workflow details</Header>}
      />
    )}
  </>
);

export const deDupeApprovalsInfoByApprovalWorkflowId = (approvalsWorkflow) => {
  let uniqueApprovalWorkflowIds = new Set();
  let uniqueApprovalInfos = [];
  approvalsWorkflow.forEach((approvalsInfo) => {
    if (!uniqueApprovalWorkflowIds.has(approvalsInfo.approvalWorkflowId)) {
      uniqueApprovalWorkflowIds.add(approvalsInfo.approvalWorkflowId);
      uniqueApprovalInfos.push(approvalsInfo);
    }
  });
  return uniqueApprovalInfos;
};

export const TagBasedRequestInformation = ({ request }) => (
  <>
    <div className='awsui-util-container'>
      <div className='awsui-util-container-header'>
        <h2>Request details</h2>
      </div>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Requested by</div>
          <div> {request.requestedBy}</div>
        </div>
        {request.requestWorkspace != null && (
          <div>
            <div className='awsui-util-label'>Request workspace</div>
            <div>
              <Link
                to={
                  isValidWorkspace(request.requestWorkspace)
                    ? createWorkspaceDetailLink(request.requestWorkspace)
                    : createGroupDetailLink(request.requestWorkspace)
                }
              >
                {request.requestWorkspace}
              </Link>
            </div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Decided by</div>
          <div> {request.decidedBy}</div>
        </div>
        {request.owner != null && (
          <div>
            <div className='awsui-util-label'>Data owner</div>
            <div>
              <Link
                to={
                  isValidWorkspace(request.owner)
                    ? createWorkspaceDetailLink(request.owner)
                    : createGroupDetailLink(request.owner)
                }
              >
                {request.owner}
              </Link>
            </div>
          </div>
        )}
        <div>
          <div className='awsui-util-label'>Status</div>
          <div>
            {request.status == 'PENDING' && <StatusIndicator type='pending'>Pending</StatusIndicator>}
            {request.status == 'APPROVED' && <StatusIndicator>Approved</StatusIndicator>}
            {request.status == 'DENIED' && <StatusIndicator type='error'>Denied</StatusIndicator>}
            {request.status == 'CANCELED' && <StatusIndicator type='stopped'>Canceled</StatusIndicator>}
          </div>
        </div>
        <div>
          <div className='awsui-util-label'>Request date</div>
          <div> {request.timeOfRequest}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Decision date</div>
          <div> {request.timeOfDecision}</div>
        </div>
        <div>{/* This is a space */}</div>
        <div>
          <div className='awsui-util-label'>External account</div>
          <div> {request.principal}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Resource tag</div>
          <div> {request.resourceTag}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Permissions</div>
          <div> {request.permissions}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Grantable permissions</div>
          <div> {request.grantablePermissions}</div>
        </div>
      </ColumnLayout>
      <ColumnLayout columns={4} variant='text-grid'>
        <div>
          <div className='awsui-util-label'>Use case</div>
          <div> {request.useCase ? request.useCase : ' '}</div>
        </div>
        <div>
          <div className='awsui-util-label'>Reason for action</div>
          <div> {request.reasonOfAction ? request.reasonOfAction : ' '}</div>
        </div>
        <div>
          <div className='awsui-util-label'>SIM ticket links</div>
          <div>
            {request?.simTickets?.length != 0 ? (
              <ul>
                {request?.simTickets?.map((st) => {
                  return (
                    <li>
                      <ExternalLinkItem text={st?.simTemplateName} href={st?.simTicketLink} />
                    </li>
                  );
                })}
              </ul>
            ) : (
              ' '
            )}
          </div>
        </div>
      </ColumnLayout>
    </div>
    {request.approvalsWorkflow?.length > 0 && (
      <Table
        columnDefinitions={[
          {
            id: 'approvalTemplateId',
            header: 'Approval template ID',
            cell: (e) => (
              <a href={convertApprovalTemplateIDToLink(e['approvalTemplateId'])} target='_blank'>
                {e['approvalTemplateId']}
              </a>
            ),
          },
          {
            id: 'approvalWorkflowId',
            header: 'Approval workflow ID',
            cell: (e) => (
              <a href={convertApprovalWorkflowIDToLink(e['approvalWorkflowId'])} target='_blank'>
                {e['approvalWorkflowId']}
              </a>
            ),
          },
          {
            id: 'approvalWorkflowStatus',
            header: 'Approval workflow status',
            cell: (e) => e['approvalWorkflowStatus'],
          },
        ]}
        items={deDupeApprovalsInfoByApprovalWorkflowId(request.approvalsWorkflow)}
        loadingText='Loading resources'
        header={<Header>Approval workflow details</Header>}
      />
    )}
  </>
);

interface DenyOrCancelModalProps {
  modalVisible: boolean;
  action: string;
  closeModal: any;
  buttonLoading: boolean;
  submitAction: any;
  approvalWorkflowStatusOutOfSync: boolean;
  textAreaPlaceholder: string;
  reasonOfAction: string;
  setReasonOfAction: any;
}

export const DenyOrCancelModal = (props: DenyOrCancelModalProps) => {
  const [isInvalid, setIsInvalid] = useState(true);

  return (
    <Modal
      visible={props.modalVisible}
      header={props.action === 'cancel' ? 'Enter reason for canceling' : 'Enter reason for denying'}
      onDismiss={() => props.closeModal()}
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => props.closeModal()}>
              No
            </Button>
            <Button variant='primary' loading={props.buttonLoading} onClick={() => props.submitAction(props.action)}>
              Yes
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {props.approvalWorkflowStatusOutOfSync && props.action == 'deny' && (
        <div>
          {' '}
          <Alert statusIconAriaLabel='Warning' type='warning'>
            The approval workflow associated with this request has been approved or pending. Make sure you want to deny
            this request.
          </Alert>
          <br />
        </div>
      )}
      <Textarea
        value={props.reasonOfAction}
        onChange={({ detail }) => {
          props.setReasonOfAction(detail.value);
          setIsInvalid(!props.reasonOfAction || props.reasonOfAction.length == 0);
        }}
        placeholder={props.textAreaPlaceholder}
        invalid={isInvalid}
      />
      Are you sure you want to {props.action} this request?
    </Modal>
  );
};
