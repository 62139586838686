import { Link, Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  associateGlossaryToResource,
  associateMetadataFormToResource,
  deleteMetadataForm,
  listMetadataForms,
} from 'src/api/catalog';
import { METADATA_FORM_PREFIX, TABLE_CONTENT_TYPE } from 'src/commons/constants';
import { Page } from 'src/routes';
import {
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Flashbar,
  Modal,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as React from 'react';
import { defaultWrapLinesPreference, notificationsPageSizePreference, paginationLabels } from 'src/commons/tables';
import { AssociateMetadataFormModal } from 'src/components/metadata/metadataForms/associateMetadataFormModal';
import MetadataFormFieldsTable from 'src/components/metadata/metadataFields/metadataFormFields';
import MetadataFormAssociationsTable from 'src/components/metadata/metadataForms/metadataFormAssociations';
import { PageHeader } from 'src/components/subscriptions/common';
import { DeleteMetadataFormRequest } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { convertToDgsHCResourceArn, isHCAdmin } from 'src/commons/common';
import { ResourceSelectorModal } from 'src/components/common/resourceSelector/resourceSelectorModal';
import { generateArnFromId } from 'src/components/utils/arnUtil';

export interface MetadataFormsProps {
  setContentType: any;
  activeGroup: string;
  match: any;
  activeWorkspace?: any;
}

export const MetaDataForms = (props: MetadataFormsProps) => {
  const { state } = useLocation();
  const [redirect, setRedirect] = useState(undefined);
  const [loadingForms, setLoadingForms] = useState(false);
  const [ownerId, setOwnerId] = useState(undefined);
  const [metadataForms, setMetaDataForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [formsTableMessage, setFormsTableMessage] = useState('No forms available');
  const [notifications, setNotifications] = useState([]);
  const [deleteFormModalVisible, setDeleteFormModalVisible] = useState(false);
  const [associateMetadataFormModalVisible, setAssociateMetadataFormModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [entityAssociationsUpdateCounter, setEntityAssociationsUpdateCounter] = useState(0);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(metadataForms, {
    filtering: {
      noMatch: '',
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{formsTableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No forms found.</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const metadataFormColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'metadataFormName',
      header: 'Name',
      cell: (item) => <Link to={`/metadataForms/${item.MetadataFormId}/${item.OwnerId}`}>{item.Name.split()}</Link>,
      minWidth: 200,
      sortingField: 'metadataFormName',
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item) => item.Description,
      minWidth: 200,
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => (
        <StatusIndicator type={item.Status == 'Enabled' ? 'success' : 'stopped'}>{item.Status}</StatusIndicator>
      ),
      minWidth: 200,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => item.FormType.replace('Form#', ''),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    const activeGroup = props.activeGroup;
    const activeWorkspace = props.activeWorkspace;
    const ownerId = activeWorkspace ? activeWorkspace.workspaceId : activeGroup;
    setOwnerId(ownerId);
    if (state) {
      setNotification(state.status, state.message);
    }
  }, []);

  useEffect(() => {
    if (ownerId) {
      handleRefresh();
    }
  }, [ownerId]);

  const handleRefresh = async () => {
    setLoadingForms(true);
    setButtonLoading(true);
    setSelectedForm(undefined);
    await getMetaDataFormsForOwner();
  };

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedForm = selectedItems[0];
    if (selectedForm) {
      setSelectedForm(selectedForm);
    }
  }, [collectionProps.selectedItems]);

  const getMetaDataFormsForOwner = async () => {
    setLoadingForms(true);
    setButtonLoading(true);
    try {
      let listMetadataFormsResult = await listMetadataForms({
        OwnerId: ownerId,
      });
      let forms = listMetadataFormsResult.MetadataFormsList;
      while (listMetadataFormsResult.NextToken != undefined) {
        listMetadataFormsResult = await listMetadataForms({
          OwnerId: ownerId,
          NextToken: listMetadataFormsResult.NextToken,
        });
        forms.push(...listMetadataFormsResult.MetadataFormsList);
      }
      setMetaDataForms(forms);
    } catch (err) {
      setFormsTableMessage(`Unable to load forms. ${err.message}`);
    } finally {
      setLoadingForms(false);
      setButtonLoading(false);
    }
  };

  const setNotification = async (header, message) => {
    if (header === 'error') {
      setNotifications([
        {
          header: header,
          type: 'error',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    } else {
      setNotifications([
        {
          type: 'success',
          content: message,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
    }
  };

  const shouldEnableAdminPermissions = () => {
    if (!selectedForm) return false;
    if (props.activeGroup == selectedForm.OwnerId || props.activeWorkspace?.workspaceId == selectedForm.OwnerId)
      return true;
    if (isHCAdmin(props.activeGroup)) return true;
    return false;
  };

  const getFilterCounterText = (count) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (redirect) return <Redirect push to={redirect} />;

  const metadataFormButtonOptions = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleMetadataFormButtonAction,
        items: [
          {
            text: 'Edit',
            id: 'editMetadataForm',
            disabled: !shouldEnableAdminPermissions(),
          },
          {
            text: 'Delete',
            id: 'deleteMetadataForm',
            disabled: !shouldEnableAdminPermissions(),
          },
          {
            text: 'Associate',
            id: 'associateMetadataForm',
            disabled: !selectedForm,
          },
        ],
      },
      {
        text: 'Create form',
        variant: 'primary',
        onItemClick: () => setRedirect(Page.CREATE_METADATA_FORM),
      },
    ];
  };

  const handleMetadataFormButtonAction = async (e) => {
    if (e.detail.id === 'editMetadataForm') {
      handleEditMetadataForm();
    } else if (e.detail.id === 'deleteMetadataForm') {
      setDeleteFormModalVisible(true);
    } else if (e.detail.id == 'associateMetadataForm') {
      setAssociateMetadataFormModalVisible(true);
    }
  };

  const handleEditMetadataForm = async () => {
    if (selectedForm !== undefined) {
      setRedirect({
        pathname: Page.EDIT_METADATA_FORM,
        state: {
          metadataFormId: selectedForm.MetadataFormId,
        },
      });
    } else {
      setNotification('error', 'Please select a form to edit');
    }
  };

  const handleDeleteMetadataForm = async () => {
    setButtonLoading(true);
    try {
      let deleteMetadataFormResult = await deleteMetadataForm({
        OwnerId: ownerId,
        MetadataFormId: selectedForm.MetadataFormId,
      } as DeleteMetadataFormRequest);
      if (deleteMetadataFormResult.Message == 'Success') {
        await setNotification('success', 'Successfully deleted the form.');
      } else {
        await setNotification('error', `Failed to delete the form. ${deleteMetadataFormResult.Message}`);
      }
    } catch (err) {
      setNotification('error', `Error in deleting the form. ${err.message}`);
    } finally {
      setButtonLoading(false);
      setDeleteFormModalVisible(false);
      await handleRefresh();
    }
  };

  const handleAssociateMetadataForm = async (resourceId: string) => {
    try {
      let resourceArn = generateArnFromId(resourceId);
      let associateMetadataFormToResourceRequest = {
        MetadataFormId: selectedForm?.MetadataFormId,
        OwnerId: selectedForm?.OwnerId,
        ResourceArn: resourceArn,
      };
      const associateMetadataFormToResourceResult = await associateMetadataFormToResource(
        associateMetadataFormToResourceRequest,
      );
      if (associateMetadataFormToResourceResult.Message == 'Success') {
        setNotification('success', 'Success');
      } else {
        setNotification('error', `Failure in associating form: ${associateMetadataFormToResourceResult.Message}`);
      }
    } catch (err) {
      setNotification('error', `Error in associating form: ${err.message}`);
    } finally {
      setAssociateMetadataFormModalVisible(false);
    }
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Modal
        visible={deleteFormModalVisible}
        header={[`Confirm deletion`]}
        onDismiss={() => setDeleteFormModalVisible(false)}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='primary' loading={buttonLoading} onClick={handleDeleteMetadataForm}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div>
          Are you sure you want to delete form <strong>'{selectedForm?.Name}'</strong>?
          <br />
          <br />
          <strong>Please delete all the corresponding fields and associated resources before deleting the form.</strong>
        </div>
      </Modal>
      <ResourceSelectorModal
        activeGroup={props.activeGroup}
        activeWorkspace={props.activeWorkspace}
        visible={associateMetadataFormModalVisible}
        onSelect={handleAssociateMetadataForm}
        close={() => setAssociateMetadataFormModalVisible(false)}
        disableGroupAndWorkspaceSelection={true}
        disableTagSelection={true}
        disableMetadataFormSelection={true}
      />
      <SpaceBetween size='l'>
        <Table
          {...collectionProps}
          selectionType='single'
          loadingText='Loading forms...'
          loading={loadingForms}
          columnDefinitions={metadataFormColumnDefinitions}
          items={items}
          wrapLines={preferences.wrapLines}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No forms</b>
              <Box variant='p' color='inherit'>
                No forms to display. Create one...
              </Box>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel='Filter resources'
              filteringPlaceholder='Find resources'
              countText={getFilterCounterText(filteredItemsCount)}
            />
          }
          resizableColumns={true}
          header={
            <PageHeader
              buttons={metadataFormButtonOptions()}
              header={
                <>
                  Metadata forms
                  <span className='awsui-util-header-counter'>{` (${items.length})`}</span>
                </>
              }
            />
          }
          preferences={
            <CollectionPreferences
              title={'Preferences'}
              confirmLabel={'Confirm'}
              cancelLabel={'Cancel'}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              pageSizePreference={notificationsPageSizePreference}
              wrapLinesPreference={defaultWrapLinesPreference}
            />
          }
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        />
        {selectedForm && (
          <MetadataFormFieldsTable
            form={selectedForm}
            ownerId={ownerId}
            activeGroup={props.activeGroup}
            activeWorkspace={props.activeWorkspace}
            setNotification={setNotification}
          />
        )}
        {selectedForm && (
          <MetadataFormAssociationsTable
            form={selectedForm}
            ownerId={ownerId}
            setNotification={setNotification}
            entityAssociationsUpdateCounter={entityAssociationsUpdateCounter}
          />
        )}
      </SpaceBetween>
    </>
  );
};

export default MetaDataForms;
