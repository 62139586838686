import { useEffect, useState } from 'react';
import { getHybridCatalogDatabases } from '../../../../api/catalog';
import { DatabaseInfo } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';
import { Status } from '../permissionSelector/use-columns';

export const useDatabases = () => {
  const [status, setStatus] = useState<Status>('loading');
  const [databases, setDatabases] = useState<DatabaseInfo[]>([]);

  const getDatabases = async () => {
    let request = {};
    let results: DatabaseInfo[] = [];
    let response = await getHybridCatalogDatabases(request);
    results.push(...response.DatabaseInfoList);

    while (response.NextToken) {
      request = { NextToken: response.NextToken };
      response = await getHybridCatalogDatabases(request);
      results.push(...response.DatabaseInfoList);
    }

    setDatabases(results);
  };

  useEffect(() => {
    getDatabases()
      .then(() => setStatus('finished'))
      .catch(() => setStatus('error'));
  }, []);

  return { status, databases };
};
