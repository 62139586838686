import * as React from 'react';
import { useState, useEffect } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  CollectionPreferences,
  Pagination,
  Table,
  TableProps,
  CollectionPreferencesProps,
  TextFilter,
  Button,
  Flashbar,
  Modal,
  Textarea,
  FlashbarProps,
  Box,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';

import {
  auditDataPermission,
  deactivateDataPermission,
  listDataLakeRoleProperty,
  listDataPermissions,
} from '../../../api/permissions';
import { Link } from 'react-router-dom';
import { PageHeader } from './common';
import { StatusIcon } from './statusIcon';
import { Redirect } from 'react-router-dom';
import BrowseTable from 'src/components/catalog/browsedatasets';
import TrustedIAMPrincipals from 'src/components/permissions/myPermissions/trustedIAMPrincipals';

import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';
import { scrollUp } from 'src/components/utils/navigation';
import { createDatabaseDetailLink } from 'src/routes';
import {
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_STATUS_ACTIVE,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import {
  CopiableText,
  flattenDataPermission,
  generateCsvFileName,
  getCsvDataForDataPermission,
} from 'src/commons/common';
import CsvDownloaderWrapper, { generateCsvColumnDefinitions } from 'src/components/common/csvDownloaderWrapper';

export interface DatasetsTableProps {
  allowListed: boolean;
  groups: any;
  setContentType: any;
  activeGroup: string;
  groupInfo: any;
  username: string;
}

export const DatasetsTable = (props: DatasetsTableProps) => {
  const [allItems, setItems] = useState([]);
  const [notifications, setNotifications] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [redirect, setRedirect] = useState(undefined);
  const [loadingDataSets, setLoadingDataSets] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [databaseName, setDatabaseName] = useState(undefined);
  const [tableName, setTableName] = useState(undefined);
  const [tableMessage, setTableMessage] = useState('No datasets');
  const [reasonOfAction, setReasonOfAction] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [, setDeactivateText] = useState(undefined);
  const [isInvalid, setIsInvalid] = useState(false);
  const [textAreaPlaceholder] = useState(undefined);
  const [, setFailedToGetProperties] = useState(false);
  const [approvedPermissions, setApprovedPermissions] = useState([]);
  const [roleArns, setRoleArns] = useState([]);
  const [loadingRoleProperties, setLoadingRoleProperties] = useState(true);
  const [csvColumnDefinition, setCsvColumnDefinition] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
    visibleContent: ['dataPermissionId', 'databaseName', 'tableName', 'option', 'audit', 'lastAudit'],
  });

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'dataPermissionId',
      header: 'Dataset permission ID',
      cell: (item) => <Link to={`/mydatasets/${item.dataPermissionId}`}>{item.dataPermissionId}</Link>,
      minWidth: 250,
    },
    {
      id: 'databaseName',
      header: 'Database',
      cell: (item) => (
        <Link
          to={createDatabaseDetailLink(
            item?.dataSourceId,
            item?.catalogId,
            item?.clusterId,
            item?.redshiftWorkgroupName,
            item?.databaseName,
            item?.region,
          )}
        >
          {item.databaseName}
        </Link>
      ),
      minWidth: 200,
      sortingField: 'databaseName',
    },
    {
      id: 'tableName',
      header: 'Table',
      cell: (item) => item.tableName,
      minWidth: 200,
      sortingField: 'tableName',
    },
    {
      id: 'option',
      header: 'Option',
      cell: (item) => item.option,
      minWidth: 150,
      sortingField: 'option',
    },
    {
      id: 'audit',
      header: 'Audit status',
      cell: (item) => <StatusIcon status={item.auditStatus} />,
      minWidth: 150,
    },
    {
      id: 'lastAudit',
      header: 'Last audit',
      cell: (item) => item.dateOfLastAudit,
      minWidth: 150,
    },
  ];

  const contentSelectorOptions = [
    {
      label: 'Lake Formation data permission properties',
      options: [
        {
          id: 'dataPermissionId',
          label: 'Dataset permission ID',
          editable: false,
          visible: true,
        },
        {
          id: 'databaseName',
          label: 'Database',
          editable: true,
          visible: true,
        },
        { id: 'tableName', label: 'Table', editable: true, visible: true },
        { id: 'option', label: 'Option', editable: true, visible: true },
        { id: 'audit', label: 'Audit status', editable: true, visible: true },
        {
          id: 'lastAudit',
          label: 'Last audit',
          editable: true,
          visible: true,
        },
      ],
    },
  ];

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
    loadPermissions();
  }, []);

  useEffect(() => {
    handleRefresh();
    loadPermissions();
  }, [props.activeGroup]);

  const refForCSVDownloader: React.RefObject<any> = React.createRef();
  const onClickDownload = () => {
    refForCSVDownloader.current.handleClick();
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
    setLoadingDataSets(true);

    try {
      let listDataPermissionsRequest = {
        ownerId: props.activeGroup,
        status: DATA_PERMISSION_STATUS_ACTIVE,
        nextToken: null,
      };
      let listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
      let dataPermissions = [
        ...listDataPermissionsResult.dataPermissionList.filter((dp) =>
          DATA_PERMISSION_LAKE_FORMATION_TYPE.includes(dp.type),
        ),
      ];
      //Loop and get remaining tables
      while (listDataPermissionsResult.nextToken != null) {
        listDataPermissionsRequest.nextToken = listDataPermissionsResult.nextToken;
        listDataPermissionsResult = await listDataPermissions(listDataPermissionsRequest);
        dataPermissions.push(
          ...listDataPermissionsResult.dataPermissionList.filter((dp) =>
            DATA_PERMISSION_LAKE_FORMATION_TYPE.includes(dp.type),
          ),
        );
      }

      let permissionList = flatten(dataPermissions);
      setItems(permissionList);
      if (permissionList) {
        let csvData = permissionList.map((item) => getCsvDataForDataPermission(item));
        setCsvData(csvData);
        setCsvColumnDefinition(generateCsvColumnDefinitions(csvData));
      }
      setLoadingDataSets(false);
    } catch (err) {
      setTableMessage(`Unable to load datasets: ${err.message}`);
      setLoadingDataSets(false);
    }
  };

  const handleDeactivate = async () => {
    if (!reasonOfAction) {
      setIsInvalid(true);
      return;
    }

    setButtonLoading(true);
    try {
      await deactivateDataPermission({
        dataPermissionId: selected,
        reasonOfAction: reasonOfAction.replace(/\n/g, ''),
      });
      successMessage(selected, 'deactivated');
      setSelected(undefined);
      setDatabaseName(undefined);
      setTableName(undefined);
    } catch (err) {
      errorMessage(err.message, 'deactivating');
    }
    closeModal();
    await handleRefresh();
  };

  const handleAction = async (e) => {
    if (e.detail.id === 'details') {
      setRedirect(`/mydatasets/${selected}`);
    }
    if (e.detail.id === 'audit') {
      setActionLoading(true);
      try {
        const response = await auditDataPermission({
          dataPermissionId: selected,
        });
        scrollUp();
        response.auditStatus === 'SUCCEEDED'
          ? successMessage(`Audit of: ${response.dataPermissionId}`, response.auditStatus)
          : errorMessage(`Audit of: ${response.dataPermissionId}`, response.auditStatus);

        await handleRefresh();
      } catch (err) {
        errorMessage(`Audit of: ${selected} : ${err.message}`, 'FAILED');
      }
      setActionLoading(false);
    }
    if (e.detail.id === 'deactivate') {
      openModal();
    }
  };

  const openModal = () => {
    setDeactivateText(`Dataset permission ID: ${selected}`);
    setModalVisible(true);
  };

  const closeModal = () => {
    setButtonLoading(false);
    setModalVisible(false);
  };

  const successMessage = (message, action) => {
    setNotifications([
      {
        type: 'success',
        content: `${message} was successfully '${action}'`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const errorMessage = (message, action) => {
    setNotifications([
      {
        type: 'error',
        content: `There was an error '${action}' while '${message}'`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const loadPermissions = async () => {
    setLoadingRoleProperties(true);
    let roleProperties;
    try {
      roleProperties = await listDataLakeRoleProperty({
        groupId: props.activeGroup,
      });
    } catch (err) {
      setFailedToGetProperties(true);
      setNotifications([
        {
          type: 'error',
          content: `Failed to get properties for group: ${props.activeGroup}`,
          dismissible: true,
          onDismiss: () => setNotifications([]),
        },
      ]);
      return;
    }

    setFailedToGetProperties(false);
    setApprovedPermissions(roleProperties.approvedPermissions);
    setRoleArns(roleProperties.roleArns);
    setLoadingRoleProperties(false);
  };

  const flatten = (items) => {
    return items.map((item) => flattenDataPermission(item));
  };

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(allItems, {
    filtering: {},
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  useEffect(() => {
    const { selectedItems } = collectionProps;
    if (!selectedItems.length) return;
    const [selected] = selectedItems;
    setSelected(selected.dataPermissionId);
    setDatabaseName(selected.databaseName);
    setTableName(selected.tableName);
  }, [collectionProps.selectedItems]);

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  let datasetsIHaveAccessTo;
  if (roleArns.length == 1) {
    datasetsIHaveAccessTo = (
      <div>
        <CopiableText name='Data Lake role ARN' value={roleArns[0]} loading={loadingRoleProperties} />
        <br />
        <BrowseTable
          {...props}
          setContentType={(_) => {}}
          title='Datasets'
          idFilter={approvedPermissions}
          extraFeatures={false}
          iamDatasetPermission={true}
          multiSelection={true}
          loading={loadingRoleProperties}
          roleArns={null}
        />
        <br />
      </div>
    );
  } else {
    datasetsIHaveAccessTo = (
      <div>
        <BrowseTable
          {...props}
          setContentType={(_) => {}}
          title='Datasets'
          idFilter={approvedPermissions}
          extraFeatures={false}
          iamDatasetPermission={true}
          multiSelection={true}
          loading={loadingRoleProperties}
          roleArns={roleArns}
        />
        <br />
      </div>
    );
  }

  return (
    <div>
      <Flashbar items={notifications} />
      <h1>Dataset access</h1>
      <PageHeader buttons={[]} header={'Lake Formation datasets'} />
      <Modal
        visible={modalVisible}
        header={[
          `Enter reason for deactivating`,
          <br />,
          `Dataset permission ID: ${selected}`,
          <br />,
          `Database: ${databaseName}`,
          <br />,
          `Table: ${tableName}`,
        ]}
        onDismiss={closeModal}
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={closeModal}>
                No
              </Button>
              <Button variant='primary' loading={buttonLoading} onClick={handleDeactivate}>
                Yes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <Textarea
          value={reasonOfAction}
          onChange={({ detail }) => {
            setIsInvalid(false);
            setReasonOfAction(detail.value);
          }}
          placeholder={textAreaPlaceholder}
          invalid={isInvalid}
        />
        Are you sure you want to deactivate this Dataset permission?
      </Modal>

      <Table
        {...collectionProps}
        loadingText='Loading dataset permissions...'
        loading={loadingDataSets}
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={preferences.wrapLines}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        selectionType='single'
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No dataset permissions to display.</p>
          </div>
        }
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: '',
                  icon: 'refresh',
                  onItemClick: handleRefresh,
                },
                {
                  text: 'Actions',
                  onItemClick: handleAction,
                  items: [
                    {
                      text: 'View details',
                      id: 'details',
                      disabled: selected === undefined,
                    },
                    {
                      text: 'Audit',
                      id: 'audit',
                      disabled: selected === undefined,
                    },
                    {
                      text: 'Deactivate',
                      id: 'deactivate',
                      disabled: selected === undefined,
                    },
                  ],
                  loading: actionLoading,
                },
              ]}
              header={
                <>
                  Dataset permissions
                  <span className='awsui-util-header-counter'>{` (${allItems.length})`}</span>
                </>
              }
              additionalItems={
                !loadingDataSets &&
                allItems.length > 0 && (
                  <>
                    <Button variant='primary' onClick={onClickDownload} iconName='download'>
                      Download as CSV
                    </Button>
                    <CsvDownloaderWrapper
                      rowItems={csvData}
                      fileName={generateCsvFileName('permissions', props.activeGroup)}
                      refForCsvDownloader={refForCSVDownloader}
                      userFriendlyDataDefinitions={csvColumnDefinition}
                    />
                  </>
                )
              }
            />
          </>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 10, label: '10 items' },
                { value: 30, label: '30 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: contentSelectorOptions,
            }}
          />
        }
      />

      <br />
      <PageHeader buttons={[]} header={'IAM datasets'} />
      {datasetsIHaveAccessTo}
      <TrustedIAMPrincipals {...props} />
    </div>
  );
};
