import * as React from 'react';
import {
  AttributeEditor,
  Button,
  Cards,
  ColumnLayout,
  Flashbar,
  Form,
  FormField,
  Container,
  Input,
  Select,
  Toggle,
  SelectProps,
  FlashbarProps,
  Header,
  StatusIndicator,
} from '@amzn/awsui-components-react-v3';
import {
  listCatalogs,
  listDatabases,
  listDataSources,
  registerCatalog,
  registerDatabase,
  registerDataSets,
  syncDataSets,
} from '../../../src/api/catalog';
import { Redirect } from 'react-router-dom';
import { getUser } from '../../api/auth';
import { PublishDataSetGlueLF } from './share/publishDataSetGlueLF';
import {
  dataClassificationOptions,
  FORM_CONTENT_TYPE,
  IAM_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  refreshCadenceOptions,
  RESOURCE_LINK_PREFIX,
} from 'src/commons/constants';
import { scrollUp } from 'src/components/utils/navigation';
import { enableIamBasedAccess, getRegion, isIso } from 'src/api/config';
import { isDataLakeAdmin } from 'src/commons/common';

export interface PublishDataSetComponentProps {
  setContentType: any;
  activeGroup: string;
}

export interface PublishDataSetComponentState {
  dataSourceOptions: SelectProps.Option[];
  dataSourcesLoading: boolean;
  createdBy: string;
  dataSourceId: any;
  dataShareType: string;
  glueDataSourceId: object;
  catalogId: string;
  region: string;
  databasesInCatalog: object[];
  catalogName: string;
  catalogDescription: string;
  cti: string;
  catalogOnboarded: boolean;
  dataSetList: object[];
  dataSetCards: object[];
  catalogChecked: boolean;
  currentDataSetName: string;
  currentDatabase: string;
  currentTable: string;
  currentOwners: string;
  currentPrimaryOwner: string;
  currentClassification: object;
  restrictedClassification: object;
  currentPreview: boolean;

  currentWheeljackConfidenceFileLocation: string;
  currentRefreshCadence: object;
  defaultRefreshCadence: object;
  currentPII: boolean;
  currentDatalakeSLA: string;
  currentTableState: object;
  activeTableState: object;
  currentKmsKey: string;
  currentLakeFormationRoleARN: string;

  notifications: FlashbarProps.MessageDefinition[];
  visible: boolean;
  buttonLoading: boolean;
  redirect: string;
  buttonText: string;
}

export default class PublishDataSetComponent extends React.Component<
  PublishDataSetComponentProps,
  PublishDataSetComponentState
> {
  state = {
    dataShareType: undefined,
    dataSourceOptions: [],
    dataSourcesLoading: true,
    createdBy: '',
    dataSourceId: null,
    glueDataSourceId: null,
    catalogId: '',
    region: getRegion(),
    dataSetList: [],
    dataSetCards: [],
    catalogName: '',
    catalogDescription: '',
    cti: '',
    catalogOnboarded: false,
    catalogChecked: false,
    // The status of the current dataset being edited
    currentDataSetName: '',
    databasesInCatalog: [],
    currentDatabase: '',
    currentTable: '',
    currentOwners: undefined,
    currentPrimaryOwner: '',
    currentClassification: null,
    restrictedClassification: null,
    currentPreview: false,
    currentWheeljackConfidenceFileLocation: '',
    currentRefreshCadence: null,
    defaultRefreshCadence: null,
    currentPII: false,
    currentDatalakeSLA: '07:00 (PST)',
    currentTableState: null,
    activeTableState: null,
    currentKmsKey: '',
    currentLakeFormationRoleARN: '',

    notifications: [],
    visible: false,
    buttonLoading: false,
    redirect: undefined,
    buttonText: 'Publish Dataset',
  };

  dataSetAttributes = [
    {
      key: 'dataset',
      value: 'Dataset name as it will appear in the catalog',
    },
    {
      key: 'database',
      value: 'Database name',
    },
    {
      key: 'table',
      value: 'Table name',
    },
    {
      key: 'owners',
      value: 'Table owners (comma-separated Omni Group IDs)',
    },
    {
      key: 'primaryOwner',
      value: 'Table primary owner',
    },
    {
      key: 'classification',
      value: 'Data classification',
    },
    {
      key: 'preview',
      value: 'Allow data preview',
    },
    {
      key: 'wheeljackConfidenceFileLocation',
      value: 'Confidence file S3 location',
    },
    {
      key: 'refreshCadence',
      value: 'Refresh cadence',
    },
    {
      key: 'pii',
      value: 'Is a PII dataset',
    },
    {
      key: 'datalakeSLA',
      value: "SLA (typically '07:00 (PST)')",
    },
    {
      key: 'tableState',
      value: "Table status (typically 'Active')",
    },
    {
      key: 'kmsKey',
      value: 'KMS key (if necessary)',
    },
  ];

  cardDefinition = {
    header: (item) => item.DataSetName,
    sections: [
      {
        id: 'database',
        header: 'Database',
        content: (item) => item.DatabaseName,
      },
      {
        id: 'table',
        header: 'Table',
        content: (item) => item.TableName,
      },
      {
        id: 'owners',
        header: 'Owners',
        content: (item) => item.TableOwners,
      },
      {
        id: 'primaryOwner',
        header: 'PrimaryOwner',
        content: (item) => item.PrimaryOwner,
      },
      {
        id: 'classification',
        header: 'Data classification',
        content: (item) => item.DataClassification,
      },
      {
        id: 'preview',
        header: 'Preview enabled',
        content: (item) => (item.DataPreview ? 'True' : 'False'),
      },
      {
        id: 'wheeljackConfidenceFileLocation',
        header: 'Confidence file s3 location',
        content: (item) => item.WheeljackConfidenceFileLocation,
      },
      {
        id: 'refreshCadence',
        header: 'Refresh cadence',
        content: (item) => item.RefreshCadence,
      },
      {
        id: 'pii',
        header: 'Is a PII dataset',
        content: (item) => (item.PII ? 'True' : 'False'),
      },
      {
        id: 'datalakeSLA',
        header: 'SLA',
        content: (item) => item.DatalakeSLA,
      },
      {
        id: 'tableState',
        header: 'Table status',
        content: (item) => item.TableState,
      },
      {
        id: 'kmsKey',
        header: 'KMS key',
        content: (item) => item.KmsKey,
      },
    ],
  };

  tableStateOptions = [
    {
      label: 'Active',
      id: '1',
    },
    {
      label: 'Pending Deprecation',
      id: '2',
    },
    {
      label: 'Deprecated',
      id: '3',
    },
  ];

  dataSourceNames = {
    glueLF: 'Lake Formation',
    glue: 'Glue',
  };

  setDefaultAttributes() {
    this.setState({
      restrictedClassification: dataClassificationOptions.find(function (currentVal) {
        return currentVal.label == 'Restricted';
      }),
      defaultRefreshCadence: refreshCadenceOptions.find(function (currentVal) {
        return currentVal.label == 'Never';
      }),
      activeTableState: this.tableStateOptions.find(function (currentVal) {
        return currentVal.label == 'Active';
      }),
      currentOwners: this.props.activeGroup,
      currentPrimaryOwner: this.props.activeGroup,
      currentDatalakeSLA: '07:00 (PST)',
      region: getRegion(),
    });

    this.setState({
      glueDataSourceId: this.state.dataSourceOptions.find(function (currentVal) {
        return currentVal.value == LAKE_FORMATION_DATASOURCE_ID;
      }),
    });
  }

  componentDidMount = async () => {
    this.setDefaultAttributes();

    this.props.setContentType(FORM_CONTENT_TYPE);

    const options = await listDataSources();
    let formattedOptions = options['DataSourceList']
      .filter((source) => source.includes(IAM_DATASOURCE_ID) && !source.includes(RESOURCE_LINK_PREFIX))
      .map((source, i) => {
        return {
          id: i.toString(),
          value: source,
          label: this.dataSourceNames[source],
        };
      });
    if (isIso()) {
      formattedOptions = options['DataSourceList']
        .filter((source) => source == IAM_DATASOURCE_ID && !source.includes(RESOURCE_LINK_PREFIX))
        .map((source, i) => {
          return {
            id: i.toString(),
            value: source,
            label: this.dataSourceNames[source],
          };
        });
    }
    this.setState({
      dataSourceOptions: formattedOptions,
      dataSourcesLoading: false,
      glueDataSourceId: formattedOptions.find(function (currentVal) {
        return currentVal.value == LAKE_FORMATION_DATASOURCE_ID; // Note: LF is default
      }),
      dataSourceId: formattedOptions.find(function (currentVal) {
        return currentVal.value == LAKE_FORMATION_DATASOURCE_ID; // Note: LF is default
      }),
    });

    this.addSingleDataSet = this.addSingleDataSet.bind(this);
    this.updateButtonText = this.updateButtonText.bind(this);
  };

  publishDataSets = async () => {
    const dataSourceIdValue =
      this.state.dataSourceId == null ? this.state.glueDataSourceId.value : this.state.dataSourceId.value;
    console.log(dataSourceIdValue);
    const registerRequest = {
      CreatedBy: await getUser(),
      DataSourceId: dataSourceIdValue,
      CatalogId: this.state.catalogId,
      Region: this.state.region,
      DataSetList: this.state.dataSetList,
    };

    // if register step fails, do not move on to sync step
    let registerResponse;
    try {
      registerResponse = await registerDataSets(registerRequest);
      console.log('Register returned response:');
      console.log(registerResponse);
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to register datasets.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }

    // now sync datasets' metadata with glue (or other data sources in the future)
    const idList = registerResponse.DataSetList.map((e) => e.Id);
    const syncRequest = {
      IdList: idList,
    };

    try {
      await syncDataSets(syncRequest);
    } catch (err) {
      this.setState({
        notifications: [
          {
            type: 'error' as FlashbarProps.Type,
            content: `Failed to sync datasets with source.`,
            dismissible: true,
            onDismiss: () => this.setState({ notifications: [] }),
          },
        ],
      });
      return;
    }

    this.setState({
      notifications: [
        {
          type: 'success' as FlashbarProps.Type,
          content: `Successfully registered and synced.`,
          dismissible: true,
          onDismiss: () => this.setState({ notifications: [] }),
        },
      ],
    });
  };

  onboardCatalog = async () => {
    let registerCatalogRequest = {
      CatalogId: this.state.catalogId,
      Region: getRegion(),
      Name: this.state.catalogName,
      Owner: this.props.activeGroup,
      CTI: this.state.cti,
      Description: this.state.catalogDescription,
      DataSourceId: this.state.dataSourceId,
    };
    try {
      await registerCatalog(registerCatalogRequest);
    } catch (err) {
      console.log(err);
    }
  };

  addSingleDataSet() {
    // Need to split and trim the owners array for POST request
    const ownersArr = this.state.currentOwners.split(',').map((item) => item.trim());

    const classification =
      this.state.currentClassification == null
        ? this.state.restrictedClassification.label
        : this.state.currentClassification.label;
    const refreshCadence =
      this.state.currentRefreshCadence == null
        ? this.state.defaultRefreshCadence.label
        : this.state.currentRefreshCadence.label;
    const tableState =
      this.state.currentTableState == null ? this.state.activeTableState.label : this.state.currentTableState.label;

    const dataSet = {
      DataSetName: this.state.currentDataSetName,
      DatabaseName: this.state.currentDatabase,
      TableName: this.state.currentTable,
      TableOwners: ownersArr,
      PrimaryOwner: this.state.currentPrimaryOwner,
      DataClassification: classification,
      DataPreview: this.state.currentPreview,
      DatalakeSLA: this.state.currentDatalakeSLA,
      TableState: tableState,
      RefreshCadence: refreshCadence,
      PII: this.state.currentPII,
      KmsKey: this.state.currentKmsKey,
      WheeljackConfidenceFileLocation: this.state.currentWheeljackConfidenceFileLocation,
    };
    const cardDataSet = {
      DataSetName: this.state.currentDataSetName,
      DatabaseName: this.state.currentDatabase,
      TableName: this.state.currentTable,
      TableOwners: this.state.currentOwners,
      PrimaryOwner: this.state.currentPrimaryOwner,
      DataClassification: classification,
      DataPreview: this.state.currentPreview,
      DatalakeSLA: this.state.currentDatalakeSLA,
      TableState: tableState,
      RefreshCadence: refreshCadence,
      PII: this.state.currentPII,
      KmsKey: this.state.currentKmsKey,
      WheeljackConfidenceFileLocation: this.state.currentWheeljackConfidenceFileLocation,
    };
    this.setState({
      dataSetList: [...this.state.dataSetList, dataSet],
      dataSetCards: [...this.state.dataSetCards, cardDataSet],
      currentDataSetName: '', // reset the text input fields
      currentTable: '',
      currentOwners: '',
      currentPrimaryOwner: '',
      currentLakeFormationRoleARN: '',
      currentWheeljackConfidenceFileLocation: '',
      currentKmsKey: '',
      currentDatalakeSLA: '',
    });
    this.updateButtonText();
    this.setDefaultAttributes();
  }

  fetchDatabases = async (catalogId) => {
    try {
      let request = { NextToken: null };
      let databases = await listDatabases(request);
      let databaseInfoList = databases.DatabaseInfoList;
      while (databases.NextToken != null) {
        request.NextToken = databases.NextToken;
        databases = await listDatabases(request);
        databaseInfoList.push(...databases.DatabaseInfoList);
      }
      let databasesHCList = [];
      for (const database of databaseInfoList) {
        if (database.CatalogId == catalogId && database.Region == getRegion()) {
          databasesHCList.push({
            CatalogId: database.CatalogId,
            DatabaseName: database.DatabaseName,
            Description: database.Description,
            Owners: database.Owners,
          });
        }
      }
      this.setState({
        databasesInCatalog: databasesHCList,
      });
    } catch (err) {
      console.log(err);
    }
  };
  fetchCatalogs = async (catalogId) => {
    if (!this.props.activeGroup) return;
    let request = {
      Filter: {
        CatalogKeyList: [
          {
            CatalogId: catalogId,
            Region: getRegion(),
          },
        ],
      },
    };
    try {
      const catalogs = await listCatalogs(request);
      const catalogList = catalogs.CatalogInfoList;
      //There should be one catalog per account currently.
      if (catalogList.length > 0) {
        this.setState({
          catalogName: catalogList[0].Name,
          catalogDescription: catalogList[0].Description,
          cti: catalogList[0].CTI,
          catalogOnboarded: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleConfirm = async () => {
    this.setState({ buttonLoading: true });

    const databases = this.state.databasesInCatalog.filter((item) => item.DatabaseName === this.state.currentDatabase);
    if (databases.length == 0) {
      let registerDatabaseRequest = {
        CreatedBy: this.props.activeGroup,
        Region: getRegion(),
        DatabaseName: this.state.currentDatabase,
        CatalogId: this.state.catalogId,
        Description: 'IAM database',
        Owners: [this.props.activeGroup],
        AutoTableOnboard: false,
        DataSourceId: this.state.dataSourceId,
      };
      try {
        await registerDatabase(registerDatabaseRequest);
      } catch (err) {
        console.log(err);
      }
    }
    // check inputs and etc
    await this.publishDataSets();

    this.setState({ buttonLoading: false });
  };

  getNumberOfDataSets() {
    return this.state.dataSetList.length;
  }

  disableAddButton() {
    return (
      this.state.currentDataSetName.length === 0 ||
      this.state.currentDatabase.length === 0 ||
      this.state.currentTable.length === 0 ||
      this.state.currentOwners.length === 0 ||
      this.state.currentPrimaryOwner.length == 0
    );
  }

  updateButtonText() {
    if (this.getNumberOfDataSets() > 1) {
      this.setState({ buttonText: 'Publish datasets' });
    } else {
      this.setState({ buttonText: 'Publish dataset' });
    }
  }

  fetchCatalogAndDbs = async () => {
    //Get Hybrid Catalog information for Catalog Id (from Arn)

    await this.fetchCatalogs(this.state.catalogId);
    await this.fetchDatabases(this.state.catalogId);
    this.setState({
      catalogChecked: true,
    });
  };

  setNotifications = (value) => {
    scrollUp();
    this.setState({ notifications: value });
  };

  render() {
    // temporary hard-coding to hide this page from everyone except admins
    // and pentest team, remove these when we can validate the groups in the backend
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }
    return (
      <div>
        <Flashbar items={this.state.notifications} />
        <div className='awsui-util-mb-m awsui-util-mt-xs'>
          <div className='awsui-util-action-stripe'>
            <div className='awsui-util-action-stripe-title'>
              <h2>
                <span>
                  <h1 className='awsui-util-container-header'>Publish data</h1>
                </span>
              </h2>
              <div className='awsui-util-label'>You can add your data to the catalog from the desired data source.</div>
            </div>
          </div>
        </div>

        <Container className='custom-screenshot-hide' header={<Header variant='h2'>Data source settings</Header>}>
          <ColumnLayout>
            <div data-awsui-column-layout-root={true}>
              <FormField label={<div>Data source</div>} description='The type of the data source to be used.'>
                <Select
                  selectedOption={
                    this.state.dataSourceId != null ? this.state.dataSourceId : this.state.glueDataSourceId
                  }
                  options={this.state.dataSourceOptions}
                  selectedAriaLabel='Selected'
                  onChange={(e) => {
                    this.setState({
                      dataSourceId: e.detail.selectedOption,
                    });
                  }}
                />
              </FormField>
            </div>
          </ColumnLayout>
        </Container>
        <br />

        {this.state.dataSourceId?.value === LAKE_FORMATION_DATASOURCE_ID && (
          <PublishDataSetGlueLF
            {...this.props}
            onNotificationChange={this.setNotifications}
            dataSourceId={this.state.dataSourceId?.value}
          />
        )}

        {this.state.dataSourceId?.value === 'glue' &&
          !enableIamBasedAccess() &&
          !isDataLakeAdmin(this.props.activeGroup) && (
            <StatusIndicator type='error'>
              Dataset onboarding not supported with IAM permission model. Choose LakeFormation.
            </StatusIndicator>
          )}

        {this.state.dataSourceId?.value === 'glue' &&
          (enableIamBasedAccess() || isDataLakeAdmin(this.props.activeGroup)) && (
            <Form
              actions={
                <div>
                  <Button
                    variant='link'
                    onClick={() => {
                      this.setState({
                        dataSetList: [],
                        dataSetCards: [],
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    onClick={this.handleConfirm}
                    loading={this.state.buttonLoading}
                    disabled={this.getNumberOfDataSets() == 0}
                  >
                    {this.state.buttonText}
                  </Button>
                </div>
              }
            >
              <Container className='custom-screenshot-hide' header={<h2>Catalog details</h2>}>
                <ColumnLayout>
                  <FormField label={<div>Catalog ID</div>} description='AWS Account ID of the catalog to submit)'>
                    <Input
                      name='catalog-id'
                      ariaRequired={true}
                      onChange={(e) => this.setState({ catalogId: e.detail.value })}
                      value={this.state.catalogId}
                    />
                  </FormField>
                  <FormField label={<div>Region</div>} description='The region where the data source resides.'>
                    <Input name='region' value={getRegion()} ariaRequired={true} disabled={true} />
                  </FormField>
                  <Button variant='primary' onClick={this.fetchCatalogAndDbs}>
                    Get catalog info
                  </Button>
                </ColumnLayout>
              </Container>

              <br />
              {!this.state.catalogOnboarded && this.state.catalogChecked && (
                <Container className='custom-screenshot-hide' header={<h2>Catalog details</h2>}>
                  <ColumnLayout>
                    <FormField label={<div>Catalog ID</div>} description='Catalog ID for your databases'>
                      <Input name='catalogId' ariaRequired={true} value={this.state.catalogId} disabled={true} />
                    </FormField>
                    <FormField label={<div>Catalog name</div>} description='Name of the catalog'>
                      <Input
                        name='catalogName'
                        placeholder='Enter catalog name here.'
                        ariaRequired={true}
                        value={this.state.catalogName}
                        disabled={this.state.catalogOnboarded}
                        onChange={(e) => this.setState({ catalogName: e.detail.value })}
                      />
                    </FormField>
                    <FormField label={<div>Catalog description</div>} description='A brief description of the catalog.'>
                      <Input
                        name='catalogDescription'
                        placeholder={this.state.catalogOnboarded ? '' : 'Enter description here.'}
                        ariaRequired={true}
                        value={this.state.catalogDescription}
                        disabled={this.state.catalogOnboarded}
                        onChange={(e) => this.setState({ catalogDescription: e.detail.value })}
                      />
                    </FormField>
                    <FormField label={<div>CTI</div>} description='CTI for your team.'>
                      <Input
                        name='cti'
                        placeholder={this.state.catalogOnboarded ? '' : 'Enter CTI here. Format: Category/Type/Item'}
                        ariaRequired={true}
                        value={this.state.cti}
                        disabled={this.state.catalogOnboarded}
                        onChange={(e) => this.setState({ cti: e.detail.value })}
                      />
                    </FormField>
                    <Button variant='primary' onClick={this.onboardCatalog} disabled={this.state.catalogOnboarded}>
                      Onboard catalog
                    </Button>
                  </ColumnLayout>
                </Container>
              )}
              <br />
              {this.state.catalogOnboarded && (
                <Container className='custom-screenshot-hide' header={<h2>Add datasets</h2>}>
                  <ColumnLayout>
                    <FormField>
                      <Cards
                        cardDefinition={this.cardDefinition}
                        items={this.state.dataSetCards}
                        cardsPerRow={[
                          {
                            cards: 1,
                          },
                          {
                            minWidth: 500,
                            cards: 2,
                          },
                        ]}
                        empty={
                          <div className='awsui-util-t-c'>
                            <div className='awsui-util-pt-xs awsui-util-mb-xs'>
                              <b>No datasets yet</b>
                            </div>
                            <p className='awsui-util-mb-s'>You can add a dataset below.</p>
                          </div>
                        }
                      />
                    </FormField>
                    <AttributeEditor
                      items={this.dataSetAttributes}
                      definition={[
                        // Definition of the left column, containing the descriptions of the input fields
                        {
                          label: '',
                          control: (item) => <p>{item.value}</p>,
                        },
                        // Definition of the right column, containing text fields, dropdown menus, and toggles
                        {
                          label: '',
                          control: (item) => {
                            if (item.key == 'preview') {
                              return (
                                <Toggle
                                  id={'toggle:' + item.key}
                                  onChange={(e) =>
                                    this.setState({
                                      currentPreview: e.detail.checked,
                                    })
                                  }
                                  checked={this.state.currentPreview}
                                />
                              );
                            } else if (item.key == 'classification') {
                              return (
                                <Select
                                  id={'select:' + item.key}
                                  selectedOption={
                                    this.state.currentClassification == null
                                      ? this.state.restrictedClassification
                                      : this.state.currentClassification
                                  }
                                  options={dataClassificationOptions}
                                  selectedAriaLabel='Selected'
                                  onChange={(e) =>
                                    this.setState({
                                      currentClassification: e.detail.selectedOption,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'dataset') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentDataSetName}
                                  onChange={(e) =>
                                    this.setState({
                                      currentDataSetName: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'database') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentDatabase}
                                  onChange={(e) =>
                                    this.setState({
                                      currentDatabase: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'owners') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder={'Enter value'}
                                  value={this.state.currentOwners}
                                  onChange={(e) =>
                                    this.setState({
                                      currentOwners: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'primaryOwner') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder={'Enter value'}
                                  value={this.state.currentPrimaryOwner}
                                  onChange={(e) =>
                                    this.setState({
                                      currentPrimaryOwner: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'table') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentTable}
                                  onChange={(e) =>
                                    this.setState({
                                      currentTable: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'wheeljackConfidenceFileLocation') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentWheeljackConfidenceFileLocation}
                                  onChange={(e) => {
                                    this.setState({
                                      currentWheeljackConfidenceFileLocation: e.detail.value,
                                    });
                                  }}
                                />
                              );
                            } else if (item.key == 'refreshCadence') {
                              return (
                                <Select
                                  id={'select:' + item.key}
                                  options={refreshCadenceOptions}
                                  selectedAriaLabel='Selected'
                                  selectedOption={
                                    this.state.currentRefreshCadence == null
                                      ? this.state.defaultRefreshCadence
                                      : this.state.currentRefreshCadence
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      currentRefreshCadence: e.detail.selectedOption,
                                    });
                                  }}
                                />
                              );
                            } else if (item.key == 'pii') {
                              return (
                                <Toggle
                                  id={'toggle:' + item.key}
                                  onChange={(e) => {
                                    this.setState({
                                      currentPII: e.detail.checked,
                                    });
                                  }}
                                  checked={this.state.currentPII}
                                />
                              );
                            } else if (item.key == 'datalakeSLA') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentDatalakeSLA}
                                  onChange={(e) =>
                                    this.setState({
                                      currentDatalakeSLA: e.detail.value,
                                    })
                                  }
                                />
                              );
                            } else if (item.key == 'tableState') {
                              return (
                                <Select
                                  id={'select:' + item.key}
                                  options={this.tableStateOptions}
                                  selectedOption={
                                    this.state.currentTableState == null
                                      ? this.state.activeTableState
                                      : this.state.currentTableState
                                  }
                                  selectedAriaLabel='Selected'
                                  onChange={(e) => {
                                    this.setState({
                                      currentTableState: e.detail.selectedOption,
                                    });
                                  }}
                                />
                              );
                            } else if (item.key == 'kmsKey') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentKmsKey}
                                  onChange={(e) => {
                                    this.setState({
                                      currentKmsKey: e.detail.value,
                                    });
                                  }}
                                />
                              );
                            } else if (item.key == 'lakeFormationRoleArn') {
                              return (
                                <Input
                                  id={'input:' + item.key}
                                  placeholder='Enter value'
                                  value={this.state.currentLakeFormationRoleARN}
                                  onChange={(e) => {
                                    this.setState({
                                      currentLakeFormationRoleARN: e.detail.value,
                                    });
                                  }}
                                />
                              );
                            }
                          },
                        },
                      ]}
                      addButtonText='Add dataset'
                      disableAddButton={this.disableAddButton()}
                      onAddButtonClick={this.addSingleDataSet}
                      isItemRemovable={() => false}
                      removeButtonText={'Remove'}
                    />
                  </ColumnLayout>
                </Container>
              )}
            </Form>
          )}
      </div>
    );
  }
}
