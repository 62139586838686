import React, { useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Input,
  Select,
  SpaceBetween,
  Spinner,
  Table,
} from '@amzn/awsui-components-react-v3';
import { ResourceType, resourceTypeOptionsForSearch } from 'src/components/common/resourceSelector/common';
import {
  flatten,
  getDataSources,
  getMinimalSearchTableColumnDefinitions,
  SearchEntityType,
} from 'src/components/search/constants';
import { search } from 'src/api/permissions';

export interface ResourceSearcherProps {
  setNotification: (header: any, message: string) => void;
  activeGroup?: string;
  activeWorkspace?: any;
  handleRefresh?: () => void;
  onSelect: (resourceId: string) => void;
  disableGroupAndWorkspaceSelection?: boolean;
  disableTagSelection?: boolean;
  workspaceNameMap?: any;
  catalogMap?: any;
}

export const ResourceSearcher = (props: ResourceSearcherProps) => {
  const [resourceType, setResourceType] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [previousQuery, setPreviousQuery] = useState('');
  const [searchResultItems, setSearchResultItems] = useState([]);
  const [selectedSearchResultItem, setSelectedSearchResultItem] = useState(undefined);
  const [userHasSearchedAtLeastOnce, setUserHasSearchedAtLeastOnce] = useState(false);
  const [loading, setLoading] = useState(false);

  const isWorkspace = !!props.activeWorkspace;
  const resourceTypes = resourceTypeOptionsForSearch(isWorkspace);

  const mapResourceTypeToSearchFilterType = {
    [ResourceType.CATALOG]: SearchEntityType.Catalog,
    [ResourceType.DATABASE]: SearchEntityType.Database,
    [ResourceType.SCHEMA]: SearchEntityType.Schema,
    [ResourceType.TABLE]: SearchEntityType.Dataset,
  };
  const selectedSearchFilterType = resourceType
    ? mapResourceTypeToSearchFilterType[resourceType.value]
    : SearchEntityType.Dataset;
  const columnDefinitions = getMinimalSearchTableColumnDefinitions(
    isWorkspace,
    selectedSearchFilterType,
    props.workspaceNameMap,
  );

  const onSearch = async () => {
    setLoading(true);
    const result = await search({
      entityFilterList: [selectedSearchFilterType],
      searchQuerySimple: {
        queryString: searchQuery,
      },
      maxResults: 20,
      nextToken: undefined,
    });
    setSearchResultItems(
      flatten(
        result.resultList[0].schemaEntity,
        getDataSources(isWorkspace),
        selectedSearchFilterType,
        props.catalogMap,
      ),
    );
    setLoading(false);
    setUserHasSearchedAtLeastOnce(true);
    setPreviousQuery(searchQuery);
  };

  const searchItemToResourceId = (item) => {
    let resourceId;
    if (selectedSearchFilterType == SearchEntityType.Dataset) {
      resourceId = item.Id;
    } else if (selectedSearchFilterType == SearchEntityType.Database) {
      const dataSource = item.type == 'Lake Formation' ? 'glueLF' : 'redshift';
      if (dataSource == 'glueLF') {
        resourceId = `DS-${dataSource}|A-${item.catalogId}|DN-${item.databaseName}|R-${item.region}`;
      } else {
        resourceId = `DS-${dataSource}|A-${item.catalogId}|CI-${item.clusterIdentifier}|DN-${item.databaseName}|R-${item.region}`;
      }
    } else if (selectedSearchFilterType == SearchEntityType.Catalog) {
      const dataSource = item.type == 'Lake Formation' ? 'glueLF' : 'redshift';
      if (dataSource == 'glueLF') {
        resourceId = `DS-${dataSource}|A-${item.catalogId}|R-${item.region}`;
      } else {
        resourceId = `DS-${dataSource}|A-${item.catalogId}|CI-${item.clusterIdentifier}|R-${item.region}`;
      }
    } else {
      resourceId = undefined;
    }
    props.onSelect(resourceId);
  };

  const selectSearchResultItem = (item) => {
    setSelectedSearchResultItem(item);
    searchItemToResourceId(item);
  };

  const onChangeSelectedResourceType = (newResourceType) => {
    setResourceType(newResourceType);
    setSearchResultItems([]);
    setSelectedSearchResultItem(undefined);
    setUserHasSearchedAtLeastOnce(false);
    props.onSelect(undefined);
  };

  return (
    <SpaceBetween direction={'vertical'} size={'s'}>
      <Form>
        <FormField label={'Resource type'} description={'Select the type of resource to search for.'}>
          <Select
            selectedOption={resourceType}
            onChange={(e) => {
              onChangeSelectedResourceType(e.detail.selectedOption);
            }}
            options={resourceTypes}
          />
        </FormField>
        <FormField label={'Search query'}>
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.detail.value)}
            placeholder={'Enter a search query here...'}
          />
        </FormField>
      </Form>
      <Button onClick={onSearch} disabled={searchQuery.length == 0 || !resourceType}>
        Search
      </Button>
      {loading && <Spinner />}
      {resourceType && userHasSearchedAtLeastOnce && !loading && (
        <Table
          items={searchResultItems}
          columnDefinitions={columnDefinitions}
          selectionType={'single'}
          selectedItems={[selectedSearchResultItem]}
          onSelectionChange={(e) => selectSearchResultItem(e.detail.selectedItems[0])}
          empty={
            <Box textAlign='center' color='inherit'>
              <b>No results for '{previousQuery}'</b>
              <Box variant='p' color='inherit'>
                Your search yielded no results. Try a different query.
              </Box>
            </Box>
          }
        />
      )}
    </SpaceBetween>
  );
};
