import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  CollectionPreferencesProps,
  Pagination,
  TextFilter,
  TableProps,
  Table,
  CollectionPreferences,
  Box,
  SpaceBetween,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
} from '@amzn/awsui-components-react-v3';
import { useCollection } from '@amzn/awsui-collection-hooks';

import { defaultWrapLinesPreference, paginationLabels } from 'src/commons/tables';

import { PageHeader } from '../subscriptions/common';
import * as validate from 'src/commons/validationUtils';
import { isGlobalTemplate } from 'src/components/utils/template';

export interface TemplateResourcesTableProps {
  templateResources: any;
  tableMessage: string;
  notification;
  handleDissociateResource;
}

const TemplateResourcesTable = (props: TemplateResourcesTableProps) => {
  const { templateResources, tableMessage, handleDissociateResource } = props;

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 10,
  });

  const [dissociateModalVisible, setDissociateModalVisible] = useState(false);
  const [selectedResource, setSelectedResource] = useState<any>('');
  const [buttonLoading] = useState(false);
  const [selectedResourceTemplateId, setSelectedResourceTemplateId] = useState('');
  const [selectedTemplateResourceId, setSelectedTemplateResourceId] = useState('');

  const templateResourcesColumnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'resourceId',
      header: 'Resource',
      cell: (template) => template.resourceId,
      minWidth: '200px',
      sortingField: 'resourceId',
    },
    {
      id: 'scope',
      header: 'Resource type',
      cell: (template) => template.scope,
      minWidth: '200px',
      sortingField: 'scope',
    },
    {
      id: 'simTemplateName',
      header: 'Sim template name',
      cell: (template) =>
        template.accessManagementInfo?.simTemplates?.[0]?.simTemplateName
          ? template.accessManagementInfo?.simTemplates?.[0]?.simTemplateName
          : 'None',
      minWidth: '200px',
    },
    {
      id: 'approvalTemplateTitle',
      header: 'Approval template title',
      cell: (template) =>
        template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateTitle
          ? template.accessManagementInfo?.approvalsWorkflowTemplate?.approvalTemplateTitle
          : 'None',
      minWidth: '200px',
    },
  ];

  const { items, collectionProps, paginationProps, filterProps, filteredItemsCount } = useCollection(
    templateResources,
    {
      filtering: {
        noMatch: '',
        empty: (
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>{tableMessage}</b>
            </div>
            <p className='awsui-util-mb-s'>No templates to display.</p>
          </div>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
      propertyFiltering: {
        filteringProperties: [],
      },
    },
  );

  useEffect(() => {
    const { selectedItems } = collectionProps;
    const selectedResource: any = selectedItems[0];
    if (selectedResource !== undefined) {
      setSelectedResource(selectedResource);
      setSelectedResourceTemplateId(selectedResource.templateId);
      setSelectedTemplateResourceId(selectedResource.resourceId);
    }
  }, [collectionProps.selectedItems]);

  return (
    <>
      <Table
        {...collectionProps}
        selectionType='single'
        loadingText='Loading template resources...'
        columnDefinitions={templateResourcesColumnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={[
                {
                  text: 'Dissociate resource',
                  variant: 'primary',
                  // Table is single selectionType
                  disabled: !collectionProps.selectedItems.length || isGlobalTemplate(collectionProps.selectedItems[0]),
                  onItemClick: () => {
                    setDissociateModalVisible(true);
                  },
                },
              ]}
              header={<>Resources associated to selected template</>}
            />
          </>
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No resources</b>
            <Box variant='p' color='inherit'>
              No resources to display.
            </Box>
          </Box>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter resources'
            filteringPlaceholder='Find resources'
            countText={`${filteredItemsCount} ${filteredItemsCount === 1 ? 'match' : 'matches'}`}
          />
        }
        preferences={
          <CollectionPreferences
            title={'Preferences'}
            confirmLabel={'Confirm'}
            cancelLabel={'Cancel'}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 5, label: '5 items' },
                { value: 10, label: '10 items' },
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
              ],
            }}
            wrapLinesPreference={defaultWrapLinesPreference}
          />
        }
      />
      <Modal
        onDismiss={() => {
          setDissociateModalVisible(false);
        }}
        visible={dissociateModalVisible}
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='link'
                onClick={() => {
                  setDissociateModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant='primary'
                disabled={!validate.isNotFalsy(selectedTemplateResourceId)}
                loading={buttonLoading}
                onClick={() => {
                  setDissociateModalVisible(false);
                  handleDissociateResource(selectedResourceTemplateId, selectedTemplateResourceId);
                }}
              >
                {'Dissociate'}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header='Dissociate resource from template'
      >
        <ColumnLayout>
          <FormField label={'Resource ID'}>
            <Input ariaRequired={true} value={selectedResource.resourceId} readOnly={true} disabled />
          </FormField>
          <FormField label={'Resource Type'}>
            <Input ariaRequired={true} value={selectedResource.scope} readOnly={true} disabled />
          </FormField>
        </ColumnLayout>
      </Modal>
    </>
  );
};

export default TemplateResourcesTable;
