import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertProps,
  Box,
  Button,
  Cards,
  Checkbox,
  CollectionPreferencesProps,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Textarea,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import {
  ARIA_LABELS,
  BASELINE_TYPE_DATA_PERMISSION_CONSUMER,
  fetchBaselineNeededConsumerDataPermissions,
  fetchBaselineNeededConsumerIAMDataPermissions,
  fetchBaselineNeededConsumerRedshiftRoleToUserDataPermissions,
  getFilterCounterText,
  PAGINATION_ARIA_LABELS,
  RESOURCE_USER_BASELINE,
  submitResourceUserBaseline,
  TABLE_EMPTY_STATE,
  TABLE_NO_MATCH_STATE,
} from 'src/components/permissions/myBaselining/baseliningUtils';
import {
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  GROUP_TYPE_REDSHIFT_MARKETPLACE_DATA_PERMISSION_BASELINE_ONLY,
  LAKE_FORMATION_DATASOURCE_ID,
  TABLE_CONTENT_TYPE,
} from 'src/commons/constants';
import { CollectionPreferences, Header, Link, Pagination, SpaceBetween } from '@amzn/awsui-components-react-v3/polaris';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isValidAccountId, isValidWorkspace } from 'src/commons/validationUtils';
import { submitBaseline } from 'src/api/notifications';
import { getGroupInfo } from 'src/api/permissions';
import * as validate from 'src/commons/validationUtils';
import { validateWikiLink } from 'src/components/dataadvisory/constants';
import { keyForCatalogNameMap } from 'src/commons/common';

export interface BaselineDataAccessPermissionProps {
  catalogMap: any;
  setContentType: any;
  match: any;
  username: string;
}

export const BaselineDataAccessPermission = (props: BaselineDataAccessPermissionProps) => {
  const BASELINE_ACTION_DELETE = 'DELETE',
    BASELINE_ACTION_KEEP = 'KEEP';

  const CARD_DEFINITIONS = {
    header: (item) => getInfoFromDP(item, 'cardHeader'),
    sections: [
      {
        id: 'catalog',
        header: 'Catalog',
        content: (item) => getInfoFromDP(item, 'catalog'),
        width: 50,
      },
      {
        id: 'baselineDueDate',
        header: 'Baseline due date',
        content: (item) => getInfoFromDP(item, 'baselineDueDate'),
        width: 50,
      },
      {
        id: 'database',
        header: 'Database',
        content: (item) => getInfoFromDP(item, 'database'),
        width: 50,
      },
      {
        id: 'baselineStatus',
        header: 'Baseline status',
        content: (item) => getInfoFromDP(item, 'baselineStatus'),
        width: 50,
      },
      {
        id: 'table',
        header: 'Table',
        content: (item) => getInfoFromDP(item, 'table'),
        width: 50,
      },
      {
        id: 'baselineStatusReason',
        header: 'Baseline status reason',
        content: (item) => getInfoFromDP(item, 'baselineStatusReason'),
        width: 50,
      },
      {
        id: 'principal',
        header: 'Principal',
        content: (item) => item.dataLakePrincipal,
        width: 50,
      },
      {
        id: 'lastBaselinedDate',
        header: 'Last baselined date',
        content: (item) => getInfoFromDP(item, 'lastBaselinedDate'),
        width: 50,
      },
      {
        id: 'type',
        header: 'Type',
        content: (item) => item.type,
        width: 50,
      },
      {
        id: 'lastBaselinedBy',
        header: 'Last baselined by',
        content: (item) => getInfoFromDP(item, 'lastBaselinedBy'),
        width: 50,
      },
      {
        id: 'permissionTag',
        header: 'Permission tag',
        content: (item) => getInfoFromDP(item, 'permissionTag'),
        width: 50,
      },
    ],
  };

  const VISIBLE_CONTENT_OPTIONS = [
    {
      label: 'Main data access permission properties for baseline',
      options: [
        { id: 'catalog', label: 'Catalog' },
        { id: 'database', label: 'Database' },
        { id: 'table', label: 'Table' },
        { id: 'permissionTag', label: 'Permission tag' },
        { id: 'type', label: 'Type' },
        { id: 'principal', label: 'Principal' },
        { id: 'baselineDueDate', label: 'Baseline due date' },
        { id: 'baselineStatus', label: 'Baseline status' },
        { id: 'baselineStatusReason', label: 'Baseline status reason' },
        { id: 'lastBaselinedDate', label: 'Last baselined date' },
        { id: 'lastBaselinedBy', label: 'Last baselined by' },
      ],
    },
  ];

  const PAGE_SIZE_OPTIONS = [
    { value: 10, label: '10 Data access permissions' },
    { value: 20, label: '20 Data access permissions' },
  ];

  const DEFAULT_PREFERENCES = {
    pageSize: 10,
    visibleContent: [
      'catalog',
      'database',
      'table',
      'permissionTag',
      'type',
      'principal',
      'baselineDueDate',
      'baselineStatus',
      'baselineStatusReason',
    ],
  };

  const [dataPermissions, setDataPermissions] = useState([]);
  const [loadingBaseline, setLoadingBaseline] = useState(true);
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);
  const [keepLoading, setKeepLoading] = useState(false);
  const [relinquishLoading, setRelinquishLoading] = useState(false);
  const [redirect] = useState(undefined);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState('success' as AlertProps.Type);
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteText, setDeleteText] = useState(undefined);
  const [alertMessage, setAlertMessage] = useState('');
  const [teamName, setTeamName] = useState('');
  const [cti, setCTI] = useState('');
  const [wikis, setWikis] = useState('');
  const [useCase, setUseCase] = useState('');
  const [awsAccountIds, setAWSAccountIds] = useState('');
  // AWS Marketplace(MP) Data Access related
  const mpConfimration = (
    <div>
      I, {props.username}, confirm that 1) I have reviewed{' '}
      <a href='https://w.amazon.com/bin/view/AWS_Marketplace_Business_Dev/BusOps/BI/DataHandlingGuidance'>
        Marketplace Data Handling Guidelines
      </a>{' '}
      and{' '}
      <a href='https://w.amazon.com/bin/view/AWSProductsLegal/CustomerData/'>
        Legal Customer Data Handling Guidelines requirements
      </a>{' '}
      and have ensured that my use case meets all guidelines, 2) AWS Marketplace data and any reports derived from it
      will be shared only with the employees or groups explicitly listed in the provided details, and 3) Data will only
      be stored or processed in production environments which meet{' '}
      <a href='https://policy.a2z.com/docs/42682/publication'>AWS Data Handling Standard requirements</a>. I commit to
      immediately revise this information if any changes arise.
    </div>
  );
  const [laptopChecked, setLaptopChecked] = useState(false);
  const [awsAccountChecked, setAWSAccountChecked] = useState(false);
  const [amazonInternalStorageChecked, setAmazonInternalStorageChecked] = useState(false);
  const [quickSightChecked, setQuickSightChecked] = useState(false);
  const [dataSecurityOthersChecked, setDataSecurityOthersChecked] = useState(false);
  const [dataSecurityOthers, setDataSecurityOthers] = useState('');
  const [sharingOutsideL8YesChecked, setSharingOutsideL8YesChecked] = useState(false);
  const [sharingOutsideL8NoChecked, setSharingOutsideL8NoChecked] = useState(false);
  const [sharingOutsideL8Details, setSharingOutsideL8Details] = useState('');
  const [mpAlertVisible, setMPAlertVisible] = useState(false);
  const [mpModalVisible, setMPModalVisible] = useState(false);
  const [mpDataPermissions, setMPDataPermissions] = useState([]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    dataPermissions,
    {
      filtering: {
        empty: <TABLE_EMPTY_STATE resourceName='Data access permission' />,
        noMatch: <TABLE_NO_MATCH_STATE onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: {
        pageSize: preferences && preferences.pageSize ? preferences.pageSize : 10,
      },
      selection: {},
    },
  );

  useEffect(() => {
    props.setContentType(TABLE_CONTENT_TYPE);
    handleRefresh();
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [props.match.params.ownerId]);

  const handleRefresh = async () => {
    try {
      setLoadingBaseline(true);
      if (!props.match.params.ownerId) return;
      const ownerId = props.match.params.ownerId;
      if (!isValidWorkspace(ownerId)) {
        const ownerGroup = await getGroupInfo({ groupId: ownerId });
        if (ownerGroup?.config?.groupType == GROUP_TYPE_REDSHIFT_MARKETPLACE_DATA_PERMISSION_BASELINE_ONLY) {
          handleMPRefresh();
          return;
        }
      }
      const dps = await fetchBaselineNeededConsumerDataPermissions([ownerId]);
      if (!ownerId.startsWith('wks-')) {
        let iamDps = await fetchBaselineNeededConsumerIAMDataPermissions(ownerId);
        dps.push(...iamDps);
      }
      setDataPermissions(dps);
      setLoadingBaseline(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmitBaseline = async (baselineAction, dps) => {
    const resourcesToBaseline = [];
    const resourcesToBaselineIAM = [];
    try {
      for (let dp of dps) {
        if (DATA_PERMISSION_IAM_TYPE == dp.type && dp.resource && dp.resource.table) {
          const tableResource = dp.resource.table;
          const datasetId = `DS-glue|A-${tableResource.catalogId}|DN-${tableResource.databaseName}|TN-${tableResource.name}|R-${dp.region}`;
          resourcesToBaselineIAM.push({
            id: datasetId,
            ownerId: dp.ownerId,
          });
        } else resourcesToBaseline.push({ id: dp.dataPermissionId });
      }
      if (resourcesToBaseline.length)
        await submitResourceUserBaseline(baselineAction, 'DATA_PERMISSION_CONSUMER', resourcesToBaseline);
      if (resourcesToBaselineIAM.length)
        await submitResourceUserBaseline(baselineAction, 'IAM_DATA_PERMISSION', resourcesToBaselineIAM);
      setAlertType('success');
      setAlertVisible(true);
      setAlertMessage('Successfully submitted baseline.');
    } catch (error) {
      setAlertType('error');
      setAlertVisible(true);
      setAlertMessage(error.message);
      console.error(error);
    }
  };

  const handleKeep = async () => {
    setKeepLoading(true);
    const dps = [...collectionProps.selectedItems];
    await handleSubmitBaseline(BASELINE_ACTION_KEEP, dps);
    setKeepLoading(false);
    await handleRefresh();
  };

  const handleRelinquishConfirm = async () => {
    const dps = [...collectionProps.selectedItems];
    const deleteText = `You have selected ${dps.length} data access permissions. Do you want to continue?`;
    setDeleteText(deleteText);
    setModalVisible(true);
  };

  const handleRelinquish = async () => {
    setRelinquishLoading(true);
    const dps = [...collectionProps.selectedItems];
    await handleSubmitBaseline(BASELINE_ACTION_DELETE, dps);
    setModalVisible(false);
    setRelinquishLoading(false);
    await handleRefresh();
  };

  const handleMPRefresh = async () => {
    const ownerId = props.match.params.ownerId;
    let dps = await fetchBaselineNeededConsumerRedshiftRoleToUserDataPermissions(ownerId);
    dps = dps.filter((dp) => dp?.activatedBy == props.username);
    if (dps.length === 0) {
      setAlertType('error');
      setAlertVisible(true);
      setAlertMessage('You must be an active user of AWSDW owned Redshift cluster(s).');
      setLoadingBaseline(false);
      return;
    }
    setMPDataPermissions(dps);
    // Should have the same use case across DW Redshift clusters for the same user
    if (dps[0]?.baselineInfo?.useCase) {
      const dpUseCase = JSON.parse(dps[0]?.baselineInfo?.useCase);
      setTeamName(dpUseCase?.teamName ? dpUseCase.teamName : '');
      setCTI(dpUseCase?.cti ? dpUseCase.cti : '');
      setWikis(dpUseCase?.wikis ? dpUseCase.wikis : '');
      setUseCase(dpUseCase?.useCase ? dpUseCase.useCase : '');
      if (dpUseCase?.dataSecurity?.storage) {
        const storage = dpUseCase.dataSecurity.storage;
        setLaptopChecked(storage?.laptop !== undefined ? storage.laptop : false);
        setAWSAccountChecked(storage?.awsAccountIds !== undefined);
        setAWSAccountIds(storage?.awsAccountIds !== undefined ? storage.awsAccountIds : '');
        setAmazonInternalStorageChecked(
          storage?.amazonInternalStorage !== undefined ? storage.amazonInternalStorage : false,
        );
        setQuickSightChecked(storage?.quickSight !== undefined ? storage.quickSight : false);
        setDataSecurityOthersChecked(storage?.others !== undefined);
        setDataSecurityOthers(storage?.others !== undefined ? storage.others : '');
      }
      if (dpUseCase?.dataConsumers) {
        const dataConsumers = dpUseCase.dataConsumers;
        setSharingOutsideL8YesChecked(
          dataConsumers?.sharingOutsideL8 !== undefined ? dataConsumers.sharingOutsideL8 : false,
        );
        setSharingOutsideL8Details(dataConsumers?.sharingOutsideL8Details ? dataConsumers.sharingOutsideL8Details : '');
      }
    }
    setMPModalVisible(true);
    setLoadingBaseline(false);
  };

  const handleMPKeep = async () => {
    setKeepLoading(true);
    const invalidCTI = !cti.trim().match(/^.+\/.+\/.+$/);
    const invalidWikis = wikis.split('\n').filter((wiki) => !validate.isValidUrl(wiki) || !validateWikiLink(wiki));
    const invalidAccountIds = awsAccountIds.split('\n').filter((id) => !isValidAccountId(id));
    if (invalidCTI || invalidWikis.length || (awsAccountChecked && invalidAccountIds.length)) {
      setAlertType('error');
      setMPAlertVisible(true);
      setAlertMessage('Invalid CTI, wiki, or account IDs found.');
      setKeepLoading(false);
      return;
    }
    const resourcesToBaseline = mpDataPermissions.map((dp) => ({ id: dp.dataPermissionId }));
    try {
      await submitBaseline({
        baselineAction: BASELINE_ACTION_KEEP,
        baselineType: RESOURCE_USER_BASELINE,
        resourceType: BASELINE_TYPE_DATA_PERMISSION_CONSUMER,
        resourcesToBaseline: resourcesToBaseline,
        baselineInfo: { useCase: buildMPUseCaseJsonString() },
      });
      setAlertType('success');
      setMPAlertVisible(true);
      setAlertMessage(
        'Successfully submitted the form. You can now access Marketplace specific records in your queries within AWSDW owned Redshift cluster(s)',
      );
    } catch (error) {
      setAlertType('error');
      setMPAlertVisible(true);
      setAlertMessage('Failed to submit the form. Please try again later, or contact Omni oncall.');
      console.error(error);
    }
    setKeepLoading(false);
  };

  const buildMPUseCaseJsonString = () => {
    const dataSecurity = {
      storage: {
        laptop: laptopChecked,
        awsAccountIds: awsAccountChecked ? awsAccountIds : '',
        amazonInternalStorage: amazonInternalStorageChecked,
        quickSight: quickSightChecked,
        others: dataSecurityOthersChecked ? dataSecurityOthers : '',
      },
    };
    const dataConsumers = {
      sharingOutsideL8: sharingOutsideL8YesChecked,
      sharingOutsideL8Details: sharingOutsideL8YesChecked ? sharingOutsideL8Details : '',
    };
    const useCaseDict = {
      teamName: teamName.trim(),
      cti: cti.trim(),
      wikis: wikis.trim(),
      useCase: useCase.trim(),
      dataSecurity: dataSecurity,
      dataConsumers: dataConsumers,
    };
    return JSON.stringify(useCaseDict);
  };

  const toEnableMPKeepButton = () => {
    const atLeastOneCheckedForDataSecurity =
      laptopChecked ||
      awsAccountChecked ||
      amazonInternalStorageChecked ||
      quickSightChecked ||
      dataSecurityOthersChecked;
    const dataSecurityAWSAccountCheckedAndNotBlank = awsAccountChecked && awsAccountIds.trim();
    const dataSecurityOthersCheckedAndNotBlank = dataSecurityOthersChecked && dataSecurityOthers.trim();
    const validSharingOutsideL8 =
      (sharingOutsideL8YesChecked && sharingOutsideL8Details.trim()) || sharingOutsideL8NoChecked;
    return (
      teamName.trim() &&
      cti.trim() &&
      wikis.trim() &&
      useCase.trim() &&
      atLeastOneCheckedForDataSecurity &&
      (!awsAccountChecked || dataSecurityAWSAccountCheckedAndNotBlank) &&
      (!dataSecurityOthersChecked || dataSecurityOthersCheckedAndNotBlank) &&
      validSharingOutsideL8
    );
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getHeaderCounterText = (items: ReadonlyArray<unknown>, selectedItems: ReadonlyArray<unknown> | undefined) => {
    return selectedItems && selectedItems?.length > 0
      ? `(${selectedItems.length}/${items.length})`
      : `(${items.length})`;
  };

  const getDataObjectDetails = (dp) => {
    if (!dp || !dp.resource) return null;
    const dpResource = dp.resource;
    const database = dpResource.database;
    const table = dpResource.table;
    const tableWithColumns = dpResource.tableWithColumns;
    const lfTag = dpResource.lFTagPolicy;
    const redshiftTag = dpResource.redshiftTagPolicy;

    if (database) {
      return {
        catalogId: database.catalogId,
        databaseName: database.name,
        tableName: 'ALL_TABLES',
      };
    }

    if (table) {
      return {
        catalogId: table.catalogId,
        databaseName: table.databaseName,
        tableName: table.name ? table.name : 'ALL_TABLES',
        hcDatasetId: dp.hcDatasetId
          ? dp.hcDatasetId
          : `DS-glueLF|A-${table.catalogId}|DN-${table.databaseName}|TN-${table.name}|R-${dp.region}`,
      };
    }

    if (tableWithColumns) {
      return {
        catalogId: tableWithColumns.catalogId,
        databaseName: tableWithColumns.databaseName,
        tableName: tableWithColumns.name,
        hcDatasetId: `DS-glueLF|A-${tableWithColumns.catalogId}|DN-${tableWithColumns.databaseName}|TN-${tableWithColumns.name}|R-${dp.region}`,
      };
    }

    if (lfTag)
      return {
        catalogId: lfTag.catalogId,
        tagKey: lfTag.expression[0].tagKey,
        tagValue: lfTag.expression[0].tagValues[0],
      };
    if (redshiftTag)
      return {
        catalogId: redshiftTag.catalogId,
        tagKey: redshiftTag.tagKey,
        tagValue: redshiftTag.tagValue,
      };
  };

  const getInfoFromDP = (dp, infoName) => {
    if (!dp) return null;
    const dpDetails = getDataObjectDetails(dp);
    const defaultMessage = 'Visit data access permissions link for more details';
    const defaultBaselineDueDate = '2023-09-30';
    const defaultBaselineStatus = 'SCHEDULED';
    const defaultBaselineStatusReason =
      'Scheduled by Omni. All existing data access permissions granted before 2023-06-01' +
      ' require a review by the given due date.';

    switch (infoName) {
      case 'cardHeader':
        if (DATA_PERMISSION_IAM_TYPE == dp.type) return `Data Lake Role ${dp.dataPermissionId}`;
        if (dp.ownerId.startsWith('wks-')) {
          return (
            <div>
              <Link
                fontSize='heading-m'
                href={`/workspaces-permission/${dp.type.toLowerCase()}/${dp.dataPermissionId}`}
              >
                {dp.dataPermissionId}
              </Link>
            </div>
          );
        } else {
          return (
            <div>
              <Link fontSize='heading-m' href={`/mydatasets/${dp.dataPermissionId}`}>
                {dp.dataPermissionId}
              </Link>
            </div>
          );
        }

      case 'catalog':
        if (DATA_PERMISSION_REDSHIFT_TYPE == dp.type) return dpDetails.catalogId;
        // for now baselining does not support DZ resources so we are not capturing clusterId / workgroupName
        // try once for a glue catalog name, else just default to account ID
        const key = keyForCatalogNameMap(
          LAKE_FORMATION_DATASOURCE_ID,
          dpDetails.catalogId,
          undefined,
          undefined,
          dp.region,
        );
        if (!props.catalogMap.get(key)) return dpDetails.catalogId;
        return (
          <div>
            <Link fontSize='body-m' href={`/catalogs/${dpDetails.catalogId}/${dp.region}`}>
              {props.catalogMap.get(key)}
            </Link>
          </div>
        );

      case 'database':
        if (!dpDetails.databaseName) return defaultMessage;
        return (
          <div>
            <Link fontSize='body-m' href={`/databases/${dpDetails.catalogId}/${dpDetails.databaseName}`}>
              {dpDetails.databaseName}
            </Link>
          </div>
        );

      case 'table':
        if (!dpDetails.tableName) return defaultMessage;
        if ('ALL_TABLES' == dpDetails.tableName) return 'ALL_TABLES';
        return (
          <div>
            <Link fontSize='body-m' href={`/datasets/${dpDetails.hcDatasetId}`}>
              {dpDetails.tableName}
            </Link>
          </div>
        );

      case 'permissionTag':
        if (dp.ownerId.startsWith('wks-') && dpDetails.tagKey && dpDetails.tagValue)
          return `${dpDetails.tagKey}.${dpDetails.tagValue}`;
        break;

      case 'baselineDueDate':
        if (!dp.baselineInfo.baselineDueDate) return defaultBaselineDueDate;
        return dp.baselineInfo.baselineDueDate;
      case 'baselineStatus':
        if (!dp.baselineInfo.baselineStatus) return defaultBaselineStatus;
        return dp.baselineInfo.baselineStatus;
      case 'baselineStatusReason':
        if (!dp.baselineInfo.baselineStatusReason) return defaultBaselineStatusReason;
        return dp.baselineInfo.baselineStatusReason;
      case 'lastBaselinedDate':
        return dp.baselineInfo.lastBaselineTimestamp;
      case 'lastBaselinedBy':
        return dp.baselineInfo.lastBaselineBy;
    }
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Modal
        visible={mpModalVisible}
        size='max'
        header={'AWS Data Platform Marketplace data access'}
        footer={
          <div>
            <div style={{ marginBottom: '10px' }}>
              <Alert
                type={alertType}
                dismissible={true}
                visible={mpAlertVisible}
                onDismiss={() => setMPAlertVisible(false)}
              >
                {alertMessage}
              </Alert>
            </div>
            <Box float='right'>
              <Button variant='primary' onClick={handleMPKeep} loading={keepLoading} disabled={!toEnableMPKeepButton()}>
                Submit
              </Button>
            </Box>
          </div>
        }
      >
        <ColumnLayout>
          <FormField label='Team name'>
            <Input value={teamName} onChange={(e) => setTeamName(e.detail.value)} />
          </FormField>
          <FormField label='CTI'>
            <Input value={cti} onChange={(e) => setCTI(e.detail.value)} placeholder={'Specify in C/T/I format.'} />
          </FormField>
          <FormField
            label='Team and use case wikis'
            description={
              "Provide wiki pages that describe your team and the specific use case for which you're accessing AWS Marketplace data."
            }
          >
            <Textarea
              value={wikis}
              onChange={(e) => setWikis(e.detail.value)}
              placeholder={'Specify each wiki on a new line.'}
            />
          </FormField>
          <FormField
            label='Business use case'
            description={
              <div>
                Provide a detailed explanation of why you need access to AWS Marketplace data. Include your project
                goals, data requirements, and how this access aligns with your business objectives.{' '}
                <a href='https://tiny.amazon.com/14tnvcvrs/wamazbinviewAWSCPRETTeamData'>
                  Learn more about AWS Marketplace data
                </a>
              </div>
            }
          >
            <Textarea onChange={(e) => setUseCase(e.detail.value)} value={useCase} />
          </FormField>
          <FormField
            label='Data security'
            description={'Provide the environments you will use for storing and processing AWS Marketplace data.'}
          >
            <Checkbox checked={laptopChecked} onChange={(e) => setLaptopChecked(e.detail.checked)}>
              Laptop
            </Checkbox>
            <Checkbox checked={awsAccountChecked} onChange={(e) => setAWSAccountChecked(e.detail.checked)}>
              AWS account
            </Checkbox>
            {awsAccountChecked && (
              <Textarea
                placeholder={'Specify each account on a new line.'}
                onChange={(e) => setAWSAccountIds(e.detail.value)}
                value={awsAccountIds}
              />
            )}
            <Checkbox
              checked={amazonInternalStorageChecked}
              onChange={(e) => setAmazonInternalStorageChecked(e.detail.checked)}
            >
              Amazon internal storage (SharedDrive, WorkDocs, etc)
            </Checkbox>
            <Checkbox checked={quickSightChecked} onChange={(e) => setQuickSightChecked(e.detail.checked)}>
              Amazon QuickSight
            </Checkbox>
            <Checkbox
              checked={dataSecurityOthersChecked}
              onChange={(e) => setDataSecurityOthersChecked(e.detail.checked)}
            >
              Others
            </Checkbox>
            {dataSecurityOthersChecked && (
              <Textarea onChange={(e) => setDataSecurityOthers(e.detail.value)} value={dataSecurityOthers} />
            )}
          </FormField>
          <FormField label='Do you share AWS Marketplace data or any reports derived from it outside of your L8 organization?'>
            <Checkbox
              checked={sharingOutsideL8YesChecked && !sharingOutsideL8NoChecked}
              onChange={(e) => {
                setSharingOutsideL8YesChecked(e.detail.checked);
                setSharingOutsideL8NoChecked(!e.detail.checked);
              }}
            >
              Yes
            </Checkbox>
            {sharingOutsideL8YesChecked && (
              <FormField
                description={'Provide the individuals or groups. Include all LDAP, POSIX, TEAMS groups or aliases.'}
              >
                <Textarea
                  onChange={(e) => setSharingOutsideL8Details(e.detail.value)}
                  value={sharingOutsideL8Details}
                />
              </FormField>
            )}
            <Checkbox
              checked={sharingOutsideL8NoChecked && !sharingOutsideL8YesChecked}
              onChange={(e) => {
                setSharingOutsideL8NoChecked(e.detail.checked);
                setSharingOutsideL8YesChecked(!e.detail.checked);
              }}
            >
              No
            </Checkbox>
          </FormField>
          <FormField>
            <Checkbox checked={true}>{mpConfimration}</Checkbox>
          </FormField>
        </ColumnLayout>
      </Modal>
      <Modal
        visible={modalVisible}
        header={'Are you sure you want to relinquish?'}
        onDismiss={closeModal}
        footer={
          <span className='awsui-util-f-r'>
            <Button variant='link' onClick={closeModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleRelinquish} loading={relinquishLoading}>
              Confirm
            </Button>
          </span>
        }
      >
        {deleteText}
      </Modal>
      <div style={{ marginBottom: '10px' }}>
        <Alert type={alertType} dismissible={true} visible={alertVisible} onDismiss={() => setAlertVisible(false)}>
          {alertMessage}
        </Alert>
      </div>
      <Cards
        {...collectionProps}
        stickyHeader={true}
        cardDefinition={CARD_DEFINITIONS}
        visibleSections={preferences.visibleContent}
        cardsPerRow={[{ cards: 3 }, { minWidth: 500, cards: 2 }]}
        loading={loadingBaseline}
        loadingText='Loading data access permissions'
        items={items}
        selectionType='multi'
        variant='full-page'
        ariaLabels={ARIA_LABELS}
        header={
          <Header
            variant='awsui-h1-sticky'
            actions={
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  disabled={collectionProps.selectedItems.length === 0 || loadingBaseline}
                  onClick={handleRelinquishConfirm}
                  loading={relinquishLoading}
                >
                  Relinquish
                </Button>
                <Button
                  variant='primary'
                  disabled={collectionProps.selectedItems.length === 0 || loadingBaseline}
                  onClick={handleKeep}
                  loading={keepLoading}
                >
                  Keep
                </Button>
              </SpaceBetween>
            }
            counter={getHeaderCounterText(dataPermissions, collectionProps.selectedItems)}
          >
            Data access permissions
          </Header>
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter data access permissions'
            filteringPlaceholder='Find data access permissions'
            countText={getFilterCounterText(filteredItemsCount)}
            disabled={loadingBaseline}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PAGINATION_ARIA_LABELS(paginationProps.pagesCount)}
            disabled={loadingBaseline}
          />
        }
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            disabled={loadingBaseline}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SIZE_OPTIONS,
            }}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: VISIBLE_CONTENT_OPTIONS,
            }}
          />
        }
      />
    </>
  );
};
