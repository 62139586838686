import {
  DATA_PERMISSION_DZ_TYPE,
  DATA_PERMISSION_IAM_TYPE,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_REDSHIFT_TYPE,
  DGS_SERVICE_NAME_GLADSTONE,
  DZ_GLUE_DATASOURCE_ID,
  DZ_REDSHIFT_DATASOURCE_ID,
  IAM_DATASOURCE_ID,
  LAKE_FORMATION_DATASOURCE_ID,
  REDSHIFT_DATASOURCE_ID,
} from 'src/commons/constants';
import { generateSchemaId } from 'src/components/utils/hybridCatalog/idUtil';
import { getSchemas, listCatalogs, listDatabases, listDataSets } from 'src/api/catalog';
import {
  createDatasetDetailLink,
  createCatalogDetailLink,
  createSchemaDetailLink,
  createDatabaseDetailLink,
} from 'src/routes';
import { IAM_CATALOG_IDS } from 'src/components/lineage/latticeLineageConstants';

export const HYBRID_CATALOG_ARN_PREFIX = '^arn\\:(aws(-(cn|iso(-[a-z])?))?)\\:dgs-hc\\:.+\\:\\d{12}\\:';

export const HC_RESOURCE_ARN_REGEX = HYBRID_CATALOG_ARN_PREFIX + '.+[\\/A-Za-z0-9_-]*';

export const HC_CATALOG_ARN_REGEX = HYBRID_CATALOG_ARN_PREFIX + '.+';
export const HC_DATABASE_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(glue|glueLF|glueGalaxi|redshift|redshiftDZ)/database/.+$';
export const HC_DATASET_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(glue|glueLF|glueGalaxi|redshift|redshiftDZ)/database/.+/table/.+$';
export const HC_COLUMN_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(glue|glueLF|glueGalaxi|redshift|redshiftDZ)/database/.+/table/.+/column/.+$';

export const HC_REDSHIFT_CATALOG_ARN_REGEX = HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/cluster/.+$';
export const HC_REDSHIFT_SRLS_CATALOG_ARN_REGEX = HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/serverless/.+$';
export const HC_REDSHIFT_DATABASE_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/cluster/.+/database/.+$';
export const HC_REDSHIFT_SRLS_DATABASE_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/serverless/.+/database/.+$';
export const HC_REDSHIFT_SCHEMA_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/cluster/.+/database/.+/schema/.+$';
export const HC_REDSHIFT_SRLS_SCHEMA_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/serverless/.+/database/.+/schema/.+$';
export const HC_REDSHIFT_DATASET_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/cluster/.+/database/.+/schema/.+/table/.+$';
export const HC_REDSHIFT_SRLS_DATASET_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/serverless/.+/database/.+/schema/.+/table/.+$';
export const HC_REDSHIFT_COLUMN_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/cluster/.+/database/.+/schema/.+/table/.+/column/.+$';
export const HC_REDSHIFT_SRLS_COLUMN_ARN_REGEX =
  HYBRID_CATALOG_ARN_PREFIX + '(redshift|redshiftDZ)/serverless/.+/database/.+/schema/.+/table/.+/column/.+$';

export const AWS = 'aws';
export const CN_NORTHWEST_1 = 'cn-northwest-1';
export const AWS_CN = 'aws-cn';
export const US_ISO_EAST_1 = 'us-iso-east-1';
export const AWS_ISO = 'aws-iso';
export const US_ISOB_EAST_1 = 'us-isob-east-1';
export const AWS_ISO_B = 'aws-iso-b';
export const EU_ISOE_WEST_1 = 'eu-isoe-west-1';
export const AWS_ISO_E = 'aws-iso-e';
export const US_ISOF_SOUTH_1 = 'us-isof-south-1';
export const AWS_ISO_F = 'aws-iso-f';

export interface IdInfo {
  dataSource: string;
  catalogId: string;
  clusterId?: string;
  redshiftWorkgroupName?: string;
  databaseName?: string;
  schemaName?: string;
  tableName?: string;
  columnName?: string;
  region: string;
}

export enum Scope {
  Catalog = 'Catalog',
  Database = 'Database',
  Schema = 'Schema',
  Table = 'Table',
  Column = 'Column',
}

export function isRedshift(idInfo: IdInfo) {
  return idInfo.dataSource == REDSHIFT_DATASOURCE_ID || idInfo.dataSource == DZ_REDSHIFT_DATASOURCE_ID;
}

export function isServerless(idInfo: IdInfo) {
  return !!idInfo.redshiftWorkgroupName;
}

export function getScopeForIdInfo(idInfo: IdInfo) {
  if (idInfo.columnName) {
    return Scope.Column;
  } else if (idInfo.tableName) {
    return Scope.Table;
  } else if (idInfo.schemaName) {
    return Scope.Schema;
  } else if (idInfo.databaseName) {
    return Scope.Database;
  } else {
    return Scope.Catalog;
  }
}

export function getResourceNameForIdInfo(idInfo: IdInfo) {
  const scope = getScopeForIdInfo(idInfo);
  if (scope == Scope.Catalog) {
    return idInfo.catalogId;
  } else if (scope == Scope.Database) {
    return idInfo.databaseName;
  } else if (scope == Scope.Schema) {
    return idInfo.schemaName;
  } else if (scope == Scope.Table) {
    return idInfo.tableName;
  } else if (scope == Scope.Column) {
    return idInfo.columnName;
  } else {
    return '';
  }
}

export function getScopeForId(id: string) {
  const idInfo = getIdInfoFromId(id);
  return getScopeForIdInfo(idInfo);
}

export function getScopeForArn(arn: string) {
  const idInfo = getIdInfoFromArn(arn);
  return getScopeForIdInfo(idInfo);
}

export function extractAttributeFromId(attributeName: string, id: string) {
  // special case: DS- is always first
  if (attributeName == 'DS') {
    return id.split('|')[0].substring(3);
  }
  if (!id.includes('|' + attributeName + '-')) {
    return undefined; // return undefined if the attribute does not exist
  }
  return id.split('|' + attributeName + '-')[1].split('|')[0];
}

export function extractAttributeFromArn(attributeName: string, arn: string) {
  if (!arn.includes('/' + attributeName + '/')) return undefined;
  return arn.split('/' + attributeName + '/')[1].split('/')[0];
}

export function getIdInfoFromId(id: string) {
  return {
    dataSource: extractAttributeFromId('DS', id),
    catalogId: extractAttributeFromId('A', id),
    clusterId: extractAttributeFromId('CI', id),
    redshiftWorkgroupName: extractAttributeFromId('WN', id),
    databaseName: extractAttributeFromId('DN', id),
    schemaName: extractAttributeFromId('SN', id),
    tableName: extractAttributeFromId('TN', id),
    columnName: extractAttributeFromId('CN', id),
    region: extractAttributeFromId('R', id),
  } as IdInfo;
}

export function getIdInfoFromArn(arn: string) {
  // example arn: arn:aws:dgs-hc:us-west-2:375937567384:redshift/cluster/redshift-test/database/awsdw/schema/awsdl_processed/table/o_aws_regions

  const region = arn.split(':')[3];
  const dataSource = arn.split(':')[5]?.split('/')[0];
  const catalogId = arn.split(':')[4];
  const clusterId = extractAttributeFromArn('cluster', arn);
  const redshiftWorkgroupName = extractAttributeFromArn('serverless', arn);
  const databaseName = extractAttributeFromArn('database', arn);
  const schemaName = extractAttributeFromArn('schema', arn);
  const tableName = extractAttributeFromArn('table', arn);
  const columnName = extractAttributeFromArn('column', arn);

  return {
    dataSource: dataSource,
    catalogId: catalogId,
    clusterId: clusterId,
    redshiftWorkgroupName: redshiftWorkgroupName,
    databaseName: databaseName,
    schemaName: schemaName,
    tableName: tableName,
    columnName: columnName,
    region: region,
  } as IdInfo;
}

export function hcArnPrefix(idInfo: IdInfo) {
  return `arn:${getAwsPartitionForRegion(idInfo.region)}:dgs-hc:${idInfo.region}:${idInfo.catalogId}:${
    idInfo.dataSource
  }`;
}

export function hcIdPrefix(idInfo: IdInfo) {
  return `DS-${idInfo.dataSource}|A-${idInfo.catalogId}`;
}

export function generateHcGlueDatasetArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/database/${idInfo.databaseName}/table/${idInfo.tableName}`;
}

export function generateGlueDatasetArn(idInfo: IdInfo) {
  return (
    `arn:${getAwsPartitionForRegion(idInfo.region)}:glue:${idInfo.region}:${idInfo.catalogId}:` +
    `table/${idInfo.databaseName}/${idInfo.tableName}`
  );
}

export function generateHcGlueDatasetId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|DN-${idInfo.databaseName}|TN-${idInfo.tableName}|R-${idInfo.region}`;
}

export function generateHcGlueDatabaseArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateGlueDatabaseArn(idInfo: IdInfo) {
  return (
    `arn:${getAwsPartitionForRegion(idInfo.region)}:glue:${idInfo.region}:${idInfo.catalogId}:` +
    `database/${idInfo.databaseName}`
  );
}

export function generateHcGlueDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcGlueCatalogArn(idInfo: IdInfo) {
  return `arn:${getAwsPartitionForRegion(idInfo.region)}:dgs-hc:${idInfo.region}:${idInfo.catalogId}:${
    idInfo.dataSource
  }`;
}

export function generateHcGlueCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

// REDSHIFT ARNS AND IDS

export function generateHcRedshiftColumnArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatasetArn(idInfo) + `/column/${idInfo.columnName}`;
}

export function generateHcRedshiftServerlessColumnArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatasetArn(idInfo) + `/column/${idInfo.columnName}`;
}

export function generateHcRedshiftDatasetArn(idInfo: IdInfo) {
  return generateHcRedshiftSchemaArn(idInfo) + `/table/${idInfo.tableName}`;
}

export function generateHcRedshiftServerlessDatasetArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessSchemaArn(idInfo) + `/table/${idInfo.tableName}`;
}

export function generateHcRedshiftDatasetId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|TN-${idInfo.tableName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftServerlessDatasetId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|TN-${idInfo.tableName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftSchemaArn(idInfo: IdInfo) {
  return generateHcRedshiftDatabaseArn(idInfo) + `/schema/${idInfo.schemaName}`;
}

export function generateHcRedshiftServerlessSchemaArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessDatabaseArn(idInfo) + `/schema/${idInfo.schemaName}`;
}

export function generateHcRedshiftSchemaId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) + `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftServerlessSchemaId(idInfo: IdInfo) {
  return (
    hcIdPrefix(idInfo) +
    `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|R-${idInfo.region}`
  );
}

export function generateHcRedshiftDatabaseArn(idInfo: IdInfo) {
  return generateHcRedshiftCatalogArn(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateHcRedshiftServerlessDatabaseArn(idInfo: IdInfo) {
  return generateHcRedshiftServerlessCatalogArn(idInfo) + `/database/${idInfo.databaseName}`;
}

export function generateHcRedshiftDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcRedshiftServerlessDatabaseId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|WN-${idInfo.redshiftWorkgroupName}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
}

export function generateHcRedshiftCatalogArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/cluster/${idInfo.clusterId}`;
}

export function generateHcRedshiftServerlessCatalogArn(idInfo: IdInfo) {
  return hcArnPrefix(idInfo) + `/serverless/${idInfo.redshiftWorkgroupName}`;
}

export function generateHcRedshiftCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

export function generateHcRedshiftServerlessCatalogId(idInfo: IdInfo) {
  return hcIdPrefix(idInfo) + `|R-${idInfo.region}`;
}

export function generateGlueArnFromId(id: string) {
  const scope = getScopeForId(id);
  const idInfo = getIdInfoFromId(id);

  if (scope == Scope.Table) {
    return generateGlueDatasetArn(idInfo);
  } else if (scope == Scope.Database) return generateGlueDatabaseArn(idInfo);
  return '';
}

export function generateArnFromId(id: string) {
  const scope = getScopeForId(id);
  const idInfo = getIdInfoFromId(id);

  if (scope == Scope.Table) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatasetArn(idInfo);
      } else {
        return generateHcRedshiftDatasetArn(idInfo);
      }
    } else {
      return generateHcGlueDatasetArn(idInfo);
    }
  } else if (scope == Scope.Database) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatabaseArn(idInfo);
      } else {
        return generateHcRedshiftDatabaseArn(idInfo);
      }
    } else {
      return generateHcGlueDatabaseArn(idInfo);
    }
  } else if (scope == Scope.Catalog) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessCatalogArn(idInfo);
      } else {
        return generateHcRedshiftCatalogArn(idInfo);
      }
    } else {
      return generateHcGlueCatalogArn(idInfo);
    }
  } else if (scope == Scope.Schema) {
    if (isServerless(idInfo)) {
      return generateHcRedshiftServerlessSchemaArn(idInfo);
    } else {
      return generateHcRedshiftSchemaArn(idInfo);
    }
  }
  return '';
}

export function generateIdFromArn(arn: string) {
  const scope = getScopeForArn(arn);
  const idInfo = getIdInfoFromArn(arn);

  if (scope == Scope.Table) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatasetId(idInfo);
      } else {
        return generateHcRedshiftDatasetId(idInfo);
      }
    } else {
      return generateHcGlueDatasetId(idInfo);
    }
  } else if (scope == Scope.Database) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessDatabaseId(idInfo);
      } else {
        return generateHcRedshiftDatabaseId(idInfo);
      }
    } else {
      return generateHcGlueDatabaseId(idInfo);
    }
  } else if (scope == Scope.Catalog) {
    if (isRedshift(idInfo)) {
      if (isServerless(idInfo)) {
        return generateHcRedshiftServerlessCatalogId(idInfo);
      } else {
        return generateHcRedshiftCatalogId(idInfo);
      }
    } else {
      return generateHcGlueCatalogId(idInfo);
    }
  } else if (scope == Scope.Schema) {
    if (isServerless(idInfo)) {
      return generateHcRedshiftServerlessSchemaId(idInfo);
    } else {
      return generateHcRedshiftSchemaId(idInfo);
    }
  }
  return '';
}

export function getAwsPartitionForRegion(region: string) {
  switch (region) {
    case CN_NORTHWEST_1:
      return AWS_CN;
    case US_ISO_EAST_1:
      return AWS_ISO;
    case US_ISOB_EAST_1:
      return AWS_ISO_B;
    case EU_ISOE_WEST_1:
      return AWS_ISO_E;
    case US_ISOF_SOUTH_1:
      return AWS_ISO_F;
    default:
      return AWS;
  }
}

export function convertResourceARNToLink(arn: string) {
  const scope = getScopeForArn(arn);
  const idInfo = getIdInfoFromArn(arn);

  if (scope == Scope.Catalog) {
    return createCatalogDetailLink(
      idInfo.dataSource,
      idInfo.catalogId,
      idInfo.clusterId,
      idInfo.redshiftWorkgroupName,
      idInfo.region,
    );
  }
  if (scope == Scope.Database) {
    return createDatabaseDetailLink(
      idInfo.dataSource,
      idInfo.catalogId,
      idInfo.clusterId,
      idInfo.redshiftWorkgroupName,
      idInfo.databaseName,
      idInfo.region,
    );
  }
  if (scope == Scope.Table) {
    const datasetId = isRedshift(idInfo) ? generateHcRedshiftDatasetId(idInfo) : generateHcGlueDatasetId(idInfo);
    return createDatasetDetailLink(datasetId);
  }
  if (scope == Scope.Schema) {
    return createSchemaDetailLink(
      idInfo.dataSource,
      idInfo.catalogId,
      idInfo.clusterId,
      idInfo.redshiftWorkgroupName,
      idInfo.databaseName,
      idInfo.schemaName,
      idInfo.region,
    );
  }
  return '';
}

export const convertResourceArnToDpResource = (arn: string) => {
  const idInfo: IdInfo = getIdInfoFromArn(arn);
  const id: string = generateIdFromArn(arn);
  const scope: string = getScopeForArn(arn);

  const typeForScope = (scope: String) => {
    if (scope == Scope.Table) {
      return 'TABLE';
    } else if (scope == Scope.Database) {
      return 'DATABASE';
    } else if (scope == Scope.Schema) {
      return 'SCHEMA';
    }
    return '';
  };

  const dpTypeForDataSource = (dataSource: string) => {
    if (dataSource == IAM_DATASOURCE_ID) {
      return DATA_PERMISSION_IAM_TYPE;
    } else if (dataSource == LAKE_FORMATION_DATASOURCE_ID) {
      return DATA_PERMISSION_LAKE_FORMATION_TYPE;
    } else if (dataSource == REDSHIFT_DATASOURCE_ID) {
      return DATA_PERMISSION_REDSHIFT_TYPE;
    } else if (dataSource == DZ_REDSHIFT_DATASOURCE_ID || dataSource == DZ_GLUE_DATASOURCE_ID) {
      return DATA_PERMISSION_DZ_TYPE;
    }
    return '';
  };

  const resource = {
    accountId: idInfo.catalogId,
    region: idInfo.region,
    type: typeForScope(scope),
    dataCatalogObjectDetails: {
      dataSourceId: idInfo.dataSource,
      clusterIdentifier: idInfo.clusterId,
      redshiftWorkgroupName: idInfo.redshiftWorkgroupName,
      databaseName: idInfo.databaseName,
      schemaName: idInfo.schemaName,
      tableName: idInfo.tableName,
    },
    dpType: dpTypeForDataSource(idInfo.dataSource),
    tagResourceId: id,
    tagUpperLevelResourceId:
      idInfo.dataSource === LAKE_FORMATION_DATASOURCE_ID ? generateHcGlueDatabaseId(idInfo) : generateSchemaId(idInfo),
  };

  return resource;
};

// TODO
export const userOwnsResource = async (userInfo: any, idInfo: IdInfo) => {
  const scope = getScopeForIdInfo(idInfo);
  let owners = [];
  try {
    if (scope == Scope.Table) {
      const id = isRedshift(idInfo)
        ? isServerless(idInfo)
          ? generateHcRedshiftServerlessDatasetId(idInfo)
          : generateHcRedshiftDatasetId(idInfo)
        : generateHcGlueDatasetId(idInfo);
      const dataSetResult = await listDataSets({
        Filter: {
          IdList: [id],
        },
      });
      owners = dataSetResult.DataSetList[0].Owners;
      owners.push(dataSetResult.DataSetList[0].PrimaryOwner);
    } else if (scope == Scope.Database) {
      const databaseResult = await listDatabases({
        DatabaseKeyList: [
          {
            CatalogId: idInfo.catalogId,
            DatabaseName: idInfo.databaseName,
            ClusterIdentifier: idInfo.clusterId,
            RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
            Region: idInfo.region,
            DataSourceId: idInfo.dataSource,
          },
        ],
      });
      owners = databaseResult.DatabaseInfoList[0].Owners;
    } else if (scope == Scope.Catalog) {
      const catalogResult = await listCatalogs({
        Filter: {
          CatalogKeyList: [
            {
              CatalogId: idInfo.catalogId,
              Region: idInfo.region,
              ClusterIdentifier: idInfo.clusterId,
              RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
              DataSourceId: idInfo.dataSource,
            },
          ],
        },
      });
      owners = [catalogResult.CatalogInfoList[0].Owner];
    } else if (scope == Scope.Schema) {
      const schemaResult = await getSchemas({
        SchemaKeyList: [
          {
            CatalogId: idInfo.catalogId,
            DatabaseName: idInfo.databaseName,
            Schema: idInfo.schemaName,
            DataSourceId: idInfo.dataSource,
            Region: idInfo.region,
            ClusterIdentifier: idInfo.clusterId,
            RedshiftWorkgroupName: idInfo.redshiftWorkgroupName,
          },
        ],
      });
      owners = schemaResult.SchemaInfoList[0].Owners;
    }
  } catch (e) {
    console.log('Failed to fetch ownership for idInfo: ', idInfo, e);
    return false;
  }

  const groupOwner = userInfo?.memberGroupIds?.some((e) => owners.includes(e));
  const workspaceOwner = userInfo?.memberWorkspaceIds?.some((e) => owners.includes(e));
  return groupOwner || workspaceOwner;
};

// Gladstone
export function generateFGAPArn(policy) {
  if (!policy) return null;

  const policyId = policy.id;
  const tableData = policy.tableData;

  if (tableData.clusterId) {
    return (
      `arn:${getAwsPartitionForRegion(tableData.region)}:${DGS_SERVICE_NAME_GLADSTONE}:${tableData.region}:${
        tableData.tableCatalogId
      }:${REDSHIFT_DATASOURCE_ID}` +
      `/cluster/${tableData.clusterId}/database/${tableData.databaseName}/schema/${tableData.schemaName}/table/${tableData.tableName}/fine-grained-access-policy/${policyId}`
    );
  }

  return (
    `arn:${getAwsPartitionForRegion(tableData.region)}:${DGS_SERVICE_NAME_GLADSTONE}:${tableData.region}:${
      tableData.tableCatalogId
    }:${LAKE_FORMATION_DATASOURCE_ID}` +
    `/database/${tableData.databaseName}/table/${tableData.tableName}/fine-grained-access-policy/${policyId}`
  );
}

// ----------------------------------------------- Arn Utils -------------------------------------------------

// HybridCatalogId to AWS resource arn format
export function getAWSResourceArnFromHybridCatalogId(hybridCatalogId: string) {
  const scope = getScopeForId(hybridCatalogId);
  const idInfo = getIdInfoFromId(hybridCatalogId);

  if (scope == Scope.Table) {
    return generateAWSTableResourceArn(idInfo);
  } else if (scope == Scope.Schema) {
    return generateAWSSchemaResourceArn(idInfo);
  } else if (scope == Scope.Database) {
    return generateAWSDatabaseResourceArn(idInfo);
  }
  return '';
}

function generateAWSTableResourceArn(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:redshift:${idInfo?.region}:${idInfo?.catalogId}:` +
      `table/${idInfo?.clusterId}/${idInfo?.databaseName}/${idInfo?.schemaName}/${idInfo?.tableName}`
    );
  } else {
    // glue
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:glue:${idInfo?.region}:${idInfo?.catalogId}:` +
      `table/${idInfo?.databaseName}/${idInfo?.tableName}`
    );
  }
}

function generateAWSSchemaResourceArn(idInfo: IdInfo) {
  // redshift
  return (
    `arn:${getAwsPartitionForRegion(idInfo?.region)}:redshift:${idInfo?.region}:${idInfo?.catalogId}:` +
    `schema/${idInfo?.clusterId}/${idInfo?.databaseName}/${idInfo?.schemaName}`
  );
}

function generateAWSDatabaseResourceArn(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:redshift:${idInfo?.region}:${idInfo?.catalogId}:` +
      `database/${idInfo?.clusterId}/${idInfo?.databaseName}`
    );
  } else {
    // glue
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:glue:${idInfo?.region}:${idInfo?.catalogId}:` +
      `database/${idInfo?.databaseName}`
    );
  }
}

// HybridCatalogId to HybridCatalog resource arn format
export function getHybridCatalogResourceArnFromHybridCatalogId(hybridCatalogId: string) {
  const scope = getScopeForId(hybridCatalogId);
  const idInfo = getIdInfoFromId(hybridCatalogId);

  if (scope == Scope.Table) {
    return generateHybridCatalogTableResourceArn(idInfo);
  } else if (scope == Scope.Schema) {
    return generateHybridCatalogSchemaResourceArn(idInfo);
  } else if (scope == Scope.Database) {
    return generateHybridCatalogDatabaseResourceArn(idInfo);
  }
  return '';
}

function generateHybridCatalogTableResourceArn(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:` +
      `redshift/cluster/${idInfo?.clusterId}/database/${idInfo?.databaseName}/schema/${idInfo?.schemaName}/table/${idInfo?.tableName}`
    );
  } else {
    if (idInfo?.dataSource === 'glue') {
      // glue
      return (
        `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:glue/` +
        `database/${idInfo?.databaseName}/table/${idInfo?.tableName}`
      );
    } else {
      // glueLF
      return (
        `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:glueLF/` +
        `database/${idInfo?.databaseName}/table/${idInfo?.tableName}`
      );
    }
  }
}

function generateHybridCatalogSchemaResourceArn(idInfo: IdInfo) {
  // redshift
  return (
    `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:` +
    `redshift/cluster/${idInfo?.clusterId}/database/${idInfo?.databaseName}/schema/${idInfo?.schemaName}`
  );
}

function generateHybridCatalogDatabaseResourceArn(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return (
      `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:` +
      `redshift/cluster/${idInfo?.clusterId}/database/${idInfo?.databaseName}`
    );
  } else {
    if (idInfo?.dataSource === 'glue') {
      // glue
      return (
        `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:` +
        `glue/database/${idInfo?.databaseName}`
      );
    } else {
      // glueLF
      return (
        `arn:${getAwsPartitionForRegion(idInfo?.region)}:dgs-hc:${idInfo?.region}:${idInfo?.catalogId}:` +
        `glueLF/database/${idInfo?.databaseName}`
      );
    }
  }
}

// AWS resource arn to HybridCatalogId  format
export function getHybridCatalogIdFromAWSResourceArn(awsResourceArn: string) {
  try {
    const scope = getScopeForAwsArn(awsResourceArn);
    const idInfo = getIdInfoFromAwsArn(awsResourceArn);
    if (scope == Scope.Table) {
      return generateHybridCatalogTableResourceId(idInfo);
    } else if (scope == Scope.Schema) {
      return generateHybridCatalogSchemaResourceId(idInfo);
    } else if (scope == Scope.Database) {
      return generateHybridCatalogDatabaseResourceId(idInfo);
    }
    return undefined;
  } catch (e) {
    console.log(`Failed to convert Arn:${awsResourceArn} to HybridCatalogId. Reason:${e} `);
    return undefined;
  }
}

function generateHybridCatalogTableResourceId(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return `DS-${idInfo.dataSource}|A-${idInfo.catalogId}|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|TN-${idInfo.tableName}|R-${idInfo.region}`;
  } else {
    if (IAM_CATALOG_IDS.includes(idInfo.catalogId)) {
      // glue
      return `DS-glue|A-${idInfo.catalogId}|DN-${idInfo.databaseName}|TN-${idInfo.tableName}|R-${idInfo.region}`;
    } else {
      // glueLF
      return `DS-glueLF|A-${idInfo.catalogId}|DN-${idInfo.databaseName}|TN-${idInfo.tableName}|R-${idInfo.region}`;
    }
  }
}

function generateHybridCatalogSchemaResourceId(idInfo: IdInfo) {
  // redshift
  return `DS-${idInfo.dataSource}|A-${idInfo.catalogId}|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|SN-${idInfo.schemaName}|R-${idInfo.region}`;
}

function generateHybridCatalogDatabaseResourceId(idInfo: IdInfo) {
  if (idInfo?.clusterId) {
    // redshift
    return `DS-${idInfo.dataSource}|A-${idInfo.catalogId}|CI-${idInfo.clusterId}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
  } else {
    // glue
    return `DS-glueLF|A-${idInfo.catalogId}|DN-${idInfo.databaseName}|R-${idInfo.region}`;
  }
}

function getScopeForAwsArn(awsArn: string) {
  const idInfo = getIdInfoFromAwsArn(awsArn);
  return getScopeForIdInfo(idInfo);
}

function getIdInfoFromAwsArn(awsArn: string): IdInfo {
  /**
   * example arns:
   * Glue Column: arn:aws:glue:us-west-2:375937567384:column/db_name/table_name/column_name
   * Glue Table: arn:aws:glue:us-west-2:375937567384:table/db_name/table_name
   * Glue database: arn:aws:glue:us-west-2:375937567384:database/db_name
   *
   * Redshift Column: arn:aws:redshift:us-west-2:375937567384:column/cluster_name/db_name/schema_name/table_name/column_name
   * Redshift Table: arn:aws:redshift:us-west-2:375937567384:table/cluster_name/db_name/schema_name/table_name
   * Redshift Schema: arn:aws:redshift:us-west-2:375937567384:schema/cluster_name/db_name/schema_name
   * Redshift Db: arn:aws:redshift:us-west-2:375937567384:database/cluster_name/db_name
   *
   * S3 bucket: arn:aws:s3:::bucket_name
   */
  const arnParts = awsArn.split(':');
  let region = arnParts[3];
  let dataSource = arnParts[2];
  let catalogId = arnParts[4];
  let clusterId = '';
  let databaseName = '';
  let schemaName = '';
  let tableName = '';
  let columnName = '';

  if (arnParts.length > 5) {
    const resourceParts = arnParts[5].split('/');
    switch (dataSource.toLowerCase()) {
      case 'glue':
        switch (resourceParts[0]) {
          case 'column':
            [, databaseName, tableName, columnName] = resourceParts;
            break;
          case 'table':
            [, databaseName, tableName] = resourceParts;
            break;
          case 'database':
            [, databaseName] = resourceParts;
            break;
        }
        break;

      case 'redshift':
        switch (resourceParts[0]) {
          case 'column':
            [, clusterId, databaseName, schemaName, tableName, columnName] = resourceParts;
            break;
          case 'table':
            [, clusterId, databaseName, schemaName, tableName] = resourceParts;
            break;
          case 'schema':
            [, clusterId, databaseName, schemaName] = resourceParts;
            break;
          case 'database':
            [, clusterId, databaseName] = resourceParts;
            break;
        }
        break;
    }
  }

  return {
    dataSource: dataSource,
    catalogId: catalogId,
    clusterId: clusterId,
    databaseName: databaseName,
    schemaName: schemaName,
    tableName: tableName,
    columnName: columnName,
    region: region,
  };
}
