import * as React from 'react';
import { useEffect, useState } from 'react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Checkbox,
  CollectionPreferencesProps,
  CollectionPreferences,
  Header,
  Pagination,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3/polaris';

import { scrollUp } from '../../utils/navigation';

import {
  batchApprove,
  batchDeny,
  cancelRequest,
  getDataPermissionRequests,
  listFgaPolicies,
} from '../../../api/permissions';
import { PageHeader } from '../../subscriptions/common';
import { EmptyState } from 'src/commons/EmptyState';
import { DenyOrCancelModal, resourceBasedColumnDefinition } from 'src/components/workspaces/requests/utils';
import { Flashbar } from '@amzn/awsui-components-react-v3';
import {
  APPROVALS_STATUS_APPROVED,
  APPROVALS_STATUS_APPROVED_WITH_CONDITION,
  APPROVALS_STATUS_REJECTED,
  TABLE_CONTENT_TYPE,
  STATUS_ACTIVE,
  APPROVALS_STATUS_CANCELLED,
} from 'src/commons/constants';
import { extractAccessManagementInfoFromRequest } from 'src/components/requests/utils';
import CsvDownloaderWrapper, { generateCsvColumnDefinitions } from 'src/components/common/csvDownloaderWrapper';
import {
  flattenRequestForCSV,
  generateCsvFileName,
  getCsvDataForDataRequest,
  getPermissionTypeForDPResource,
} from 'src/commons/common';
import _ from 'lodash';

const DEFAULT_CANCEL_REASON = 'Please cancel the request.';

interface BrowseResourceBasedRequestsTable {
  isIncomingRequests: boolean;

  setContentType(contentType: string): void;

  activeGroup: string;
  activeWorkspace: any;
  match: any;
}

export function BrowseResourceBasedRequestsTable({
  isIncomingRequests,
  setContentType,
  activeWorkspace,
}: BrowseResourceBasedRequestsTable) {
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [columnDefinitions] = useState(resourceBasedColumnDefinition(isIncomingRequests));
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [actionRequestLoading, setActionRequestLoading] = useState(false);
  const [reasonOfAction, setReasonOfAction] = useState('');
  const [includesRequestHistory, setIncludesRequestHistory] = useState(false);
  const [action, setAction] = useState('');
  const [csvColumnDefinition, setCsvColumnDefinition] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: false,
    pageSize: 15,
  });

  const refForCSVDownloader: React.RefObject<any> = React.createRef();
  const onClickDownload = () => {
    refForCSVDownloader.current.handleClick();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: (
          <EmptyState
            title='No instances'
            subtitle='No instances to display.'
            action={<Button>Create instance</Button>}
          />
        ),
        noMatch: (
          <EmptyState
            title='No matches'
            subtitle='We can’t find a match.'
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'requestedTime',
          },
          isDescending: true,
        },
      },
      selection: {
        trackBy: 'requestId',
        defaultSelectedItems: [],
        keepSelection: false,
      },
    },
  );
  const { selectedItems } = collectionProps;

  // component did mount
  useEffect(() => {
    setContentType(TABLE_CONTENT_TYPE);
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, []);

  // component did update
  useEffect(() => {
    const refresh = async () => {
      await handleRefresh();
    };
    refresh();
  }, [includesRequestHistory]);

  const transformData = (items, filteredFgaPolicies) => {
    const transformedData = [];
    items.forEach((item) => {
      if (
        item.dataPermissionRequest.resource.lFTagPolicy == undefined &&
        item.dataPermissionRequest.resource.redshiftTagPolicy == undefined
      ) {
        let transform = {};
        transform['requestId'] = item.requestId;
        transform['status'] = item.status;
        transform['statusReason'] = item.statusReason;
        if (isIncomingRequests) {
          transform['requestedWorkspace'] = item.ownerRequestedBy;
        } else {
          transform['requestedTo'] = item.ownerRequestedTo;
        }
        transform['requestedBy'] = item.requestedBy;
        transform['requestedTime'] = item.timeOfRequest;
        transform['permissionType'] = getPermissionTypeForDPResource(item.dataPermissionRequest.resource);
        transform = extractAccessManagementInfoFromRequest(item, transform);
        var resource;
        if (item.dataPermissionRequest.resource.tableWithColumns != undefined) {
          resource = item.dataPermissionRequest.resource.tableWithColumns;
        } else if (item.dataPermissionRequest.resource.table != undefined) {
          resource = item.dataPermissionRequest.resource.table;
        } else if (item.dataPermissionRequest.resource.database != undefined) {
          resource = item.dataPermissionRequest.resource.database;
        } else if (item.dataPermissionRequest.resource.dataCellsFilter != undefined) {
          resource = item.dataPermissionRequest.resource.dataCellsFilter;
        } else if (item.dataPermissionRequest.resource.dataZoneResource != undefined) {
          resource = item.dataPermissionRequest.resource.dataZoneResource;
        }
        transform['databaseName'] = resource.databaseName;
        // for non-DZ resource, the field is called name; for DZ it is called tableName.
        transform['tableName'] = resource.name ?? resource.tableName;
        // for non-DZ resource, the field is called catalogId; for DZ it is called awsAccountId.
        transform['catalogId'] = resource.catalogId ?? resource.awsAccountId;
        if (item.dataPermissionRequest.resource.dataCellsFilter != undefined) {
          transform['clusterId'] = resource.clusterId;
          transform['redshiftWorkgroupName'] = resource.redshiftWorkgroupName;
          transform['databaseName'] = resource.databaseName;
          transform['schemaName'] = resource.schemaName;
          transform['tableName'] = resource.tableName;
          transform['catalogId'] = resource.tableCatalogId;
          transform['fgapName'] = resource.name;
          filteredFgaPolicies.forEach((fgaPolicy) => {
            if (fgaPolicy.tableData.physicalName == resource.name) {
              transform['fgapId'] = fgaPolicy.id;
            }
          });
        }
        if (resource?.columnNames != undefined) {
          transform['columnName'] = item.resource.columnNames.join(', ');
        }
        transformedData.push(transform);
      }
    });
    return transformedData;
  };

  const handleCancel = async () => {
    setActionRequestLoading(true);

    let finalCancelReason = DEFAULT_CANCEL_REASON;
    if (reasonOfAction !== '') {
      finalCancelReason = reasonOfAction;
    }
    try {
      await cancelRequest({
        requestId: selectedItems[0].requestId,
        reason: finalCancelReason,
      });
      await handleRefresh();
      successMessage('cancelled');
    } catch (err) {
      errorMessage(err.message, 'cancel');
      console.log(err);
    }
    setActionRequestLoading(false);
    setReasonOfAction('');
    setActionModalVisible(false);
    scrollUp();
  };

  const handleRefresh = async () => {
    if (!activeWorkspace) return;
    setRequestsLoading(true);
    const requests = [];
    try {
      let nextToken = null;
      do {
        let dataPermissionAccessRequest = {
          workspaceId: activeWorkspace.workspaceId,
          isIncomingRequests: isIncomingRequests,
          includesRequestHistory: includesRequestHistory,
          nextToken: null,
        };
        if (nextToken != null) {
          dataPermissionAccessRequest['nextToken'] = nextToken;
        }
        var getNamedResourceAccessRequestResult = await getDataPermissionRequests(dataPermissionAccessRequest);
        nextToken = getNamedResourceAccessRequestResult?.nextToken;
        requests.push(...getNamedResourceAccessRequestResult?.requestList);
      } while (nextToken != null);
      // check if any FGA permission request present and retrieve the policies
      let filteredFgaPolicies = [];
      for (const permissionRequest of requests) {
        if (permissionRequest?.dataPermissionRequest?.resource?.dataCellsFilter) {
          let dpRequest = permissionRequest.dataPermissionRequest;
          let fgapName = dpRequest.resource.dataCellsFilter.name;
          let fgaPolicies = [];
          let fgaPoliciesResult = await listFgaPolicies({
            ownerId: permissionRequest.ownerRequestedTo,
            status: STATUS_ACTIVE,
          });
          fgaPolicies.push(...fgaPoliciesResult.FineGrainAccess);
          while (fgaPoliciesResult.nextToken) {
            fgaPoliciesResult = await listFgaPolicies({
              ownerId: permissionRequest.ownerRequestedTo,
              status: STATUS_ACTIVE,
              nextToken: fgaPoliciesResult.nextToken,
            });
            fgaPolicies.push(...fgaPoliciesResult.FineGrainAccess);
          }
          fgaPolicies.forEach((fgaPolicy) => {
            if (fgaPolicy.tableData.physicalName == fgapName) {
              filteredFgaPolicies.push(fgaPolicy);
            }
          });
        }
      }
      setAllItems(transformData(requests, filteredFgaPolicies));
      if (requests) {
        let csvData = requests
          .map((item) => flattenRequestForCSV(item))
          .filter((item) => !_.isEmpty(item))
          .map((item) => getCsvDataForDataRequest(item));
        setCsvData(csvData);
        setCsvColumnDefinition(generateCsvColumnDefinitions(csvData));
      }
    } catch (err) {
      console.log(err.message);
      setRequestsLoading(false);
    }
    setRequestsLoading(false);
  };

  const successMessage = (action) => {
    setNotifications([
      {
        type: 'success',
        content: `Data access request was '${action}'.`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const errorMessage = (message, action) => {
    setNotifications([
      {
        type: 'error',
        content: `There was an error '${action}' your Lake Formation request: '${message}'`,
        dismissible: true,
        onDismiss: () => setNotifications([]),
      },
    ]);
  };

  const handleApprove = async () => {
    setRequestsLoading(true);
    let requestList = [];
    selectedItems.forEach((item) =>
      requestList.push({
        requestId: item.requestId,
      }),
    );
    try {
      await batchApprove({
        approveRequestList: requestList,
      });
      await handleRefresh();
      successMessage('approved');
    } catch (err) {
      errorMessage(err.message, 'approve');
      console.log(err);
    }
    setRequestsLoading(false);
  };

  const handleDeny = async () => {
    setRequestsLoading(true);
    let requestList = [];
    selectedItems.forEach((item) =>
      requestList.push({
        requestId: item.requestId,
        reason: 'DENIED',
      }),
    );
    try {
      await batchDeny({
        denyRequestList: requestList,
      });
      await handleRefresh();
      successMessage('denied');
    } catch (err) {
      errorMessage(err.message, 'deny');
      console.log(err);
    }
    setRequestsLoading(false);
  };

  const handleAction = async (e) => {
    setAction(e.detail.id);
    if (e.detail.id == 'cancel' || e.detail.id == 'deny') {
      setActionModalVisible(true);
    } else {
      await handleApprove();
    }
  };

  const shouldDisableDenyButton = () => {
    // if no items are selected, no action can be taken
    if (!selectedItems) return true;
    if (selectedItems.length === 0) return true;
    // if any request is not pending, no action can be taken
    selectedItems.forEach((item) => {
      if (item.status != 'PENDING') return true;
    });
    return false;
  };

  const shouldDisableApproveButton = () => {
    // if no items are selected, no action can be taken
    if (!selectedItems) return true;
    if (selectedItems.length === 0) return true;
    // if any request is not pending, no action can be taken
    selectedItems.forEach((item) => {
      if (item.status != 'PENDING') return true;
    });
    // if any approval workflow is not approved, no action can be taken
    let anyApprovalWorkflowsAreNotApproved = false;
    selectedItems.forEach((item) => {
      if (item.approvalsWorkflow) {
        item.approvalsWorkflow.forEach((approvalWorkflow) => {
          const status = approvalWorkflow.approvalWorkflowStatus;
          if (status != APPROVALS_STATUS_APPROVED && status != APPROVALS_STATUS_APPROVED_WITH_CONDITION) {
            anyApprovalWorkflowsAreNotApproved = true;
          }
        });
      }
    });
    return anyApprovalWorkflowsAreNotApproved;
  };

  const options = () => {
    return [
      {
        text: '',
        icon: 'refresh',
        onItemClick: handleRefresh,
      },
      {
        text: 'Actions',
        onItemClick: handleAction,
        loading: requestsLoading,
        items: isIncomingRequests
          ? [
              {
                text: 'Approve',
                id: 'approve',
                disabled: shouldDisableApproveButton(),
              },
              {
                text: 'Deny',
                id: 'deny',
                disabled: shouldDisableDenyButton(),
              },
            ]
          : [
              {
                text: 'Cancel',
                id: 'cancel',
                disabled: selectedItems.length === 0 || selectedItems.length > 1,
              },
            ],
      },
    ];
  };

  const closeActionModal = () => {
    setActionModalVisible(false);
  };

  const submitModalAction = async (action) => {
    setActionModalVisible(false);
    if (action === 'cancel') {
      await handleCancel();
    } else if (action === 'approve') {
      await handleApprove();
    } else if (action === 'deny') {
      await handleDeny();
    }
  };

  const approvalWorkflowStatusOutOfSync = (desiredStatuses: string[]) => {
    let outOfSync = false;
    selectedItems.forEach((item) => {
      item['approvalsWorkflow']?.forEach((workflowItem) => {
        if (!desiredStatuses.includes(workflowItem.approvalWorkflowStatus)) {
          outOfSync = true;
        }
      });
    });
    return outOfSync;
  };

  return (
    <>
      <Flashbar items={notifications} />
      <Table
        {...collectionProps}
        loadingText='Loading requests...'
        columnDefinitions={columnDefinitions}
        items={items}
        wrapLines={false}
        resizableColumns={true}
        header={
          <>
            <PageHeader
              buttons={options()}
              header={
                <>
                  <Header counter={`(${items.length})`}>Resource-based access requests</Header>
                  <Checkbox
                    onChange={({ detail }) => setIncludesRequestHistory(detail.checked)}
                    checked={includesRequestHistory}
                  >
                    Show request history
                  </Checkbox>
                </>
              }
              additionalItems={
                !requestsLoading &&
                csvData.length > 0 && (
                  <>
                    <Button variant='primary' onClick={onClickDownload} iconName='download'>
                      Download as CSV
                    </Button>
                    <CsvDownloaderWrapper
                      rowItems={csvData}
                      fileName={generateCsvFileName('requests', activeWorkspace ? activeWorkspace.workspaceId : null)}
                      refForCsvDownloader={refForCSVDownloader}
                      userFriendlyDataDefinitions={csvColumnDefinition}
                    />
                  </>
                )
              }
            />
          </>
        }
        empty={<EmptyState title='No requests' subtitle='No requests were found.' />}
        loading={requestsLoading}
        preferences={
          <CollectionPreferences
            title='Preferences'
            confirmLabel='Confirm'
            cancelLabel='Cancel'
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: [
                { value: 25, label: '25 items' },
                { value: 50, label: '50 items' },
                { value: 100, label: '100 items' },
              ],
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Enable to wrap table cell content, disable to truncate text.',
            }}
          />
        }
        trackBy='requestId'
        selectionType={isIncomingRequests ? 'multi' : 'single'}
        selectedItems={selectedItems}
        isItemDisabled={(item) => item.status != 'PENDING' && item.status != 'FAILED'}
        filter={
          <TextFilter
            {...filterProps}
            filteringPlaceholder='Find resources'
            filteringAriaLabel='Filter named resource access requests'
            countText={`${filteredItemsCount} ${filteredItemsCount > 1 ? 'matches' : 'match'}`}
          />
        }
        pagination={<Pagination {...paginationProps} />}
      ></Table>
      <DenyOrCancelModal
        modalVisible={actionModalVisible}
        action={action}
        closeModal={closeActionModal}
        buttonLoading={actionRequestLoading}
        submitAction={submitModalAction}
        approvalWorkflowStatusOutOfSync={approvalWorkflowStatusOutOfSync([
          APPROVALS_STATUS_REJECTED,
          APPROVALS_STATUS_CANCELLED,
        ])}
        textAreaPlaceholder={''}
        reasonOfAction={reasonOfAction}
        setReasonOfAction={setReasonOfAction}
      />
    </>
  );
}
