import { Page } from '../routes';
import { enableAdvisories } from 'src/api/config';
import { Badge } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { Advisory } from 'aws-sdk/clients/awsdlomni';
import { getDismissedAdvisories } from 'src/components/common/activeAdvisoryGlobalFlashbar';
import { DGS_TEAM_WIKI, FEATURE_REQUEST_URL, OMNI_WIKI_PAGE } from 'src/commons/constants';
import { dataZoneIsAvailable } from 'src/components/datazone/constants';

const IMPORT_DATASETS = 'Import dataset';
export const DATA_INGESTION_TITLE = 'Data ingestion (Tethys)';

const moreInfoItems = () => {
  return [
    {
      type: 'link',
      text: 'How-to guide',
      href: OMNI_WIKI_PAGE,
      external: true,
    },
    {
      type: 'link',
      text: 'About AWS Data Governance Services',
      href: DGS_TEAM_WIKI,
      external: true,
    },
    {
      type: 'link',
      text: 'Feature request',
      href: FEATURE_REQUEST_URL,
      external: true,
    },
  ];
};

const permissionsItemsForGroup = (includeTemplates, isIso) => {
  let items = [];
  items.push({ type: 'link', text: 'New access requests', href: Page.REQUESTS });
  if (includeTemplates) {
    items.push({ type: 'link', text: 'Templates', href: Page.TEMPLATES });
  }
  if (!isIso) {
    items.push({ type: 'link', text: 'Baseline', href: Page.BASELINING });
  }
  items.push({ type: 'link', text: 'Datasets', href: Page.MY_DATASETS });
  items.push({ type: 'link', text: 'Groups', href: Page.GROUP });
  return {
    type: 'section',
    text: 'Manage permissions',
    items: items,
  };
};

const permissionsItemsForWorkspace = (isConsumer) => {
  let items = [];
  items.push({
    type: 'link',
    text: 'New access requests',
    href: Page.WORKSPACE_MY_REQUESTS,
  });
  if (!isConsumer) {
    items.push({ type: 'link', text: 'Templates', href: Page.TEMPLATES });
  }
  items.push({
    type: 'link',
    text: 'Data access permissions',
    href: Page.WORKSPACE_ALL_PERMISSIONS,
  });
  items.push({ type: 'link', text: 'Workspace access', href: Page.WORKSPACEACCESS });
  if (!isConsumer) {
    items.push({ type: 'link', text: 'Permission tags', href: Page.TAGS });
    items.push({
      type: 'link',
      text: 'Fine grain access policies ',
      href: Page.FINE_GRAIN_ACCESS_POLICIES,
    });
  }
  return {
    type: 'section',
    text: 'Manage permissions',
    items: items,
  };
};

const shareItems = () => {
  return {
    type: 'section',
    text: 'Share data',
    items: [
      { type: 'link', text: IMPORT_DATASETS, href: Page.PUBLISH },
      { type: 'link', text: DATA_INGESTION_TITLE, href: Page.PUBLISHER_TABLES },
    ],
  };
};

const metricStoreItems = () => {
  return {
    type: 'section',
    text: 'MetricStore',
    items: [
      { type: 'link', text: 'Metrics catalog', href: Page.METRICS_CATALOG },
      { type: 'link', text: 'Metrics collection catalog', href: Page.METRIC_COLLECTIONS_CATALOG },
      { type: 'link', text: 'Reporting table catalog', href: Page.REPORTING_TABLE_CATALOG },
    ],
  };
};

const subscriptionItems = () => {
  return {
    type: 'section',
    text: 'Subscribe to data',
    items: [
      { type: 'link', text: 'Connections', href: Page.CONNECTIONS },
      { type: 'link', text: 'Subscriptions', href: Page.SUBSCRIPTIONS },
    ],
  };
};

const registerItems = () => {
  return {
    type: 'section',
    text: 'Register data',
    items: [
      {
        type: 'link',
        text: 'Lake Formation',
        href: Page.WORKSPACE_GLUE_REGISTER,
      },
      {
        type: 'link',
        text: 'Redshift',
        href: Page.WORKSPACE_REDSHIFT_REGISTER,
      },
    ],
  };
};

const manageResourcesItems = (includeRm, includeMetadataEnrichment, includeEventSubscriptions) => {
  let items = [];
  if (includeMetadataEnrichment) {
    items.push({ type: 'link', text: 'Metadata forms', href: Page.METADATA_FORMS });
    items.push({
      type: 'link',
      text: 'Business glossaries',
      href: Page.BUSINESS_GLOSSARIES,
    });
  }
  if (includeEventSubscriptions) {
    items.push({
      type: 'link',
      text: 'Event subscriptions',
      href: Page.EVENT_SUBSCRIPTIONS,
    });
  }
  if (includeRm) {
    items.push({ type: 'link', text: 'Resource groups', href: Page.RESOURCEGROUPS });
    items.push({ type: 'link', text: 'Bootstrap actions', href: Page.BOOTSTRAPACTIONS });
  }
  return {
    type: 'section',
    text: 'Manage resources',
    items: items,
  };
};

const dataAnalyticsItems = () => {
  return {
    type: 'section',
    text: 'Data analytics',
    items: [
      {
        type: 'link',
        text: 'Athena query',
        href: Page.WORKSPACE_RESOURCE_LINKS,
      },
      {
        type: 'link',
        text: 'Redshift query',
        href: Page.WORKSPACE_REDSHIFT_QUERY,
      },
    ],
  };
};

const workspacesItems = (isWorkspaceLevel) => {
  let items = [];
  if (isWorkspaceLevel) {
    items.push({ type: 'link', text: 'Current workspace', href: Page.WORKSPACE });
  } else {
    items.push({
      type: 'link',
      text: 'Workspaces',
      href: Page.BROWSE_WORKSPACES,
    });
  }
  return {
    type: 'section',
    text: 'Omni workspace',
    items: items,
  };
};

const datazoneItems = () => {
  let items = [];
  items.push({ type: 'link', text: 'DataZone permissions', href: Page.DATAZONE_MANAGE });
  if (dataZoneIsAvailable()) {
    return {
      type: 'section',
      text: 'DataZone resources',
      items: items,
    };
  }
  return {};
};

const onboardItems = () => {
  return {
    type: 'section',
    text: 'Onboard',
    items: [{ type: 'link', text: 'Submit Onboarding Request', href: '/onboarding' }],
  };
};

const advisoryItem = async (props) => {
  const dismissedAdvisories = await getDismissedAdvisories();
  const advisoriesToDisplay = props.activeAdvisories.filter(
    (advisory: Advisory) => !dismissedAdvisories.includes(advisory.advisoryId),
  );
  return {
    type: 'link',
    text: 'Data advisories',
    href: Page.LIST_ADVISORIES,
    info: advisoriesToDisplay.length > 0 ? <Badge color='red'>{advisoriesToDisplay.length}</Badge> : undefined,
  };
};

// the "Discover data" section of the sidebar only displays "Data advisories" in certain regions, and
// it displays a red badge next to "Data advisories" if the user has active advisories.
let browseItemsForGroup = async (props) => {
  let items = [
    { type: 'link', text: 'Search', href: Page.SEARCH },
    { type: 'link', text: 'Browse datasets', href: Page.BROWSE_DATASETS },
    { type: 'link', text: 'Browse databases', href: Page.BROWSE_DATABASES },
    { type: 'link', text: 'Browse catalogs', href: Page.BROWSE_CATALOGS },
  ];
  if (enableAdvisories() && props.userInfo) {
    items.push(await advisoryItem(props));
  }
  return {
    type: 'section',
    text: 'Discover data',
    items: items,
  };
};
let browseItemsForWorkspace = async (props) => {
  let items = [
    { type: 'link', text: 'Search', href: Page.SEARCH },
    {
      type: 'link',
      text: 'Browse datasets',
      href: Page.WORKSPACES_BROWSE_DATASETS,
    },
    {
      type: 'link',
      text: 'Browse databases',
      href: Page.WORKSPACES_BROWSE_DATABASES,
    },
    {
      type: 'link',
      text: 'Browse catalogs',
      href: Page.WORKSPACES_BROWSE_CATALOGS,
    },
  ];
  if (enableAdvisories()) {
    items.push(await advisoryItem(props));
  }
  return {
    type: 'section',
    text: 'Discover data',
    items: items,
  };
};

const divider = () => {
  return { type: 'divider' };
};

// Navigation
export const navItemsForProvider = async (props) => [
  await browseItemsForGroup(props),
  subscriptionItems(),
  shareItems(),
  metricStoreItems(),
  permissionsItemsForGroup(true, false),
  manageResourcesItems(true, true, false),
  workspacesItems(false),
  datazoneItems(),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForAdmins = async (props) => [
  await browseItemsForGroup(props),
  subscriptionItems(),
  shareItems(),
  metricStoreItems(),
  permissionsItemsForGroup(true, false),
  manageResourcesItems(true, true, true),
  workspacesItems(false),
  datazoneItems(),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForConsumer = async (props) => [
  await browseItemsForGroup(props),
  subscriptionItems(),
  metricStoreItems(),
  permissionsItemsForGroup(false, false),
  manageResourcesItems(true, false, false),
  workspacesItems(false),
  datazoneItems(),
  divider(),
  ...moreInfoItems(),
];

// Navigation for new customers
export const navItemsNewCustomers = async (props) => [
  await browseItemsForGroup(props),
  onboardItems(),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForWorkspacesProvider = async (props) => [
  await browseItemsForWorkspace(props),
  registerItems(),
  permissionsItemsForWorkspace(false),
  manageResourcesItems(false, true, true),
  dataAnalyticsItems(),
  workspacesItems(true),
  datazoneItems(),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForWorkspacesConsumer = async (props) => [
  await browseItemsForWorkspace(props),
  permissionsItemsForWorkspace(true),
  dataAnalyticsItems(),
  workspacesItems(true),
  datazoneItems(),
  divider(),
  ...moreInfoItems(),
];

//mvp navigation
export const navItemsForIsoProvider = async (props) => [
  await browseItemsForGroup(props),
  shareItems(),
  metricStoreItems(),
  permissionsItemsForGroup(true, true),
  manageResourcesItems(true, false, false),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForIsoAdmins = async (props) => [
  await browseItemsForGroup(props),
  shareItems(),
  metricStoreItems(),
  permissionsItemsForGroup(true, true),
  manageResourcesItems(true, false, false),
  divider(),
  ...moreInfoItems(),
];

export const navItemsForIsoConsumer = async (props) => [
  await browseItemsForGroup(props),
  metricStoreItems(),
  permissionsItemsForGroup(false, true),
  divider(),
  ...moreInfoItems(),
];

export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel',
};

export const activeHref = '/subscription';
