import React, { Component } from 'react';
import {
  Button,
  Flashbar,
  Form,
  Container,
  Spinner,
  TokenGroup,
  Header,
  FlashbarProps,
  SpaceBetween,
  Link,
} from '@amzn/awsui-components-react-v3';
import { Redirect } from 'react-router-dom';

import {
  createRequestForDataPermission,
  editDataLakeRole,
  getRequest,
  getTemplateForResource,
} from '../../../src/api/permissions';
import { getPendingDataAccessRequestContent, PENDING_DATA_ACCESS_REQUEST_SUBJECT } from 'src/components/utils/emails';
import { createNotification } from '../../../src/api/notifications';
import {
  CreateRequestForDataPermissionRequest,
  GetTemplateForResourceResult,
} from 'aws-sdk/clients/awsdatalakegladstonelambda';
import { TemplateRequestDetailsContainer } from 'src/components/permissions/myPermissions/templateRequestDetailsContainer';
import { Page } from 'src/routes';
import {
  APPROVALS_PRIORITY,
  FGA_POLICY_TYPE_PREFIX,
  FORM_CONTENT_TYPE,
  INVALID_PARAMETER_EXCEPTION_NAME,
  LAKE_FORMATION_TAG_ITEM,
  NON_ISENGARD_ACCESS_GLADSTONE_ERROR_PREFIX,
  NON_ISENGARD_ACCESS_WIKI_URL,
  NON_PRODUCTION_ACCESS_GLADSTONE_ERROR_PREFIX,
  NON_PRODUCTION_ACCESS_WIKI_URL,
  REDSHIFT_TAG_ITEM,
  RESOURCE_ITEM,
  TABLE_RESOURCE_TYPE,
  TAG_ITEM,
  TEMPLATE_TYPE_ACCESS_MANAGEMENT,
} from 'src/commons/constants';
import { extractApprovalTemplateIdFromLink } from 'src/components/permissions/myPermissions/createLakeFormationPermissionsPage';
import { convertApprovalWorkflowIDToLink } from 'src/components/permissions/myPermissions/common';
import UseCaseComponent from '../permissions/myPermissions/useCase/useCaseComponent';
import _ from 'lodash';
import { isValidPrincipalNotRoot } from 'src/commons/validationUtils';
import { generateFGAPArn } from 'src/components/utils/arnUtil';

export interface CheckoutProps {
  cartItems: object[];
  setContentType: any;
  activeGroup: string;
  removeFromCart: any;
  setItemsInCart: any;
  cartItemIds: string[];
  username: string;
  activeWorkspace: any;
}

export interface CheckoutState {
  redirect: string;
  catalogToItemsMap: Map<string, object[]>;
  requestSending: boolean;
  checkoutSucceeded: boolean;
  notifications: FlashbarProps.MessageDefinition[];
  additionalMetadata: any[];
  useCase: string;
  formSubmitted: boolean;
}

export const generateApprovalWorkflowMessage = (approvalWorkflowLinks: string[]) => {
  return (
    <div>
      As part of the request process, some approval workflows were created in Amazon Approvals and action is required.
      To finalize the request, navigate to the below links and fill out the remaining details by editing the summary
      under Basic Details and Start the approval.
      <ul>
        {approvalWorkflowLinks?.map((aWT) => {
          return (
            <li>
              <Link color='inverted' href={aWT.valueOf()} external={true}>
                {aWT.valueOf()}{' '}
              </Link>
            </li>
          );
        })}
      </ul>
      You can come back to these links any time by navigating to the New Access Requests page on the sidebar and
      selecting the relevant request under the Outgoing tab.
    </div>
  );
};

export const getErrorMessageContent = (error) => {
  let content = error.message;
  let errMsg = error.message;
  if (INVALID_PARAMETER_EXCEPTION_NAME === error.name) {
    if (errMsg.startsWith(NON_ISENGARD_ACCESS_GLADSTONE_ERROR_PREFIX)) {
      errMsg = errMsg.split(NON_ISENGARD_ACCESS_GLADSTONE_ERROR_PREFIX)[1] + ' is not managed by Isengard. ';
      content = (
        <>
          {errMsg}
          <Link external href={NON_ISENGARD_ACCESS_WIKI_URL}>
            Learn more
          </Link>
        </>
      );
    }
    if (errMsg.startsWith(NON_PRODUCTION_ACCESS_GLADSTONE_ERROR_PREFIX)) {
      errMsg = errMsg.split(NON_PRODUCTION_ACCESS_GLADSTONE_ERROR_PREFIX)[1] + ' is not a production account. ';
      content = (
        <>
          {errMsg}
          <Link external href={NON_PRODUCTION_ACCESS_WIKI_URL}>
            Learn more
          </Link>
        </>
      );
    }
  }
  return content;
};

export class Checkout extends Component<CheckoutProps, CheckoutState> {
  state = {
    redirect: undefined,
    catalogToItemsMap: new Map(),
    requestSending: false,
    checkoutSucceeded: false,
    notifications: [],
    additionalMetadata: [],
    useCase: '',
    formSubmitted: false,
  };

  MAX_DATASETS_PER_REQUEST = 500;

  componentDidMount = async () => {
    this.props.setContentType(FORM_CONTENT_TYPE);
    this.submitAccessRequest = this.submitAccessRequest.bind(this);
    this.sendNotificationToDataProviders = this.sendNotificationToDataProviders.bind(this);
    this.handleAccessRequest = this.handleAccessRequest.bind(this);
    this.setCatalogToItemsMap();
    await this.getAllTemplateInfo();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.cartItemIds.length != this.props.cartItemIds.length) {
      this.setCatalogToItemsMap();
      await this.getAllTemplateInfo();
    }
  };

  updateTemplateInfo = async (id: string) => {
    // This is to support rule-based template
    // Provide account ID where the LF/Redshift access will be granted to
    const dataLakePrincipals =
      this.props.activeWorkspace && this.props.activeWorkspace.accountId ? [this.props.activeWorkspace.accountId] : [];
    let getTemplateForResourceResult: GetTemplateForResourceResult = null;
    try {
      getTemplateForResourceResult = await getTemplateForResource({
        resourceId: id,
        templateType: TEMPLATE_TYPE_ACCESS_MANAGEMENT,
        dataLakePrincipals: dataLakePrincipals,
      });
    } catch (e) {
      return [];
    }
    const newTemplates = getTemplateForResourceResult.templates.map((template) => {
      let accessManagement = {};
      if (template.accessManagementInfo?.simTemplates) {
        let simTickets = template.accessManagementInfo?.simTemplates?.map((simTemplate) => {
          return { ...simTemplate, simTicketLink: '' };
        });
        accessManagement['simTemplates'] = simTickets;
      }
      if (template.accessManagementInfo?.approvalsWorkflowTemplate) {
        let approvals = {
          approvalTemplateId: extractApprovalTemplateIdFromLink(
            template.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateLink,
          ),
          neededOnDate: '',
          priority: APPROVALS_PRIORITY,
          userAlias: this.props.username,
        };
        accessManagement['approvalsWorkflowTemplate'] = approvals;
      }
      return { ...template, accessManagementInfo: accessManagement, id: id };
    });
    return newTemplates;
  };

  getAllTemplateInfo = async () => {
    let newTemplates = [];
    for (let item of this.props.cartItems) {
      const cartItemResourceId = this.getResourceIdForCartItem(item);
      const templates = await this.updateTemplateInfo(cartItemResourceId);
      newTemplates = newTemplates.concat(templates);
    }

    //Group by template ID
    const groupByTemplId = _.groupBy(newTemplates, 'templateId');
    //Removed the duplicates based on templateId
    const uniqueTemplates = _.uniqBy(newTemplates, 'templateId');
    //Keep track of cart item resource IDs that share the same template and map to its template resource ID
    uniqueTemplates.forEach((uniqTempl) => {
      const keyValuePairs = groupByTemplId[uniqTempl.templateId].map((templ) => [templ.id, templ.resourceId]);
      uniqTempl['cartItemResIdToTemplResIdMap'] = _.fromPairs(keyValuePairs);
    });

    this.setState({
      additionalMetadata: uniqueTemplates,
    });
  };

  // We are storing a catalog -> itemsInCatalog map in the state.
  // This populates that map.
  setCatalogToItemsMap() {
    const catalogToItemsMap = new Map();
    for (const item of this.props.cartItems) {
      const catalogName = item['catalogDisplayName'] ? item['catalogDisplayName'] : item['catalogInfo']['Name'];
      if (!catalogToItemsMap.has(catalogName)) {
        catalogToItemsMap.set(catalogName, []);
      }
      catalogToItemsMap.get(catalogName).push(item);
    }
    this.setState({
      catalogToItemsMap: catalogToItemsMap,
    });
  }

  // Retrieve token groups, split up by catalogs (if there are multiple)
  getTokenGroupsByCatalog() {
    const tokenGroups = [];
    // Sorting the catalogs
    const sortedCatalogs = Array.from(this.state.catalogToItemsMap.keys()).sort();
    for (const catalog of sortedCatalogs) {
      tokenGroups.push(this.getTokenGroupForItems(this.state.catalogToItemsMap.get(catalog), catalog));
    }
    return <div>{tokenGroups}</div>;
  }

  // Converting items into something more helpful for token groups
  convertItemsForTokenGroup(items: object[]) {
    const toReturn = [];
    // Can't use iterator because we need to maintain order
    for (const item of items) {
      if (item['itemType'] == undefined) {
        toReturn.push({
          label: item['tableName'],
          labelTag: item['databaseName'],
          dismissLabel: 'Remove ' + item['tableName'],
          description: `This table is owned by ${item['owners'].join(', ')}`,
          key: item['id'],
        });
      } else if (item['itemType'] == REDSHIFT_TAG_ITEM || item['itemType'] == LAKE_FORMATION_TAG_ITEM) {
        let res = {
          label: item['tag'],
          tags: item['resources'],
          description: `This tag is owned by ${item['owner']}`,
          dismissLabel: 'Remove ' + item['tag'],
          itemType: TAG_ITEM,
          ownerWorkspace: item['owner'],
          dataOwnerRole: item['dataOwnerRole'],
          catalogId: item['catalogId'],
          catalogName: item['catalogName'],
          tagKey: item['tagKey'],
          tagValue: item['tagValue'],
          permissionId: item['permissionId'],
        };
        toReturn.push(res);
      } else if (item['itemType'] == RESOURCE_ITEM) {
        let res = {
          label: item['datasetName'],
          labelTag: item['databaseName'],
          dismissLabel: 'Remove ' + item['datasetName'],
          description: `This table is owned by ${item['owner']}`,
          // key: item['id'],
          itemType: RESOURCE_ITEM,
          ownerWorkspace: item['owner'],
          catalogId: item['catalogId'],
          catalogName: item['catalogName'],
          databaseName: item['databaseName'],
          permissionId: item['permissionId'],
          dataOwnerRole: item['dataOwnerRole'],
        };
        toReturn.push(res);
      } else if (item['itemType'] == FGA_POLICY_TYPE_PREFIX) {
        let res = {
          label: item['fgapName'],
          dismissLabel: 'Remove ' + item['tableName'],
          description: `This policy is owned by ${item['owner']}`,
          key: item['id'],
          itemType: FGA_POLICY_TYPE_PREFIX,
          ownerWorkspace: item['owner'],
          catalogId: item['catalogId'],
          clusterId: item['clusterId'],
          catalogName: item['catalogName'],
          databaseName: item['databaseName'],
          schemaName: item['schemaName'],
          tableName: item['tableName'],
          permissionId: item['permissionId'],
          fgapName: item['fgapName'],
          fgapId: item['id'],
          dataOwnerRole: item['dataOwnerRole'],
        };
        toReturn.push(res);
      }
    }
    return toReturn;
  }

  // Retrieve the token group structure for items of the same catalog
  getTokenGroupForItems(items, catalog) {
    const convertedItems = this.convertItemsForTokenGroup(items);

    return (
      <div className='awsui-util-mb-m awsui-util-mt-xs' key={catalog}>
        <div className='awsui-util-action-stripe'>
          <div className='awsui-util-action-stripe-title'>
            <h3>
              {`Datasets/tags in catalog "${catalog}"`}
              {/*We don't display the number twice if the number of items in catalog */}
              {/*same as total number of items*/}
              {convertedItems.length !== this.props.cartItems.length && (
                <span className='awsui-util-header-counter'>{` (${convertedItems.length})`}</span>
              )}
            </h3>
          </div>
        </div>
        <div className='awsui-util-action-stripe-group awsui-util-pv-n'>
          <TokenGroup
            alignment='vertical'
            items={convertedItems}
            onDismiss={({ detail: { itemIndex } }) => {
              const removedDataSet = items[itemIndex];
              this.props.removeFromCart([removedDataSet]);
            }}
          />
        </div>
      </div>
    );
  }

  convertNeededOnDate = (oldDate: string) => {
    const arr = oldDate.split('-');
    return arr[1] + '/' + arr[2] + '/' + arr[0];
  };

  mapMetadata(accessManagementTemplateList) {
    return accessManagementTemplateList.map((item) => ({
      templateId: item.templateId,
      resourceId: item.resourceId,
      simTicketLinks: item['simTicketLinks'],
      approvalTemplateId: item['approvalTemplateId'],
      userAlias: item['userAlias'],
      neededOnDate: item['neededOnDate'],
      priority: item['priority'],
    }));
  }

  mapMetadataForCartItem(accessManagementTemplateList, cartItem) {
    const mappedMetadata = [];
    const cartItemResourceId = this.getResourceIdForCartItem(cartItem);
    accessManagementTemplateList.forEach((item) => {
      if (!_.isEmpty(item.cartItemResIdToTemplResIdMap) && cartItemResourceId in item.cartItemResIdToTemplResIdMap) {
        mappedMetadata.push({
          templateId: item.templateId,
          resourceId: item.cartItemResIdToTemplResIdMap[cartItemResourceId],
          simTicketLinks: item['simTicketLinks'],
          approvalTemplateId: item['approvalTemplateId'],
          userAlias: item['userAlias'],
          neededOnDate: item['neededOnDate'],
          priority: item['priority'],
        });
      }
    });
    return mappedMetadata;
  }

  getResourceIdForCartItem(item) {
    let resourceId = '';
    // IAM dataset
    if (item['itemType'] == undefined) {
      const datasetName = item['tableName'];
      const databaseName = item['databaseName'];
      const region = item['region'];
      const catalogId = item['catalogId'];
      resourceId = `DS-glue|A-${catalogId}|DN-${databaseName}|TN-${datasetName}|R-${region}`;
    }
    // Tag
    else if (item['itemType'] == LAKE_FORMATION_TAG_ITEM || item['itemType'] == REDSHIFT_TAG_ITEM) {
      resourceId = item['tagKey'] + ':' + item['tagValue'] + ':' + item['catalogId'] + ':' + item['region'];
    }
    // LakeFormation and DataZone dataset
    else if (item['itemType'] == RESOURCE_ITEM) {
      const datasetName = item['datasetName'];
      const databaseName = item['databaseName'];
      const region = item['region'];
      const catalogId = item['catalogId'];
      if (datasetName == 'All Tables') {
        resourceId = `DS-glueLF|A-${catalogId}|DN-${databaseName}|R-${region}`;
      } else {
        resourceId = `DS-glueLF|A-${catalogId}|DN-${databaseName}|TN-${datasetName}|R-${region}`;
      }
      //for datazone onboard resources
      if (item['dataZoneAssetId'] != undefined) {
        resourceId = item['id'];
      }
    }
    // LakeFormation and Redshift FGAP
    else if (item['itemType'] == FGA_POLICY_TYPE_PREFIX) {
      resourceId = generateFGAPArn({
        id: item['id'],
        tableData: {
          clusterId: item['clusterId'],
          databaseName: item['databaseName'],
          region: item['region'],
          schemaName: item['schemaName'],
          tableCatalogId: item['catalogId'],
          tableName: item['tableName'],
        },
      });
    }
    return resourceId;
  }

  // Submitting request for access to items in the cart
  async submitAccessRequest() {
    const failMessage = 'Failed to submit the access request';
    const successMessage = `Successfully submitted the access request. This will remain pending until the data owners approve or deny the request.`;
    const numCartItems = this.props.cartItemIds.length;
    let createdRequestIds = [];
    try {
      let valueArr = [];
      for (let curArr of Array.from(this.state.catalogToItemsMap.values())) {
        valueArr.push(...curArr);
      }
      // verify checkout data type(Omni or Foundry data)
      let type = valueArr[0].itemType;
      if (type == undefined) {
        for (let i = 0; i < numCartItems; i++) {
          if (valueArr[i].itemType !== undefined) {
            this.setState({
              notifications: [
                ...this.state.notifications,
                {
                  type: 'error',
                  content: 'Can only checkout Foundry or Omni data.',
                  dismissible: true,
                  onDismiss: () => this.dismissNotification(failMessage),
                },
              ],
            });
            return;
          }
        }
      } else {
        for (let i = 0; i < numCartItems; i++) {
          if (valueArr[i].itemType == undefined) {
            this.setState({
              notifications: [
                ...this.state.notifications,
                {
                  type: 'error',
                  content: 'Can only checkout Foundry or Omni data.',
                  dismissible: true,
                  onDismiss: () => this.dismissNotification(failMessage),
                },
              ],
            });
            return;
          }
        }
      }
      if (type == undefined) {
        for (let i = 0; i < numCartItems; i += this.MAX_DATASETS_PER_REQUEST) {
          const endIndex = Math.min(i + this.MAX_DATASETS_PER_REQUEST, numCartItems);
          const accessManagementTemplateMetadata = this.getAccessManagementInfoForRequest();
          const mappedMetadata = this.mapMetadata(accessManagementTemplateMetadata);
          const response = await editDataLakeRole({
            groupId: this.props.activeGroup,
            datasetsToAdd: this.props.cartItemIds.slice(i, endIndex),
            accessManagementTemplateMetadata: mappedMetadata,
          });
          createdRequestIds = createdRequestIds.concat(response.addDatasetsRequestIds);
        }
      } else {
        for (let i = 0; i < numCartItems; i++) {
          const cartItem = valueArr[i];
          const accessManagementTemplateMetadata = this.getAccessManagementInfoForRequest();
          // separate requests for each item, so we filter it here
          const mappedMetadataForAMTemplate = this.mapMetadataForCartItem(accessManagementTemplateMetadata, cartItem);
          const baseRequest = {
            ownerRequestedBy: this.props.activeWorkspace.workspaceId,
            ownerRequestedTo: cartItem.owner,
            dataPermissionPublisherRole: cartItem.dataOwnerRole,
            dataPermissionId: cartItem.permissionId,
            dataLocationRegion: cartItem.region,
            useCase: this.state.useCase,
            dataPermissionConsumerRole: this.props.activeWorkspace.workspaceRoleArn,
            permissions: ['SELECT'],
            permissionsWithGrantOption: isValidPrincipalNotRoot(cartItem.dataLakePrincipal) ? [] : ['SELECT'],
            dataLakePrincipal: cartItem.dataLakePrincipal,
          };
          if (cartItem.itemType == RESOURCE_ITEM) {
            if (cartItem.datasetName !== 'All Tables') {
              // table-level access
              // DataZone table-level
              const isDataZone =
                cartItem.dataZoneAssetId !== undefined && cartItem.dataZoneConsumerProjectId !== undefined;
              const resource = {
                tableWithColumns: undefined,
                database: undefined,
                dataCellsFilter: undefined,
                lFTagPolicy: undefined,
                redshiftTagPolicy: undefined,
                dataZoneResource: !isDataZone
                  ? undefined
                  : {
                      awsAccountId: cartItem.catalogId,
                      clusterId: cartItem.clusterId,
                      redshiftWorkgroupName: cartItem.redshiftWorkgroupName,
                      databaseName: cartItem.databaseName,
                      schemaName: cartItem.schemaName,
                      tableName: cartItem.datasetName,
                      region: cartItem.region,
                    },
                table: isDataZone
                  ? undefined
                  : {
                      databaseName: cartItem.databaseName,
                      name: cartItem.datasetName,
                      catalogId: cartItem.catalogId,
                      tableWildcard: null,
                    },
              };

              let finalRequest: CreateRequestForDataPermissionRequest = {
                ...baseRequest,
                resource,
                accessManagementTemplateMetadata: mappedMetadataForAMTemplate,
              };

              if (isDataZone) {
                // remove these unneeded fields from base request for DZ
                delete finalRequest.dataPermissionId;
                delete finalRequest.permissions;
                delete finalRequest.permissionsWithGrantOption;
                delete finalRequest.dataLakePrincipal;
                finalRequest.dataZoneConsumerProjectId = cartItem.dataZoneConsumerProjectId;
                finalRequest.dataZoneAssetId = cartItem.dataZoneAssetId;
              }

              const response = await createRequestForDataPermission(finalRequest);
              createdRequestIds.push(response.requestId);
            } else {
              // All Tables - level access
              const resource = {
                tableWithColumns: null,
                database: null,
                dataCellsFilter: null,
                lFTagPolicy: null,
                redshiftTagPolicy: null,
                table: {
                  databaseName: cartItem.databaseName,
                  name: null,
                  catalogId: cartItem.catalogId,
                  tableWildcard: {},
                },
              };
              const response = await createRequestForDataPermission({
                ...baseRequest,
                resource,
                accessManagementTemplateMetadata: mappedMetadataForAMTemplate,
              });
              createdRequestIds.push(response.requestId);
            }
          } else if (cartItem.itemType == LAKE_FORMATION_TAG_ITEM) {
            const resource = {
              tableWithColumns: null,
              table: null,
              database: null,
              dataCellsFilter: null,
              redshiftTagPolicy: null,
              lFTagPolicy: {
                resourceType: TABLE_RESOURCE_TYPE,
                expression: [{ tagKey: cartItem.tagKey, tagValues: [cartItem.tagValue] }],
                catalogId: cartItem.catalogId,
              },
            };
            const response = await createRequestForDataPermission({
              ...baseRequest,
              resource,
              accessManagementTemplateMetadata: mappedMetadataForAMTemplate,
            });
            createdRequestIds.push(response.requestId);
          } else if (cartItem.itemType == REDSHIFT_TAG_ITEM) {
            const resource = {
              tableWithColumns: null,
              table: null,
              database: null,
              dataCellsFilter: null,
              redshiftTagPolicy: {
                tagKey: cartItem.tagKey,
                tagValue: cartItem.tagValue,
                catalogId: cartItem.catalogId,
              },
              lFTagPolicy: null,
            };
            const response = await createRequestForDataPermission({
              ...baseRequest,
              resource,
              accessManagementTemplateMetadata: mappedMetadataForAMTemplate,
            });
            createdRequestIds.push(response.requestId);
          } else if (cartItem.itemType == FGA_POLICY_TYPE_PREFIX) {
            const resource = {
              tableWithColumns: null,
              table: null,
              database: null,
              dataCellsFilter: {
                clusterId: cartItem.clusterId,
                databaseName: cartItem.databaseName,
                schemaName: cartItem.schemaName,
                tableName: cartItem.tableName,
                tableCatalogId: cartItem.catalogId,
                name: cartItem.fgapName,
              },
              redshiftTagPolicy: null,
              lFTagPolicy: null,
            };
            const response = await createRequestForDataPermission({
              ...baseRequest,
              resource,
              accessManagementTemplateMetadata: mappedMetadataForAMTemplate,
            });
            createdRequestIds.push(response.requestId);
          }
        }
      }
    } catch (err) {
      console.log(err);
      const errMsgContent = getErrorMessageContent(err);
      this.setState({
        notifications: [
          ...this.state.notifications,
          {
            type: 'error',
            header: failMessage,
            content: errMsgContent,
            dismissible: true,
            onDismiss: () => this.dismissNotification(errMsgContent),
          },
        ],
      });
      return;
    }

    let approvalWorkflowLinks = [];
    for (const requestId of createdRequestIds) {
      const getRequestResponse = await getRequest({ requestId: requestId });
      const accessManagementInfoList = getRequestResponse?.requestItem?.additionalMetadata?.accessManagementInfoList;
      if (accessManagementInfoList) {
        accessManagementInfoList.forEach((aMIS) => {
          if (aMIS.approvalWorkflowId) {
            const approvalWorkflowLink = convertApprovalWorkflowIDToLink(aMIS.approvalWorkflowId);
            approvalWorkflowLinks.push(approvalWorkflowLink);
          }
        });
      }
    }

    let newNotifications = [
      ...this.state.notifications,
      {
        type: 'success',
        content: successMessage,
        dismissible: true,
        onDismiss: () => this.dismissNotification(successMessage),
      },
    ];
    if (approvalWorkflowLinks.length > 0) {
      const approvalWorkflowMessage = generateApprovalWorkflowMessage(approvalWorkflowLinks);
      newNotifications.push({
        type: 'info',
        content: approvalWorkflowMessage,
        dismissible: true,
        onDismiss: () => this.dismissNotification(approvalWorkflowMessage),
      });
    }
    this.setState({
      notifications: newNotifications,
      checkoutSucceeded: true,
    });
  }

  getAccessManagementInfoForRequest() {
    const accessManagementTemplateMetadata = this.state.additionalMetadata.map((template) => {
      let accessManagementRequest = {
        templateId: template.templateId,
        resourceId: template.resourceId,
        cartItemResIdToTemplResIdMap: template.cartItemResIdToTemplResIdMap,
      };
      if (template.accessManagementInfo.simTemplates) {
        let simTickets = template.accessManagementInfo.simTemplates.map((simTemplate) => {
          return {
            simTemplateLink: simTemplate.simTemplateLink,
            simTemplateName: simTemplate.simTemplateName,
            simTicketLink: simTemplate.simTicketLink,
          };
        });
        accessManagementRequest['simTicketLinks'] = simTickets;
      }
      if (template.accessManagementInfo.approvalsWorkflowTemplate) {
        accessManagementRequest['approvalTemplateId'] =
          template.accessManagementInfo.approvalsWorkflowTemplate.approvalTemplateId;
        accessManagementRequest['userAlias'] = template.accessManagementInfo.approvalsWorkflowTemplate.userAlias;
        accessManagementRequest['neededOnDate'] = this.convertNeededOnDate(
          template.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate,
        );
        accessManagementRequest['priority'] = template.accessManagementInfo.approvalsWorkflowTemplate.priority;
      }
      return accessManagementRequest;
    });
    return accessManagementTemplateMetadata;
  }

  // Sends a notification telling the data provider they have pending access requests
  async sendNotificationToDataProviders() {
    function failMessage(provider) {
      return `Failed to send a notification to the data provider, ${provider}, about your access request.`;
    }

    function successMessage(provider) {
      return `Successfully sent a notification to the data provider, ${provider}, about your access request.`;
    }

    const dataProviderToDatasets = new Map<string, object[]>();
    for (const item of this.props.cartItems) {
      if (item['primaryOwner'] != undefined) {
        const currentNotificationRecipientGroup = item['primaryOwner'];
        if (!dataProviderToDatasets.has(currentNotificationRecipientGroup)) {
          dataProviderToDatasets.set(currentNotificationRecipientGroup, []);
        }
        let currentItems = dataProviderToDatasets.get(currentNotificationRecipientGroup);
        currentItems.push(item);
      }
    }

    for (const dataProviderRecipient of dataProviderToDatasets.keys()) {
      const items = dataProviderToDatasets.get(dataProviderRecipient);
      try {
        await createNotification({
          SenderId: this.props.activeGroup,
          ReceiverIdList: [dataProviderRecipient],
          PriorityLevel: 'MEDIUM',
          Subject: PENDING_DATA_ACCESS_REQUEST_SUBJECT,
          Content: getPendingDataAccessRequestContent(items, this.props.activeGroup, dataProviderRecipient),
          EmailNeeded: true,
        });
      } catch (err) {
        this.setState({
          notifications: [
            ...this.state.notifications,
            {
              type: 'error',
              content: failMessage(dataProviderRecipient),
              dismissible: true,
              onDismiss: () => this.dismissNotification(failMessage(dataProviderRecipient)),
            },
          ],
        });
        return;
      }
      this.setState({
        notifications: [
          ...this.state.notifications,
          {
            type: 'success',
            content: successMessage(dataProviderRecipient),
            dismissible: true,
            onDismiss: () => this.dismissNotification(successMessage(dataProviderRecipient)),
          },
        ],
      });
    }
  }

  // Submits access requests and send notification of the access request to the data provider
  async handleAccessRequest() {
    // Do not submit form if use case is not provided.
    if (!this.state.useCase) {
      this.setState({ formSubmitted: true });
      return;
    }

    this.setState({ requestSending: true });
    await this.submitAccessRequest();
    if (this.state.checkoutSucceeded) {
      await this.sendNotificationToDataProviders();
      // Remove all items from the cart
      this.props.setItemsInCart([]);
    }
    this.setState({ requestSending: false });
  }

  dismissNotification(messageString) {
    this.setState({
      notifications: this.state.notifications.filter((notificationItem) => notificationItem.content !== messageString),
    });
  }

  validateAdditionalMetadata = () => {
    let validation = [];
    this.state.additionalMetadata.map((t) => {
      if (t.accessManagementInfo.simTemplates) {
        t.accessManagementInfo.simTemplates.map((st) => {
          if (!st.simTicketLink) {
            validation.push(true);
          } else {
            validation.push(false);
          }
        });
      }
      if (t.accessManagementInfo.approvalsWorkflowTemplate) {
        if (!t.accessManagementInfo.approvalsWorkflowTemplate.neededOnDate) {
          validation.push(true);
        } else if (!t.accessManagementInfo.approvalsWorkflowTemplate.priority) {
          validation.push(true);
        } else {
          validation.push(false);
        }
      }
    });
    return !validation.every((ticket) => ticket == false);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={this.state.redirect} />;
    }

    if (!this.state.catalogToItemsMap) {
      return (
        <>
          <Flashbar items={this.state.notifications} />
          <Spinner size='large' />;
        </>
      );
    }

    return (
      <>
        <Flashbar items={this.state.notifications} />
        <Form
          header={
            <Header
              variant='h1'
              description='Please review the tables and tags in your cart.
      Once finished, click on "Request access" to submit your request
      to the data providers who will review and approve/deny your request shortly.'
            >
              Request access
            </Header>
          }
          actions={[
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                variant='normal'
                onClick={() => {
                  this.props.setItemsInCart([]);
                  this.setState({ additionalMetadata: [] });
                }}
                disabled={this.props.cartItems.length === 0}
              >
                {'Clear cart'}
              </Button>
              <Button
                variant='primary'
                onClick={this.handleAccessRequest}
                loading={this.state.requestSending}
                disabled={this.props.cartItems.length === 0 || this.validateAdditionalMetadata()}
              >
                {'Request access'}
              </Button>
            </SpaceBetween>,
          ]}
        >
          <SpaceBetween direction='vertical' size='l'>
            <Container
              className='custom-screenshot-hide'
              header={
                <Header variant='h2'>
                  Items in cart
                  <span className='awsui-util-header-counter'>{` (${this.props.cartItems.length})`}</span>
                </Header>
              }
            >
              {this.props.cartItems.length === 0 ? (
                <div>
                  <Button variant={'link'} onClick={() => this.setState({ redirect: Page.SEARCH })}>
                    You have no items in your cart.
                  </Button>
                </div>
              ) : (
                this.getTokenGroupsByCatalog()
              )}
            </Container>
            {this.state.additionalMetadata.length > 0 && (
              <TemplateRequestDetailsContainer
                additionalMetadata={this.state.additionalMetadata}
                setAdditionalMetadata={(metadata) => {
                  this.setState({ additionalMetadata: metadata });
                }}
              />
            )}
            <UseCaseComponent
              value={this.state.useCase}
              formSubmitted={this.state.formSubmitted}
              onChange={(value) => this.setState({ useCase: value })}
            />
          </SpaceBetween>
        </Form>
      </>
    );
  }
}
