export enum ResourceType {
  LF_CATALOG = 'lfCatalog',
  LF_DATABASE = 'lfDatabase',
  LF_TABLE = 'lfTable',
  LF_FGAP = 'lfFGAP',
  LF_TAG = 'lfTag',
  REDSHIFT_CATALOG = 'redshiftCatalog',
  REDSHIFT_DATABASE = 'redshiftDatabase',
  REDSHIFT_SCHEMA = 'redshiftSchema',
  REDSHIFT_TABLE = 'redshiftTable',
  REDSHIFT_FGAP = 'redshiftFGAP',
  REDSHIFT_TAG = 'redshiftTag',
  CATALOG = 'catalog',
  DATABASE = 'database',
  SCHEMA = 'schema',
  TABLE = 'table',
  METADATA_FORM = 'metadataForm',
}

export const resourceTypeOptions = (
  isWorkspace: boolean,
  disableGroupAndWorkspaceSelection: boolean,
  disableTagSelection: boolean,
  disableMetadataFormSelection: boolean,
) => {
  const optionalGroupOption = disableGroupAndWorkspaceSelection ? [] : [{ value: 'group', label: 'Group' }];
  const optionalWorkspaceOption = disableGroupAndWorkspaceSelection ? [] : [{ value: 'workspace', label: 'Workspace' }];
  // advisories does not support tags yet.
  const optionalTagsOption = disableTagSelection
    ? []
    : [
        { value: ResourceType.LF_TAG, label: 'Lake Formation Tag' },
        { value: ResourceType.REDSHIFT_TAG, label: 'Redshift Tag' },
      ];
  const optionalMetadataFormOption = disableMetadataFormSelection
    ? []
    : [{ value: ResourceType.METADATA_FORM, label: 'Metadata Form' }];
  const resourceTypesWorkspace = [
    ...optionalWorkspaceOption,
    ...optionalTagsOption,
    ...optionalMetadataFormOption,
    { value: ResourceType.LF_CATALOG, label: 'Lake Formation Catalog' },
    { value: ResourceType.LF_DATABASE, label: 'Lake Formation Database' },
    { value: ResourceType.LF_TABLE, label: 'Lake Formation Table' },
    { value: ResourceType.LF_FGAP, label: 'Lake Formation Fine Grained Access Policy' },
    { value: ResourceType.REDSHIFT_CATALOG, label: 'Redshift Catalog' },
    { value: ResourceType.REDSHIFT_DATABASE, label: 'Redshift Database' },
    { value: ResourceType.REDSHIFT_SCHEMA, label: 'Redshift Schema' },
    { value: ResourceType.REDSHIFT_TABLE, label: 'Redshift Table' },
    { value: ResourceType.REDSHIFT_FGAP, label: 'Redshift Fine Grained Access Policy' },
  ];
  const resourceTypesGroup = [
    ...optionalGroupOption,
    ...optionalWorkspaceOption,
    ...optionalMetadataFormOption,
    { value: ResourceType.LF_CATALOG, label: 'Lake Formation Catalog' },
    { value: ResourceType.LF_DATABASE, label: 'Lake Formation Database' },
    { value: ResourceType.LF_TABLE, label: 'Lake Formation Table' },
  ];
  return isWorkspace ? resourceTypesWorkspace : resourceTypesGroup;
};

export const resourceTypeOptionsForSearch = (isWorkspace: boolean) => {
  const optionalSchemaOption = isWorkspace ? [{ value: ResourceType.SCHEMA, label: 'Schema' }] : [];
  const resourceTypes = [
    ...optionalSchemaOption,
    { value: ResourceType.CATALOG, label: 'Catalog' },
    { value: ResourceType.DATABASE, label: 'Database' },
    { value: ResourceType.TABLE, label: 'Table' },
  ];
  return resourceTypes;
};
