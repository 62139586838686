import * as React from 'react';
import { useEffect, useState } from 'react';
import { CONTENT_SELECTOR_OPTIONS, PAGE_SELECTOR_OPTIONS } from './lakeFormationShareTable-config';
import { getDataAccessConfig, getRegion } from 'src/api/config';
import {
  AttributeEditor,
  Button,
  CollectionPreferences,
  ColumnLayout,
  Form,
  FormField,
  Container,
  Input,
  Modal,
  ProgressBar,
  ProgressBarProps,
  Table,
  TokenGroup,
  FlashbarProps,
  CollectionPreferencesProps,
  Pagination,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { createDataPermission, listPermissions } from '../../../api/permissions';
import { Redirect } from 'react-router-dom';
import { scrollUp } from '../../utils/navigation';
import {
  getDataSourceDatabases,
  listCatalogs,
  listDatabases,
  registerCatalog,
  registerDatabase,
} from 'src/api/catalog';
import { isNotFalsy, isValidGroup, isValidRegion, isValidRole } from 'src/commons/validationUtils';
import { TableProps } from '@amzn/awsui-components-react-v3/polaris/table/interfaces';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { paginationLabels } from 'src/commons/tables';
import {
  DATA_PERMISSION_CONSUMER_OPTION,
  DATA_PERMISSION_LAKE_FORMATION_TYPE,
  DATA_PERMISSION_PUBLISHER_OPTION,
  FORM_CONTENT_TYPE,
  LAKE_FORMATION_DATASOURCE_ID,
} from 'src/commons/constants';
import { RegisterDatabaseRequest } from 'aws-sdk/clients/awsdlhybridcatalogservicelambda';

export interface PublishDataSetGlueLFProps {
  setContentType: any;
  onNotificationChange?: (notifications: FlashbarProps.MessageDefinition[]) => void;
  dataSourceId: string;
  activeGroup: string;
}

export const PublishDataSetGlueLF = (props: PublishDataSetGlueLFProps) => {
  const [dataPermissionType] = useState(DATA_PERMISSION_LAKE_FORMATION_TYPE);
  const [lakeFormationPermissions, setLakeFormationPermissions] = useState([]);
  const [lakeFormationPermissionsLoading, setLakeFormationPermissionsLoading] = useState(false);
  const [dataAuditArn, setDataAuditArn] = useState(undefined);
  const [dataLocationArn, setDataLocationArn] = useState(undefined);
  const dataLocationRegion = getRegion();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [, setDatabasesLoading] = useState(false);
  const [redirect] = useState(undefined);
  const [buttonText] = useState('Onboard data');
  const [, setCatalogsLoading] = useState(false);
  const [, setSelected] = useState(undefined);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tableMessage, setTableMessage] = useState('Fill out the form then choose Get permissions.');
  const [dataPermissionList, setDataPermissionList] = useState([]);
  const [, setDataPermissionCards] = useState([]);
  const [dataPermissionTokens, setDataPermissionTokens] = useState([]);
  const [databasesInCatalog, setDatabasesInCatalog] = useState([]);
  const [databasesToOnboard, setDatabasesToOnboard] = useState([]);
  const [, setSelectedPermission] = useState([]);
  const [catalog, setCatalog] = useState('');
  const [catalogDescription, setCatalogDescription] = useState('');
  const [catalogId, setCatalogId] = useState('');
  const [isCatalogOnboarded, setIsCatalogOnboarded] = useState(false);
  const [isDatabaseOnboarded, setIsDatabaseOnboarded] = useState(false);
  const [currentDatabase, setCurrentDatabase] = useState('');
  const [currentDatabaseDescription, setCurrentDatabaseDescription] = useState('');
  const [currentAllowSNSSubscriptions, setCurrentAllowSNSSubscriptions] = useState(false);
  const [currentAutoTableOnboard, setCurrentAutoTableOnboard] = useState(false);
  const [, setCurrentSelectedDatabase] = useState([]);
  const [cti, setCti] = useState('');
  const [dataAccessDetails, setDataAccessDetails] = useState({});
  const [currentOwners, setCurrentOwners] = useState(props.activeGroup);
  const [progressModalVisible, setProgressModalVisible] = useState(false);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [progressBarStep, setProgressBarStep] = useState('');
  const [progressBarStatus, setProgressBarStatus] = useState('in-progress');
  const [progressBarAdditionalInfo, setProgressBarAdditionalInfo] = useState('');
  const [progressBarDescription, setProgressBarDescription] = useState('Onboarding, please wait.');
  const [datasourceDatabaseMap, setDatasourceDatabaseMap] = useState(new Map());

  const dataPermissionAttributes = [
    {
      key: 'database',
      value: 'Database name',
    },
    {
      key: 'databaseDescription',
      value: 'Database description',
    },
    {
      key: 'owners',
      value: 'Database owner(s)',
    },
    {
      key: 'allowSNSSubscription',
      value: 'Allow SNS event subscriptions',
    },
    {
      key: 'autoTableOnboard',
      value: 'Auto table onboard',
    },
  ];

  const columnDefinitions: TableProps.ColumnDefinition<any>[] = [
    {
      id: 'principal',
      header: 'DataLake Principal',
      cell: (item) => item.principal,
      minWidth: 180,
    },
    {
      id: 'catalogId',
      header: 'Catalog Id',
      cell: (item) => item.catalogId,
      minWidth: 100,
      sortingField: 'catalogId',
    },
    {
      id: 'databaseName',
      header: 'Database',
      cell: (item) => item.databaseName,
      minWidth: 100,
      sortingField: 'databaseName',
    },
    {
      id: 'tableName',
      header: 'Table',
      cell: (item) => item.tableName,
      minWidth: 100,
      sortingField: 'tableName',
    },
    {
      id: 'columns',
      header: 'Columns',
      cell: (item) => (item.columnNames !== undefined && item.columnNames !== null ? item.columnNames.join(', ') : ''),
      minWidth: 100,
      sortingField: 'columns',
    },
    {
      id: 'permissions',
      header: 'Permissions',
      cell: (item) => item.permissions,
      minWidth: 100,
    },
    {
      id: 'grantPermissions',
      header: 'Grant Permissions',
      cell: (item) => item.grantablePermissions,
      minWidth: 100,
    },
    {
      id: 'logging',
      header: () => 'Logging',
      cell: (item) => item.logging,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    init();
  }, []);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    wrapLines: true,
    pageSize: 10,
    visibleContent: ['databaseName', 'tableName', 'columns'],
  });

  const contentSelectorOptions: CollectionPreferencesProps.VisibleContentOptionsGroup[] = CONTENT_SELECTOR_OPTIONS;

  const { items, collectionProps, paginationProps, actions } = useCollection(lakeFormationPermissions, {
    filtering: {
      noMatch: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-xs awsui-util-mb-xs'>
            <b>No matches</b>
          </div>
          <p className='awsui-util-mb-s'>We can't find a match.</p>
          <div className='awsui-util-mb-l'>
            <Button
              onClick={() => {
                actions.setFiltering('');
              }}
            >
              Clear filter
            </Button>
          </div>
        </div>
      ),
      empty: (
        <div className='awsui-util-t-c'>
          <div className='awsui-util-pt-s awsui-util-mb-xs'>
            <b>{tableMessage}</b>
          </div>
          <p className='awsui-util-mb-s'>No Lake Formation permissions</p>
        </div>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
    propertyFiltering: {
      filteringProperties: [],
    },
  });

  const init = async () => {
    await handleRefresh();
    props.setContentType(FORM_CONTENT_TYPE);
    setDataAccessDetails(getDataAccessConfig());
  };

  const transformData = (items) => {
    //TODO: Convert to Reduce for Table Permissions Exclude Database
    let permissions = items.map((item, index) => {
      const transform = {};
      transform['permissionId'] = index;
      transform['principal'] = JSON.parse(item.DataLakePrincipal);
      transform['permissions'] = JSON.parse(item.PermissionsString);
      transform['grantablePermissions'] = JSON.parse(item.PermissionsWithGrantOptionString);
      transform['resource'] = JSON.parse(item.ResourceString);

      //Handle Resource Types Database / Table / Table With Columns
      if (transform['resource'].database) {
        transform['shareLevel'] = 'Database';
        transform['catalogId'] = transform['resource'].database.catalogId;
        transform['databaseName'] = transform['resource'].database.name;
      }
      if (transform['resource'].table) {
        transform['shareLevel'] = 'Table';
        transform['catalogId'] = transform['resource'].table.catalogId;
        transform['databaseName'] = transform['resource'].table.databaseName;
        transform['resource'].table.tableWildcard
          ? (transform['tableName'] = 'All Tables')
          : (transform['tableName'] = transform['resource'].table.name);
      }
      if (transform['resource'].tableWithColumns) {
        transform['resource'].tableWithColumns.name === 'ALL_TABLES'
          ? (transform['shareLevel'] = 'Table')
          : (transform['shareLevel'] = 'Column');
        transform['catalogId'] = transform['resource'].tableWithColumns.catalogId;
        transform['databaseName'] = transform['resource'].tableWithColumns.databaseName;
        transform['tableName'] = transform['resource'].tableWithColumns.name;
        if (transform['resource'].tableWithColumns.columnNames) {
          transform['columnNames'] = transform['resource'].tableWithColumns.columnNames;
          transform['columnWildCard'] = 'Include';
        } else {
          transform['columnNames'] = transform['resource'].tableWithColumns.columnWildcard.excludedColumnNames;
          transform['columnWildCard'] = 'Exclude';
        }
      }
      return transform;
    });

    permissions = permissions.filter((item) => typeof item['databaseName'] !== 'undefined');
    return permissions;
  };

  const handleRefresh = async () => {
    if (!props.activeGroup) return;
  };

  const handleReset = async () => {
    setLakeFormationPermissions([]);
    setLakeFormationPermissionsLoading(false);
    setDataAuditArn(undefined);
    setDataLocationArn(undefined);
    setSelectedItems([]);
    setDataPermissionList([]);
    setDataPermissionCards([]);
    setDataPermissionTokens([]);
    setDatabasesInCatalog([]);
    setDatabasesToOnboard([]);
    setSelectedPermission([]);
    setProgressModalVisible(false);
    setProgressBarValue(0);
    setProgressBarStatus('in-progress');
    setProgressBarStep('');
    setProgressBarDescription('Onboarding, please wait.');
    setProgressBarAdditionalInfo('');
  };

  const fetchCatalogs = async (catalogId) => {
    if (!props.activeGroup) return;
    setCatalogsLoading(true);
    let request = {
      Filter: {
        CatalogKeyList: [
          {
            CatalogId: catalogId,
            Region: dataLocationRegion,
          },
        ],
      },
    };
    try {
      const catalogs = await listCatalogs(request);
      const catalogList = catalogs.CatalogInfoList;
      //There should be one catalog per account currently.
      if (catalogList.length > 0) {
        setCatalog(catalogList[0].Name);
        setCatalogDescription(catalogList[0].Description);
        setCti(catalogList[0].CTI);
        setIsCatalogOnboarded(true);
      }
    } catch (err) {
      console.log(err);
      setCatalogsLoading(false);
    }
  };

  const generateDataSourceDatabaseMap = async (catalogId) => {
    let databaseMap = new Map();
    try {
      let getDataSourceDatabasesRequest = {
        DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
        CatalogId: catalogId,
        NextToken: undefined,
      };
      let getDataSourceDatabasesResponse = await getDataSourceDatabases(getDataSourceDatabasesRequest);
      for (let dataSourceDatabase of getDataSourceDatabasesResponse.DatabaseList) {
        databaseMap.set(dataSourceDatabase.Name, dataSourceDatabase);
      }
      while (getDataSourceDatabasesResponse.NextToken != null) {
        getDataSourceDatabasesRequest.NextToken = getDataSourceDatabasesResponse.NextToken;
        getDataSourceDatabasesResponse = await getDataSourceDatabases(getDataSourceDatabasesRequest);
        for (let dataSourceDatabase of getDataSourceDatabasesResponse.DatabaseList) {
          databaseMap.set(dataSourceDatabase.Name, dataSourceDatabase);
        }
      }
    } catch (err) {
      console.log('Exception when fetching LF databases', err);
    }
    setDatasourceDatabaseMap(databaseMap);
  };

  // here fetch databases for a catalog when one is selected.
  // this will not scale but should work fine until a GetDatabasesForCatalog API exists.
  const fetchDatabases = async (catalogId) => {
    if (!props.activeGroup) return;
    setDatabasesLoading(true);
    try {
      let request = { NextToken: null };
      let databases = await listDatabases(request);
      let databaseInfoList = databases.DatabaseInfoList;
      while (databases.NextToken != null) {
        request.NextToken = databases.NextToken;
        databases = await listDatabases(request);
        databaseInfoList.push(...databases.DatabaseInfoList);
      }
      let databasesHCList = [];
      for (const database of databaseInfoList) {
        if (database.CatalogId == catalogId && database.Region == dataLocationRegion) {
          databasesHCList.push({
            CatalogId: database.CatalogId,
            DatabaseName: database.DatabaseName,
            Description: database.Description,
            Owners: database.Owners,
          });
        }
      }
      setDatabasesInCatalog(databasesHCList);
      setDatabasesLoading(false);
    } catch (err) {
      console.log(err);
      setCatalog(null);
      setDatabasesInCatalog([]);
      setDatabasesLoading(false);
    }
  };

  const getDatabaseIfExists = (databaseName) => {
    const databases = databasesInCatalog
      .filter((item) => item.DatabaseName === databaseName)
      .concat(databasesToOnboard.filter((item) => item.DatabaseName === databaseName));
    if (databases.length > 0) {
      setIsDatabaseOnboarded(true);
      setCurrentDatabase(databaseName);
      setCurrentDatabaseDescription(databases[0].Description);
      setCurrentOwners(databases[0].Owners.toString());
    } else {
      setIsDatabaseOnboarded(false);
      setCurrentDatabase(databaseName);
      setCurrentDatabaseDescription(datasourceDatabaseMap.get(databaseName)?.Description ?? '');
      setCurrentOwners(props.activeGroup);
    }
    return databases;
  };

  const getPermissions = async () => {
    if (!props.activeGroup) return;
    setLakeFormationPermissionsLoading(true);
    setTableMessage('Fill out the form then choose Get permissions.');
    setLakeFormationPermissions([]);
    let catalogId = dataAuditArn.split(':')[4];
    setCatalogId(catalogId);

    //Get Hybrid Catalog information for Catalog Id (from Arn)
    await generateDataSourceDatabaseMap(catalogId);
    await fetchCatalogs(catalogId);
    await fetchDatabases(catalogId);

    try {
      let request = {
        groupId: props.activeGroup,
        type: dataPermissionType,
        requestMap: {
          DataGroupId: props.activeGroup,
          DataAuditArn: dataAuditArn,
          DataLocationArn: dataLocationArn,
          DataLocationRegion: dataLocationRegion,
        },
        nextToken: null,
      };
      let response = await listPermissions(request);
      let permissions = [...response.permissionList];
      //Loop and get remaining tables
      while (response.nextToken != null) {
        request.nextToken = response.nextToken;
        response = await listPermissions(request);
        permissions.push(...response.permissionList);
      }
      let permissionList = transformData(permissions).filter(
        (item) => item.permissions !== undefined && item.permissions.length == 1 && item.permissions[0] == 'SELECT',
      );
      setLakeFormationPermissions(permissionList);
      setLakeFormationPermissionsLoading(false);
      setSelected(undefined);
      setSelectedItems([]);
    } catch (err) {
      setTableMessage(`Unable to load Lake Formation permissions: ${err.message}`);
      setLakeFormationPermissionsLoading(false);

      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: err.message,
          dismissible: true,
        },
      ]);
    }
  };

  const addSingleDataPermission = () => {
    const database = {
      Description: currentDatabaseDescription,
      DatabaseName: currentDatabase,
      Owners: currentOwners,
      AllowSNSEventSubscription: currentAllowSNSSubscriptions,
      AutoTableOnboard: currentAutoTableOnboard,
    };
    const dataPermission = selectedItems[0];

    const dataPermissionToken = {
      label: currentDatabase,
      description: selectedItems[0].tableName,
      dismissLabel: selectedItems[0].permissionId,
      tags: [`Permission ${selectedItems[0].permissions}`, `Grantable ${selectedItems[0].grantablePermissions}`],
    };
    if (!isDatabaseOnboarded) setDatabasesToOnboard([...databasesToOnboard, database]);

    setDataPermissionList([...dataPermissionList, dataPermission]);
    setDataPermissionTokens([...dataPermissionTokens, dataPermissionToken]);
    setSelectedItems([]);
    setSelected('');
    setCurrentDatabase('');
    setCurrentDatabaseDescription('');
    setCurrentAllowSNSSubscriptions(false);
    setCurrentAutoTableOnboard(false);
  };

  const isFormComplete = () => {
    return (
      isValidRole(dataLocationArn) &&
      isValidRole(dataAuditArn) &&
      isValidRegion(dataLocationRegion) &&
      isNotFalsy(props.dataSourceId) &&
      dataPermissionList?.length
    );
  };

  const disableAddButton = () => {
    return !(
      isDatabaseOnboarded ||
      (isValidGroup(currentOwners) && isNotFalsy(currentDatabase) && isNotFalsy(currentDatabaseDescription))
    );
  };

  const handleConfirm = async () => {
    if (!props.activeGroup) return;
    // declare variables which are used/updated locally
    let permissionsOnboarded = false;
    let catalogOnboarded = false;
    let databasesOnboarded = false;

    setButtonLoading(true);
    setProgressModalVisible(true);
    setProgressBarValue(1);
    //Onboard Permissions
    permissionsOnboarded = await onboardPermissions();
    //Onboard Hybrid Catalog If catalog isn't onboarded, onboarded.
    //Else mark it as onboarded.
    if (permissionsOnboarded && !isCatalogOnboarded) {
      catalogOnboarded = await onboardCatalog();
    } else {
      catalogOnboarded = true;
    }

    //Onboard Databases
    if (permissionsOnboarded && catalogOnboarded) {
      databasesOnboarded = await onboardDatabases();
    }

    if (permissionsOnboarded && catalogOnboarded && databasesOnboarded) {
      setButtonLoading(false);
      setProgressBarValue(100);
      setProgressBarStep('Onboarding successful.  Visit the database(s) you onboarded to register the tables to Omni.');
      setProgressBarDescription('');
      setProgressBarStatus('success');
    }
    scrollUp();
  };

  //Onboards Catalog
  const onboardCatalog = async () => {
    setProgressBarStep('Registering catalog...');
    let registerCatalogRequest = {
      CatalogId: catalogId,
      Region: dataLocationRegion,
      Name: catalog,
      Owner: props.activeGroup,
      CTI: cti,
      Description: catalogDescription,
      DataAccessRole: dataAccessDetails['dataAccessRoleArn'],
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
    };
    try {
      await registerCatalog(registerCatalogRequest);
      setProgressBarValue(66);
      return true;
    } catch (err) {
      console.log(err);
      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: `There was an error registering your catalog: '${err.message}'`,
        },
      ]);
      setButtonLoading(false);
      setProgressBarStatus('error');
      setProgressBarAdditionalInfo(err.message);
      return false;
    }
  };

  //Onboards Databases
  const onboardDatabases = async () => {
    setProgressBarStep('Onboarding databases...');
    //Create unique set to remove duplicated requests to register
    let databaseSet = new Set(
      dataPermissionList.map((permissionList) => {
        return permissionList.databaseName;
      }),
    );
    //If any permissions were removed this will remove those databases
    databasesToOnboard.forEach((database) => {
      if (databaseSet.has(database.DatabaseName)) {
        if (!onboardDatabase(database)) return false;
      }
    });
    setProgressBarValue(100);
    return true;
  };

  //Register Database
  const onboardDatabase = async (database) => {
    //Loop through for each database
    let registerDatabaseRequest: RegisterDatabaseRequest = {
      CreatedBy: props.activeGroup,
      Region: dataLocationRegion,
      DatabaseName: database.DatabaseName,
      CatalogId: catalogId,
      Description: database.Description,
      Owners: [database.Owners],
      AllowSNSEventSubscription: database.AllowSNSEventSubscription,
      AutoTableOnboard: database.AutoTableOnboard,
      DataAccessRole: dataAccessDetails['dataAccessRoleArn'],
      DataSourceId: LAKE_FORMATION_DATASOURCE_ID,
    };

    try {
      await registerDatabase(registerDatabaseRequest);
      return true;
    } catch (err) {
      console.log(err);
      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: `There was an error registering your database: '${err.message}'`,
        },
      ]);
      setButtonLoading(false);
      setProgressBarStatus('error');
      setProgressBarAdditionalInfo(err.message);
      return false;
    }
  };

  //Onboards Permissions
  const onboardPermissions = async () => {
    setProgressBarStep('Onboarding permissions.... this will take a minute');

    try {
      for (let request of dataPermissionRequestList()) {
        await createDataPermission(request);
      }
      setProgressBarValue(33);
      return true;
    } catch (err) {
      console.log(err);
      props.onNotificationChange([
        {
          type: 'error' as FlashbarProps.Type,
          content: `There was an error creating your Lake Formation data permission: '${err.message}'`,
        },
      ]);
      setButtonLoading(false);
      setProgressBarStatus('error');
      setProgressBarAdditionalInfo(err.message);
      return false;
    }
  };

  //Creates Data Permission Request List
  const dataPermissionRequestList = () => {
    let dataPermissionRequestList = [];
    dataPermissionList.forEach((element) => {
      dataPermissionRequestList.push(
        createDataPermissionRequestItem(
          DATA_PERMISSION_PUBLISHER_OPTION,
          props.activeGroup,
          dataLocationArn,
          null,
          element,
          props.activeGroup,
        ),
        //Onboard Consumer Permission
        createDataPermissionRequestItem(
          DATA_PERMISSION_CONSUMER_OPTION,
          props.activeGroup,
          dataAccessDetails['accountId'],
          dataAccessDetails['ramRoleArn'],
          element,
          dataAccessDetails['groupId'],
        ),
      );
    });

    return dataPermissionRequestList;
  };

  const createDataPermissionRequestItem = (option, groupId, principal, ramRole, permission, ownerId) => {
    let resource = undefined;
    if (permission.resource.tableWithColumns !== null && permission.resource.tableWithColumns !== undefined) {
      if (permission.resource.tableWithColumns.name === 'ALL_TABLES') {
        if (option == DATA_PERMISSION_CONSUMER_OPTION) {
          resource = {
            table: {
              databaseName: permission.resource.tableWithColumns.databaseName,
              catalogId: permission.resource.tableWithColumns.catalogId,
              tableWildcard: {},
            },
          };
        } else {
          resource = {
            tableWithColumns: {
              databaseName: permission.resource.tableWithColumns.databaseName,
              name: permission.resource.tableWithColumns.name,
              catalogId: permission.resource.tableWithColumns.catalogId,
              columnNames: permission.resource.tableWithColumns.columnNames,
              columnWildcard: {},
            },
          };
        }
      } else {
        resource = {
          tableWithColumns: permission.resource.tableWithColumns,
        };
      }
    } else if (permission.resource.table !== null && permission.resource.table !== undefined) {
      if (permission.resource?.table.name === 'ALL_TABLES') {
        resource = {
          table: {
            databaseName: permission.resource.table.databaseName,
            catalogId: permission.resource.table.catalogId,
            tableWildcard: {},
          },
        };
      } else {
        resource = {
          table: permission.resource.table,
        };
      }
    } else if (permission.resource.database !== null && permission.resource.database !== undefined) {
      resource = {
        database: permission.resource.database,
      };
    }
    return {
      ownerId: groupId,
      dataPermission: {
        ownerId: ownerId,
        dataPermissionOwnerId: groupId,
        option: option,
        type: dataPermissionType,
        dataPublisherAuditRole: dataAuditArn,
        dataConsumerRole: ramRole,
        dataPublisherRole: dataLocationArn,
        region: dataLocationRegion,
        resource: resource,
        permissions: permission.permissions,
        permissionsWithGrantOption: permission.grantablePermissions,
        dataLakePrincipal: principal,
      },
    };
  };

  const isDisabled = () => {
    return (
      !isNotFalsy(dataLocationArn) ||
      !isNotFalsy(dataAuditArn) ||
      !isNotFalsy(dataLocationRegion) ||
      !isNotFalsy(dataPermissionType)
    );
  };

  const handleOnSelectionChange = (selectedItems) => {
    setSelected(selectedItems[0] ? selectedItems[0]['permissionId'] : undefined);

    setSelectedItems(selectedItems);

    setCurrentSelectedDatabase(getDatabaseIfExists(selectedItems[0]['databaseName']));
  };

  const handleOnDismiss = (itemIndex) => {
    //Remove item from permission list.
    setDataPermissionList(
      dataPermissionList.filter(
        (dataPermission) => dataPermission.permissionId !== dataPermissionTokens[itemIndex].dismissLabel,
      ),
    );
    //Remove item from tokens
    setDataPermissionTokens([
      ...dataPermissionTokens.slice(0, itemIndex),
      ...dataPermissionTokens.slice(itemIndex + 1),
    ]);
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <div>
      <Modal onDismiss={handleReset} visible={progressModalVisible} size='medium' header='Onboarding...'>
        <ProgressBar
          label={progressBarStep}
          status={progressBarStatus as ProgressBarProps.Status}
          description={progressBarDescription}
          value={progressBarValue}
          additionalInfo={progressBarAdditionalInfo}
        />
      </Modal>
      <Form
        actions={
          <div>
            <Button variant='primary' onClick={handleConfirm} loading={buttonLoading} disabled={!isFormComplete()}>
              {buttonText}
            </Button>
          </div>
        }
      >
        <Container className='custom-screenshot-hide' header={<h2>Lake Formation permissions</h2>}>
          <ColumnLayout>
            <FormField
              label={<div>Lake Formation admin audit / metadata role</div>}
              description={
                <>
                  This is the role that is a Lake Formation admin.
                  <a
                    href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HPublishingRoles'
                    className='awsui-util-help-info-link'
                  >
                    Learn more
                  </a>
                </>
              }
            >
              <Input
                name='dataAuditArn'
                placeholder='arn:aws:iam::123456789012:role/DataLakeLakeFormationAudit'
                ariaRequired={true}
                value={dataAuditArn}
                onChange={(e) => setDataAuditArn(e.detail.value.trim())}
                invalid={dataAuditArn !== undefined && !isValidRole(dataAuditArn)}
              />
            </FormField>
            <FormField
              label={<div>Lake Formation sharing role</div>}
              description={
                <>
                  This is a data admin role that has permissions to the tables you want to share.
                  <a
                    href='https://w.amazon.com/bin/view/AWSDataPlatform/AWSDataLake/Omni/LakeFormationRoles/#HPublishingRoles'
                    className='awsui-util-help-info-link'
                  >
                    Learn more
                  </a>
                </>
              }
            >
              <Input
                name='dataLocationArn'
                placeholder='arn:aws:iam::123456789012:role/DataLakeLakeFormation'
                ariaRequired={true}
                value={dataLocationArn}
                onChange={(e) => setDataLocationArn(e.detail.value.trim())}
                invalid={dataLocationArn !== undefined && !isValidRole(dataLocationArn)}
              />
            </FormField>
            <FormField label={<div>Region</div>} description='The region where the data source resides.'>
              <Input
                name='dataLocationRegion'
                placeholder='us-east-1'
                value={dataLocationRegion}
                disabled={true}
                ariaRequired={true}
              />
            </FormField>
            <Button
              variant='primary'
              onClick={getPermissions}
              loading={lakeFormationPermissionsLoading}
              disabled={isDisabled()}
            >
              Get permissions
            </Button>
          </ColumnLayout>
        </Container>
        <br />

        {lakeFormationPermissions.length !== 0 && (
          <>
            <Container className='custom-screenshot-hide' header={<h2>Catalog details</h2>}>
              <ColumnLayout>
                <FormField label={<div>Catalog ID</div>} description='Catalog ID for your databases'>
                  <Input name='catalogId' ariaRequired={true} value={catalogId} disabled={true} />
                </FormField>
                <FormField label={<div>Catalog name</div>} description='Name of the catalog'>
                  <Input
                    name='catalogName'
                    placeholder='Enter catalog name here.'
                    ariaRequired={true}
                    value={catalog}
                    disabled={isCatalogOnboarded}
                    onChange={(e) => setCatalog(e.detail.value)}
                  />
                </FormField>
                <FormField label={<div>Catalog description</div>} description='A brief description of the catalog.'>
                  <Input
                    name='catalogDescription'
                    placeholder={isCatalogOnboarded ? '' : 'Enter description here.'}
                    ariaRequired={true}
                    value={catalogDescription}
                    disabled={isCatalogOnboarded}
                    onChange={(e) => setCatalogDescription(e.detail.value)}
                  />
                </FormField>
                <FormField label={<div>CTI</div>} description='CTI for your team.'>
                  <Input
                    name='cti'
                    placeholder={isCatalogOnboarded ? '' : 'Enter CTI here. Format: Category/Type/Item'}
                    ariaRequired={true}
                    value={cti}
                    disabled={isCatalogOnboarded}
                    onChange={(e) => setCti(e.detail.value)}
                  />
                </FormField>
              </ColumnLayout>
            </Container>
            <br />
            {/*Add a drop down that have a list of all permissions for multiple databases*/}
            <Container className='custom-screenshot-hide' header={<h2>Onboard data permissions</h2>}>
              <ColumnLayout>
                <Table
                  {...collectionProps}
                  pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                  selectionType='single'
                  loadingText='Loading Lake Formation permissions... this will take a minute.'
                  loading={lakeFormationPermissionsLoading}
                  columnDefinitions={columnDefinitions}
                  items={items}
                  wrapLines={preferences.wrapLines}
                  resizableColumns={true}
                  empty={
                    <div className='awsui-util-t-c'>
                      <div className='awsui-util-pt-s awsui-util-mb-xs'>
                        <b>{tableMessage}</b>
                      </div>
                      <p className='awsui-util-mb-s'>No Lake Formation permissions</p>
                    </div>
                  }
                  selectedItems={selectedItems}
                  trackBy='permissionId'
                  isItemDisabled={(item) => dataPermissionList.includes(item)}
                  visibleColumns={preferences.visibleContent}
                  onSelectionChange={({ detail: { selectedItems } }) => handleOnSelectionChange(selectedItems)}
                  preferences={
                    <CollectionPreferences
                      title='Preferences'
                      confirmLabel='Confirm'
                      cancelLabel='Cancel'
                      pageSizePreference={{
                        title: 'Page size',
                        options: PAGE_SELECTOR_OPTIONS,
                      }}
                      preferences={preferences}
                      onConfirm={({ detail }) => setPreferences(detail)}
                      wrapLinesPreference={{
                        label: 'Wrap lines',
                        description: 'Check to see all the text and wrap the lines',
                      }}
                      visibleContentPreference={{
                        title: 'Select visible columns',
                        options: contentSelectorOptions,
                      }}
                    />
                  }
                />
                <AttributeEditor
                  items={dataPermissionAttributes}
                  definition={[
                    // Definition of the left column, containing the descriptions of the input fields
                    {
                      label: '',
                      control: (item) => <p>{item.value}</p>,
                    },
                    // Definition of the right column, containing text fields, dropdown menus, and toggles
                    {
                      label: '',
                      control: (item) => {
                        if (item.key == 'database') {
                          return <Input id={'input:' + item.key} value={currentDatabase} disabled={true} />;
                        } else if (item.key == 'databaseDescription') {
                          return (
                            <Input
                              id={'input:' + item.key}
                              placeholder={isDatabaseOnboarded ? '' : 'Enter value'}
                              value={currentDatabaseDescription}
                              disabled={isDatabaseOnboarded}
                              onChange={(e) => setCurrentDatabaseDescription(e.detail.value)}
                            />
                          );
                        } else if (item.key == 'owners') {
                          return (
                            <Input
                              id={'input:' + item.key}
                              placeholder={'Enter value'}
                              value={currentOwners}
                              disabled={true}
                            />
                          );
                        } else if (item.key == 'allowSNSSubscription') {
                          return (
                            <Toggle
                              checked={currentAllowSNSSubscriptions}
                              onChange={(e) => setCurrentAllowSNSSubscriptions(e.detail.checked)}
                            />
                          );
                        } else if (item.key == 'autoTableOnboard') {
                          return (
                            <Toggle
                              checked={currentAutoTableOnboard}
                              onChange={(e) => setCurrentAutoTableOnboard(e.detail.checked)}
                            />
                          );
                        }
                      },
                    },
                  ]}
                  addButtonText='Add data permissions'
                  disableAddButton={disableAddButton()}
                  onAddButtonClick={addSingleDataPermission}
                  isItemRemovable={() => false}
                  removeButtonText='Remove'
                />
                <FormField>
                  <TokenGroup
                    alignment='vertical'
                    onDismiss={({ detail: { itemIndex } }) => handleOnDismiss(itemIndex)}
                    items={dataPermissionTokens}
                  />
                </FormField>
              </ColumnLayout>
            </Container>
          </>
        )}
      </Form>
    </div>
  );
};
